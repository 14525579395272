<template>
  <ListItemCheckbox
    :disabled="isSavingJobAssets"
    :value="value === 'Yes' || value === true"
    color="blue"
    class="align-center"
    hide-details
    @change="$emit('update', $event)"
  />
</template>

<script>
import ListItemCheckbox from '@components/DailyReportView/ListItemFields/Checkbox.vue'
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
  getterType: 'getJobAssetsField',
  mutationType: 'updateJobAssetsField',
})

export default {
  name: 'JobAssetCheckbox',
  components: { ListItemCheckbox },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    prop: {
      type: String,
      required: true,
      default: undefined,
    },
  },
  data() {
    return {}
  }, // watch
  computed: {
    ...mapFields(['isSavingJobAssets']),
    value() {
      return this.item[this.prop]
    },
  },
}
</script>
