import { TOGGLE_PRINT_MODE, SET_REPORT_PRINT_MODE } from '@constants/mutations'

export default {
  state: {
    printMode: false,
  },

  mutations: {
    [TOGGLE_PRINT_MODE](state) {
      state.printMode = !state.printMode
    },
    [SET_REPORT_PRINT_MODE](state, mode) {
      state.printMode = mode
    },
  },

  actions: {
    togglePrintMode({ commit }) {
      commit(TOGGLE_PRINT_MODE)
    }, // togglePrintMode
    setPrintMode({ commit }, mode) {
      commit(SET_REPORT_PRINT_MODE, mode)
    }, // togglePrintMode
  },

  getters: {
    isPrintModeOn: (state) => state.printMode,
  },
}
