<template>
  <VLayout class="job-asset-editable">
    <VFlex class="value--wrap">
      <VTextField
        v-if="isEditing"
        ref="input"
        v-model="editableValue"
        class="input-el pa-0 ma-0"
        clearable
        hide-details
        :placeholder="`${displayValue}`"
        :type="inputType"
        @blur="isEditing = false"
        @keyup.enter="isEditing = false"
      />
      <span
        v-else
        :class="[
          'display-value',
          { empty: isEmpty },
          { local: hasLocalValue },
          { saving: isSavingJobAssets },
        ]"
        @click="showEditor"
        >{{ displayValue }}</span
      >
    </VFlex>
  </VLayout>
</template>

<script>
import { capitalCase } from 'change-case'
import { createHelpers } from 'vuex-map-fields'
import { VTextField } from 'vuetify/lib'
const { mapFields } = createHelpers({
  getterType: 'getJobAssetsField',
  mutationType: 'updateJobAssetsField',
})

export default {
  name: 'JobAssetEditable',
  components: { VTextField },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    prop: {
      type: String,
      required: true,
      default: undefined,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      isEditing: false,
      editableValue: null,
      isDirty: false,
    }
  }, // watch
  computed: {
    ...mapFields(['isSavingJobAssets']),
    displayValue() {
      if (this.isEmpty) {
        return capitalCase(this.prop)
      }
      return this.hasLocalValue ? this.localValue : this.inheritedValue
    },
    inheritedValue() {
      return this.item[this.prop] ? this.item[this.prop] : ''
    },
    hasInheritedValue() {
      return this.inheritedValue !== ''
    },
    localValue() {
      return this.item[this.localValueKey] ? this.item[this.localValueKey] : ''
    },
    localValueKey() {
      return `LOCAL_${this.prop}`
    },
    hasLocalValue() {
      return this.localValue !== ''
    },
    isEmpty() {
      return !this.hasLocalValue && !this.hasInheritedValue
    },
    inputType() {
      if (this.type !== 'text') return this.type
      return typeof displayValue === 'number' ? 'number' : 'text'
    },
  },
  watch: {
    isEditing(val) {
      if (val) {
        this.$nextTick(() => {
          this.isDirty = false
          this.$refs.input.focus()
          this.editableValue = this.hasLocalValue
            ? this.localValue
            : this.inheritedValue
        })
      } else if (this.isDirty) {
        this.$emit('update', this.editableValue)
      }
    }, // isEditing
    editableValue() {
      this.isDirty = true
    }, // editableValue
  }, // methods
  mounted() {
    this.editableValue = this.hasLocalValue
      ? this.localValue
      : this.inheritedValue
  },
  methods: {
    showEditor() {
      if (this.isSavingJobAssets) return
      this.isEditing = true
    },
  }, // watch
}
</script>

<style lang="scss">
.job-asset-editable {
  .value--wrap {
    align-self: center;
    flex-basis: 100px;
    flex-grow: 1;
    flex-shrink: 0;
    // border: 1px solid red;
  }
  .input-el {
    min-width: 100px;
    font-size: 13px;
    .v-text-field input {
      line-height: 1;
      padding: 2px 0;
    }
    .v-icon {
      font-size: 15px;
    }
  }
}

.display-value {
  cursor: pointer;
  border-bottom: 1px solid transparent;
  min-width: 100px;
  &.empty {
    color: hsl(0, 0, 90%);
    font-style: italic;
  }
  &.local {
    color: #3c92e7;
    font-weight: bold;
  }
}
</style>
