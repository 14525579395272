var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VCard',{staticClass:"job-asset-manager-table--wrap px-3 py-0",attrs:{"elevation":"0"}},[_c('VCardTitle',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('VSpacer'),_c('BaseInput',{staticClass:"pt-0 mt-0",attrs:{"append-icon":"search","placeholder":"Search","hide-details":"","color":"primary","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('VDataTable',{staticClass:"job-asset-manager-table",attrs:{"value":_vm.selected,"items":_vm.items,"loading":_vm.loading,"headers":_vm.headers,"pagination":_vm.pagination,"search":_vm.search,"item-key":"ID","select-all":"","dense":""},on:{"update:pagination":function($event){_vm.pagination=$event},"input":function($event){return _vm.$emit('select', $event)}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_c('VCheckbox',{attrs:{"primary":"","hide-details":""},model:{value:(props.selected),callback:function ($$v) {_vm.$set(props, "selected", $$v)},expression:"props.selected"}})],1),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm.getAssetName(props.item)))]),_vm._l((_vm.editableItemHeaders),function(editableHeader){return _c('td',{key:editableHeader.value,staticClass:"text-xs-left"},[(editableHeader.value === 'HIDE_FROM_WORK_ITEM_PICKLIST')?_c('JobAssetCheckbox',{attrs:{"prop":editableHeader.value,"item":props.item},on:{"update":function($event){return _vm.updateAsset(props.item, editableHeader.value, $event)}}}):_c('JobAssetEditable',{attrs:{"item":props.item,"prop":editableHeader.value,"type":editableHeader.type},on:{"update":function($event){return _vm.updateAsset(
              props.item,
              editableHeader.hasLocalField
                ? `LOCAL_${editableHeader.value}`
                : editableHeader.value,
              $event
            )}}})],1)})]}},{key:"no-results",fn:function(){return [_vm._v(" No search results for \""+_vm._s(_vm.search)+"\". ")]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }