<template>
  <VTextField
    v-bind="allBindings"
    :class="$attrs.class"
    v-on="$listeners"
    @change="$emit('change')"
    @update:error="$emit('errorUpdate', $event)"
  ></VTextField>
</template>

<script>
import VTextField from '@vuetify/VTextField'
export default {
  name: 'BaseInput',
  components: { VTextField },
  props: { color: { type: String, default: 'teal' }, ...VTextField.props },
  data() {
    return {}
  },
  computed: {
    allBindings() {
      // Need to proxify both props and attrs, for example for showLabels
      return { ...this.$props, ...this.$attrs }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-input {
  font-size: 14px;
}
</style>
