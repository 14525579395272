import Vue from 'vue'
import api from '@services/api'
import fullstory from '@utils/fullstory'
import nanoid from 'nanoid'
import pAll from 'p-all'
import _get from 'lodash/get'
import _uniq from 'lodash/uniq'
import _sortBy from 'lodash/sortBy'
import _omitBy from 'lodash/omitBy'
import {
  HumanFields,
  AssetObjectNames,
  AssignmentTables,
  AssetTypes,
} from '@constants/knack'
import {
  SET_SHIFT_ITEMS,
  MODIFY_SHIFT_ITEM,
  REPLACE_SHIFT_ITEM,
  ADD_NEW_SHIFT_ITEM,
  INSERT_SHIFT_ITEM,
  REMOVE_NEW_SHIFT_ITEM,
  TOGGLE_MULTI_SPLIT_SELECTION,
} from '@constants/mutations'

import { createHelpers } from 'vuex-map-fields'
const { getShiftItemsField, updateShiftItemsField } = createHelpers({
  getterType: 'getShiftItemsField',
  mutationType: 'updateShiftItemsField',
})

export default {
  state: {
    shiftItems: {
      [AssetTypes.LABORER]: [],
      [AssetTypes.EQUIPMENT]: [],
    },
    numUnsavedShiftItems: 0,
    visibleShiftItemLists: [],
    multiSplitSelections: {
      [AssetTypes.LABORER]: [],
      [AssetTypes.EQUIPMENT]: [],
    },
    lastUsedTimestamp: null,
  },
  mutations: {
    [SET_SHIFT_ITEMS](state, { shiftItems, assetType }) {
      state.shiftItems[assetType] = shiftItems
    },

    [ADD_NEW_SHIFT_ITEM](state, { shiftItem, assetType }) {
      state.shiftItems[assetType].push({ ...shiftItem, new: true })
    }, // ADD_NEW_SHIFT_ITEM

    [INSERT_SHIFT_ITEM](state, { shiftItem, assetType }) {
      state.shiftItems[assetType].push(shiftItem)
    }, // INSERT_SHIFT_ITEM

    [MODIFY_SHIFT_ITEM](state, { assetType, shiftItemId, payload }) {
      let shiftItems = { ...state.shiftItems }

      let updatedRecords = shiftItems[assetType]
      let record = updatedRecords.find((a) => a.ID === shiftItemId)
      let index = updatedRecords.indexOf(record)
      let modifiedRecord = { ...record, ...payload }
      updatedRecords.splice(index, 1, modifiedRecord)
      Vue.set(state, 'shiftItems', shiftItems)
    }, // MODIFY_SHIFT_ITEM

    [REPLACE_SHIFT_ITEM](state, { assetType, shiftItemId, payload }) {
      let shiftItems = { ...state.shiftItems }

      let updatedRecords = shiftItems[assetType]
      let record = updatedRecords.find((a) => a.ID === shiftItemId)
      let index = updatedRecords.indexOf(record)
      let modifiedRecord = { ...payload }
      updatedRecords.splice(index, 1, modifiedRecord)
      Vue.set(state, 'shiftItems', shiftItems)
    }, // REPLACE_SHIFT_ITEM

    [REMOVE_NEW_SHIFT_ITEM](state, { assetType, shiftItemId }) {
      if (!shiftItemId) {
        return false
      }
      let updatedShiftItems = { ...state.shiftItems }
      let updatedRecords = updatedShiftItems[assetType]
      let record = updatedRecords.find((a) => a.ID === shiftItemId)
      let index = updatedRecords.indexOf(record)
      updatedRecords.splice(index, 1)
      updatedShiftItems[assetType] = updatedRecords
      Vue.set(state, 'shiftItems', updatedShiftItems)
    }, // REMOVE_NEW_SHIFT_ITEM

    [TOGGLE_MULTI_SPLIT_SELECTION](state, { assetType, shiftId, isSelected }) {
      let updatedMultiSplitSelections = {
        ...state.multiSplitSelections,
      }
      if (isSelected) {
        updatedMultiSplitSelections[assetType].push(shiftId)
      } else {
        updatedMultiSplitSelections[assetType] = state.multiSplitSelections[
          assetType
        ].filter((shift) => shift !== shiftId)
      }
      state.multiSplitSelections = updatedMultiSplitSelections
    }, // TOGGLE_MULTI_SPLIT_SELECTION

    updateShiftItemsField,
  },
  getters: {
    getShiftItemById: (state) => (assetType, shiftItemId) => {
      return state.shiftItems[assetType].find(
        (shiftItem) => shiftItem.ID === shiftItemId
      )
    }, // getShiftItemById
    getShiftItemsByShift: (state) => (assetType) => {
      return state.shiftItems[assetType].reduce((obj, shiftItem) => {
        let shiftId = _get(
          shiftItem,
          'LABOR_ASSIGNMENT[0].id',
          _get(shiftItem, 'EQUIPMENT_USAGE[0].id')
        )
        if (shiftId) {
          if (!obj[shiftId]) obj[shiftId] = []
          obj[shiftId].push(shiftItem)
        }
        return obj
      }, {})
    },
    getShiftItemsForShiftIds: (state, getters) => (assetType, shiftIds) => {
      if (!Object.keys(state.shiftItems[assetType]).length || !shiftIds.length)
        return []

      return Object.keys(getters.getShiftItemsByShift(assetType))
        .filter((shiftId) => shiftIds.includes(shiftId))
        .reduce((obj, shiftId) => {
          obj[shiftId] = _sortBy(
            getters.getShiftItemsByShift(assetType)[shiftId],
            'TIME_CREATED',
            'DESC'
          )
          return obj
        }, {})
    },

    getDefaultShiftItemForShift: (state, getters) => (assetType, shiftId) => {
      let shiftItems = getters.getShiftItemsByShift(assetType)[shiftId]
      return shiftItems.find((shiftItem) => !!shiftItem.IS_DEFAULT)
    },

    getShiftItemsForShift: (state) => (assetType, shiftId) => {
      let connectionField = AssignmentTables[assetType]
      let shiftItems = state.shiftItems[assetType].filter(
        (shiftItem) => _get(shiftItem, `[${connectionField}][0].id`) === shiftId
      )
      return _sortBy(shiftItems, 'TIME_CREATED')
    },

    getShiftIdFromShiftItem: (state, getters) => (assetType, shiftItem) => {
      let connectionField = AssignmentTables[assetType]
      return _get(shiftItem, `[${connectionField}][0].id`)
    }, // getShiftIdFromShiftItem

    getShiftItemTotalsForShift: (_, getters) => (assetType, shiftId) => {
      let shiftItems = getters.getShiftItemsForShift(assetType, shiftId)
      let totals = {
        REGULAR_HOURS: 0,
        OT_HOURS: 0,
      }
      shiftItems.forEach((shiftItem) => {
        totals.REGULAR_HOURS += shiftItem.REGULAR_HOURS
        totals.OT_HOURS += shiftItem.OT_HOURS
      })

      return totals
    },

    getIsShiftItemsListVisible: (state) => (shiftId) => {
      return state.visibleShiftItemLists.includes(shiftId)
    },

    getMultiSplitSelectionsForAssetType: (state) => (assetType) => {
      return state.multiSplitSelections[assetType]
    },

    getIsMultiSplitSelected: (_, getters) => (assetType, assignmentId) => {
      let selected = getters.getMultiSplitSelectionsForAssetType(assetType)
      return selected.includes(assignmentId)
    },

    getShiftItemsField,
  },
  actions: {
    async fetchShiftItemsForShifts({ commit }, { assetType, shiftIds }) {
      if (!shiftIds.length) return

      try {
        // fetch shift items for assets
        let connectorField = AssignmentTables[assetType]

        let filters = {
          match: 'or',
          rules: _uniq(shiftIds).map((shiftId) => {
            return {
              field: HumanFields.SHIFT_ITEMS[connectorField],
              operator: 'is',
              value: shiftId,
            }
          }),
        }
        let options = {
          filters,
          assetType: AssetObjectNames.shift_items,
          rows_per_page: 1000,
        }

        let shiftItems = await api.getCollection(
          AssetObjectNames.shift_items,
          { options },
          (response) => {
            commit(SET_SHIFT_ITEMS, { shiftItems: response.records, assetType })
            return response.records
          },
          (err) => {
            throw err
          }
        )
        return shiftItems
      } catch (err) {
        throw new Error(err)
      }
    },

    async fetchDefaultShiftItemForShift({ commit }, { assetType, shiftId }) {
      if (!shiftId) return

      try {
        // fetch shift items for assets
        let connectorField = AssignmentTables[assetType]

        let filters = {
          match: 'and',
          rules: [
            {
              field: HumanFields.SHIFT_ITEMS.IS_DEFAULT,
              operator: 'is',
              value: true,
            },
            {
              field: HumanFields.SHIFT_ITEMS[connectorField],
              operator: 'is',
              value: shiftId,
            },
          ],
        }
        let options = {
          filters,
          assetType: AssetObjectNames.shift_items,
          rows_per_page: 100,
        }

        let shiftItems = await api.getCollection(
          AssetObjectNames.shift_items,
          { options },
          (response) => {
            commit(SET_SHIFT_ITEMS, { shiftItems: response.records, assetType })
            return response.records
          },
          (err) => {
            throw err
          }
        )
        let defaultShiftItem = shiftItems[0]
        return defaultShiftItem
      } catch (err) {
        throw new Error(err)
      }
    },

    setShiftItemIsWorking({ dispatch }, { assetType, shiftItemId }) {
      dispatch('modifyShiftItem', {
        assetType,
        shiftItemId,
        payload: { isWorking: true },
      })
    }, // setShiftItemIsWorking

    setShiftItemNotWorking({ dispatch }, { assetType, shiftItemId }) {
      dispatch('modifyShiftItem', {
        assetType,
        shiftItemId,
        payload: { isWorking: false },
      })
    }, // setAssetNotWorking

    modifyShiftItem({ commit }, { assetType, shiftItemId, payload }) {
      if (!shiftItemId) {
        console.error('modifyShiftItem: shiftItemId is required')
      }
      commit(MODIFY_SHIFT_ITEM, {
        assetType,
        shiftItemId,
        payload,
      })
    }, // modifyShiftItem

    forceUpdateShiftItem(
      { commit, getters, rootGetters },
      { assetType, shiftItemId, payload }
    ) {
      if (!shiftItemId) {
        console.error('forceUpdateShiftItem: shiftItemId is required')
        return
      }
      let shiftItem = getters.getShiftItemById(assetType, shiftItemId)
      if (!shiftItem) {
        console.error('forceUpdateShiftItem: shiftItem not found')
        return
      }

      let hasPhase = _get(shiftItem, 'PHASE', []).length > 0
      let hasCostCode = _get(shiftItem, 'COST_CODE', []).length > 0
      if (!hasPhase || !hasCostCode) {
        let location = rootGetters.getLocation
        let defaultPhase = _get(location, 'DEFAULT_PHASE[0]')
        let defaultCostCode = _get(location, 'DEFAULT_COST_CODE[0]')
        payload = {
          ...payload,
          // if defaultPhase or defaultCostCode isn't null
          // add them to the payload
          ...(defaultPhase && { PHASE: [defaultPhase] }),
          ...(defaultCostCode && { COST_CODE: [defaultCostCode] }),
        }
      }
      commit(MODIFY_SHIFT_ITEM, {
        assetType,
        shiftItemId,
        payload: { ...payload, forcedUpdates: { payload } },
      })
    }, // forceUpdateShiftItem

    async replaceShiftItem({ commit }, { assetType, shiftItemId, payload }) {
      if (!shiftItemId) {
        console.error('replaceShiftItem: shiftItemId is required')
      }
      commit(REPLACE_SHIFT_ITEM, { assetType, shiftItemId, payload })
    }, // replaceShiftItem

    async addShiftItem({ dispatch }, { shiftItem, assetType }) {
      try {
        let newShiftItem = await api.createShiftItem(shiftItem)
        fullstory.event('shift_item_create.ok', {
          assetType,
          newShiftItem,
        })
        await dispatch('makeRevisionForShiftItemMod', {
          assetType,
          shiftItemId: shiftItem.ID,
          action: 'added',
        })
        return newShiftItem
      } catch (error) {
        throw new Error(error)
      }
    }, // addShiftItem

    async addDraftShiftItem(
      { commit, dispatch },
      { assetType, shiftId, shiftItem = {} }
    ) {
      let shiftType = AssignmentTables[assetType]

      let defaults = {
        ID: nanoid(),
        draft: true,
        REGULAR_HOURS: 0,
        OT_HOURS: 0,
        WAS_MODIFIED: true,
        [shiftType]: [{ id: shiftId }],
      }
      shiftItem = { ...defaults, ...shiftItem }
      shiftItem.TIME_CREATED = await dispatch('getUniqueTimestamp')

      commit(ADD_NEW_SHIFT_ITEM, {
        shiftItem,
        assetType,
      })
      return shiftItem
    }, // addDraftShiftItem

    // Add a default shift item to the new assignment
    async addDefaultShiftItem(
      { rootGetters, dispatch },
      { assetType, shiftId, reportId }
    ) {
      try {
        let report = rootGetters['getReportById'](reportId)

        let locationId = _get(report, 'LOCATION[0].id')
        let location = await rootGetters['getLocationFromId'](locationId)
        if (!location) {
          location = await dispatch('fetchLocation', { ID: locationId })
        }
        let defaultPhase = _get(location, 'DEFAULT_PHASE[0]')
        let defaultCostCode = _get(location, 'DEFAULT_COST_CODE[0]')

        let shiftItem = {
          REGULAR_HOURS: assetType === AssetTypes.EQUIPMENT ? 8 : 0,
          OT_HOURS: 0,
          DT_HOURS: 0,
          PHASE: [defaultPhase],
          COST_CODE: [defaultCostCode],
          IS_DEFAULT: true,
          DAILY_REPORT: reportId,
        }

        let draftShiftItem = await dispatch('addDraftShiftItem', {
          assetType,
          shiftId,
          shiftItem,
        })

        let defaultShiftItem = await api.createShiftItem(draftShiftItem)

        return defaultShiftItem
      } catch (error) {
        return Promise.reject(error)
      }
    }, // addDefaultShiftItem

    async updateDefaultShiftItem(
      { rootGetters, dispatch },
      { assetType, shiftId, reportId }
    ) {
      try {
        let report = rootGetters['getReportById'](reportId)

        let defaultShiftItem = await dispatch('fetchDefaultShiftItemForShift', {
          assetType,
          shiftId,
        })
        let shiftItemId = _get(defaultShiftItem, 'ID')
        if (!shiftItemId) {
          throw new Error(`No default shift item found for shift ${shiftId}`)
        }
        let locationId = _get(report, 'LOCATION[0].id')
        let location = await rootGetters.getLocationFromId(locationId)
        if (!location) {
          location = await dispatch('fetchLocation', { ID: locationId })
        }
        let defaultPhase = _get(location, 'DEFAULT_PHASE[0]')
        let defaultCostCode = _get(location, 'DEFAULT_COST_CODE[0]')

        let shiftItemUpdates = {
          ID: shiftItemId,
          PHASE: [defaultPhase],
          COST_CODE: [defaultCostCode],
          DAILY_REPORT: reportId,
        }

        let updatedDefaultShiftItem = await dispatch('updateShiftItem', {
          assetType,
          shiftItem: shiftItemUpdates,
        })

        return updatedDefaultShiftItem
      } catch (error) {
        return Promise.reject(error)
      }
    }, // updateDefaultShiftItem

    insertShiftItem(
      { commit, dispatch },
      { assetType, shiftId, shiftItem = {} }
    ) {
      let shiftType = AssignmentTables[assetType]

      let defaults = {
        ID: nanoid(),
        REGULAR_HOURS: 0,
        OT_HOURS: 0,
        DT_HOURS: 0,
        [shiftType]: [{ id: shiftId }],
        TIME_CREATED: dispatch('getUniqueTimestamp'),
      }
      shiftItem = { ...defaults, ...shiftItem }
      commit(INSERT_SHIFT_ITEM, {
        shiftItem,
        assetType,
      })
      return shiftItem
    }, // insertShiftItem

    async updateShiftItem({ dispatch }, { assetType, shiftItem }) {
      if (!shiftItem.ID) {
        console.error('updateShiftItem: shiftItem.ID is required')
        return
      }
      if (!assetType) {
        console.error('updateShiftItem: assetType is required')
        return
      }

      let updatedShiftItem = await api.updateShiftItem(shiftItem.ID, shiftItem)
      fullstory.event('shift_item_update.ok', {
        assetType,
        shiftId: shiftItem.ID,
        shiftItem,
      })
      if (updatedShiftItem) {
        await dispatch('makeRevisionForShiftItemMod', {
          assetType,
          shiftItemId: shiftItem.ID,
          action: 'updated',
        })
        await dispatch('replaceShiftItem', {
          assetType,
          shiftItemId: updatedShiftItem.ID,
          payload: updatedShiftItem,
        })
      }
      return updatedShiftItem
    }, // updateShiftItem

    async saveShiftItem(
      { dispatch, rootGetters },
      { assetType, shiftItem, updatedProps }
    ) {
      let shiftItemId = shiftItem.ID
      if (!shiftItemId) {
        console.error('saveShiftItem: shiftItem.ID is required')
        return
      }
      try {
        let theReport = rootGetters.getTheReport
        let theReportId = theReport.ID

        if (!theReportId) {
          console.error('insertShiftItem: theReportId is required')
          return
        }
        shiftItem = { ...shiftItem, DAILY_REPORT: theReportId }

        await dispatch('setShiftItemIsWorking', { assetType, shiftItemId })

        let saveAction = shiftItem.draft
          ? 'saveDraftShiftItem'
          : 'saveExistingShiftItem'
        let savedShiftItem = await dispatch(saveAction, {
          assetType,
          shiftItem,
          updatedProps,
        })

        return savedShiftItem
      } catch (error) {
        await dispatch('setShiftItemNotWorking', { assetType, shiftItemId })
        return Promise.reject(error)
      }
    },

    async saveDraftShiftItem({ dispatch }, { assetType, shiftItem }) {
      let newShiftItem = await dispatch('addShiftItem', {
        assetType,
        shiftItem,
      })
      if (newShiftItem) {
        await dispatch('replaceShiftItem', {
          assetType,
          shiftItemId: shiftItem.ID,
          payload: newShiftItem,
        })
        return newShiftItem
      } else {
        return false
      }
    }, // saveDraftShiftItem

    async saveExistingShiftItem(
      { dispatch },
      { assetType, shiftItem, updatedProps }
    ) {
      if (
        updatedProps.includes('REGULAR_HOURS') ||
        updatedProps.includes('OT_HOURS')
      ) {
        shiftItem = { ...shiftItem, WAS_MODIFIED: true }
      }
      let savedShiftItem = await dispatch('updateShiftItem', {
        assetType,
        shiftItem,
      })
      return savedShiftItem
    },

    removeNewShiftItem({ commit }, { assetType, shiftItemId }) {
      commit(REMOVE_NEW_SHIFT_ITEM, {
        assetType,
        shiftItemId,
      })
    }, // removeNewShiftItem

    async trashShiftItem({ dispatch, getters }, { assetType, shiftItemId }) {
      let payload = { assetType, shiftItemId }
      await dispatch('setShiftItemIsWorking', payload)
      let removedShiftItem = await api.deleteShiftItem(shiftItemId)
      if (removedShiftItem.delete) {
        fullstory.event('shift_item_delete.ok', {
          assetType,
          shiftItemId,
        })
        let shiftItem = await getters.getShiftItemById(assetType, shiftItemId)
        await dispatch('makeRevisionForShiftItemMod', {
          assetType,
          shiftItemId,
          shiftItem,
          action: `deleted`,
        })
        dispatch('removeNewShiftItem', payload)
      } else {
        this.$notify(`Couldn't remove shiftItem. Please try again.`)
        await dispatch('setShiftItemIsNotWorking', payload)
      }
    }, // trashShiftItem

    untrashShiftItem({ dispatch }, { assetType, shiftItemId }) {
      dispatch('modifyShiftItem', {
        assetType,
        shiftItemId,
        payload: { trashed: false },
      })
    }, // untrashShiftItem

    toggleShiftItemsListVisibility({ state, getters }, shiftId) {
      if (!shiftId) return
      let isShowingList = getters.getIsShiftItemsListVisible(shiftId)

      if (isShowingList) {
        state.visibleShiftItemLists = state.visibleShiftItemLists.filter(
          (id) => id !== shiftId
        )
      } else {
        state.visibleShiftItemLists.push(shiftId)
      }
    },

    toggleMultiSplitSelection({ commit }, { assetType, shiftId, isSelected }) {
      if (!shiftId) return
      commit(TOGGLE_MULTI_SPLIT_SELECTION, { assetType, shiftId, isSelected })
    },

    toggleMultiSplitSelectAll(
      { commit, rootState },
      { assetType, isSelected }
    ) {
      let assignmentType = AssignmentTables[assetType]
      let assetIds = rootState.reportAssets.assets[assignmentType].map(
        (asset) => asset.ID
      )
      assetIds.forEach((assetId) => {
        commit(TOGGLE_MULTI_SPLIT_SELECTION, {
          assetType,
          shiftId: assetId,
          isSelected,
        })
      })
    }, // toggleMultiSplitSelectAll

    async batchCreateShiftItems(
      { state, dispatch, getters, rootGetters },
      { shiftItems, assetType }
    ) {
      let selectedShifts = state.multiSplitSelections[assetType]

      const buildAndInsertShiftItem = async (shiftId, shiftItem) => {
        let location = await rootGetters.getLocation
        let defaultPhase = _get(location, 'DEFAULT_PHASE[0]')
        let defaultCostCode = _get(location, 'DEFAULT_COST_CODE[0]')
        let shiftType = AssignmentTables[assetType]

        let defaults = {
          PHASE: [defaultPhase],
          COST_CODE: [defaultCostCode],
          REGULAR_HOURS: 0,
          OT_HOURS: 0,
          WAS_MODIFIED: true,
          TIME_CREATED: Date.now(),
          [shiftType]: [{ id: shiftId }],
        }
        let newShiftItem = { ...defaults, ...shiftItem }

        let insertedShiftItem = await dispatch('insertShiftItem', {
          assetType,
          shiftId,
          shiftItem: newShiftItem,
        })
        return insertedShiftItem
      }

      let creationPromises = selectedShifts.reduce((acc, shiftId) => {
        let calls = shiftItems.map(async (shiftItem) => {
          let returnFn = async () => {
            let draft = await buildAndInsertShiftItem(shiftId, shiftItem)
            let TIME_CREATED = await dispatch('getUniqueTimestamp')
            draft = { ...draft, TIME_CREATED }

            let newShiftItem = await api.createShiftItem(draft)
            if (newShiftItem) {
              fullstory.event('shift_item_batch_create.ok', {
                assetType,
                newShiftItem,
              })
              await dispatch('replaceShiftItem', {
                assetType,
                shiftItemId: shiftItem.ID,
                payload: newShiftItem,
              })
            } else {
              fullstory.event('shift_item_batch_create.fail', {
                assetType,
                shiftItem,
              })
            }
            return newShiftItem
          }
          return returnFn
        })
        return [...acc, ...calls]
      }, [])
      let newShiftItems = await pAll(creationPromises, { concurrency: 2 })

      state.multiSplitSelections[assetType] = []

      let successes = newShiftItems.filter((r) => !(r instanceof Error))
      if (successes.length) {
        Vue.prototype.$notify({
          type: 'success',
          title: `Created ${successes.length} Shift Items!`,
        })
      }
      if (successes.length !== creationPromises.length) {
        Vue.prototype.$notify({
          type: 'error',
          title: `Failed to create ${creationPromises.length -
            successes.length} Shift Items!`,
        })
      }

      let totalUpdatedHoursByShiftIds = newShiftItems.reduce(
        (obj, shiftItem) => {
          let shiftType = AssignmentTables[assetType]

          let shiftId = _get(shiftItem, `[${shiftType}][0].id`)
          if (shiftId) {
            if (!obj[shiftId]) obj[shiftId] = { REGULAR_HOURS: 0, OT_HOURS: 0 }
            obj[shiftId].REGULAR_HOURS += shiftItem.REGULAR_HOURS
            obj[shiftId].OT_HOURS += shiftItem.OT_HOURS
          }
          return obj
        },
        {}
      )

      // force update the default shift item for each shift
      let defaultShiftItemUpdates = Object.keys(
        totalUpdatedHoursByShiftIds
      ).map((shiftId) => {
        let defaultShiftItem = getters.getDefaultShiftItemForShift(
          assetType,
          shiftId
        )
        let updatedHours = totalUpdatedHoursByShiftIds[shiftId]
        if (!defaultShiftItem) {
          fullstory.event(
            'batchCreateShiftItems.default_shift_item_not_found',
            { shiftId }
          )
        }

        let newRegularHours =
          (defaultShiftItem?.REGULAR_HOURS || 0) - updatedHours.REGULAR_HOURS
        let newOtHours =
          (defaultShiftItem?.OT_HOURS || 0) - updatedHours.OT_HOURS
        return {
          ID: defaultShiftItem.ID,
          REGULAR_HOURS: newRegularHours > 0 ? newRegularHours : 0,
          OT_HOURS: newOtHours > 0 ? newOtHours : 0,
          WAS_MODIFIED: true,
        }
      })

      let defaultShiftItemPromises = defaultShiftItemUpdates.map(
        (shiftItem) => {
          return () =>
            dispatch('updateShiftItem', {
              assetType,
              shiftItem,
            })
        }
      )
      let updatedDefaultShiftItems = await pAll(defaultShiftItemPromises, {
        concurrency: 2,
      })

      return [...newShiftItems, ...updatedDefaultShiftItems]
    }, // batchCreateShiftItems

    async makeRevisionForShiftItemMod(
      { dispatch, getters, rootGetters },
      { assetType, action, shiftItemId, shiftItem }
    ) {
      shiftItem = shiftItem || getters.getShiftItemById(assetType, shiftItemId)
      if (!shiftItem) {
        console.error('makeRevisionForShiftItemMod: shiftItem is required')
        return
      }

      let shiftId = getters.getShiftIdFromShiftItem(assetType, shiftItem)

      if (!shiftId) {
        console.error('makeRevisionForShiftItemMod: shiftId is required')
        return
      }
      let shift = rootGetters.getAssgnt(assetType, shiftId)

      if (!shift) {
        console.error(
          `Shift id ${shiftId} not found for assetType ${assetType}`
        )
        return
      }
      let assetName = rootGetters.getAssetNameFromAssgnt(assetType, shift)

      let keysToMonitor = [
        'REGULAR_HOURS',
        'OT_HOURS',
        'PHASE',
        'COST_CODE',
        'LABOR_ASSIGNMENT',
        'REGULAR_HOURS_FROM_ASSET',
        'IS_DEFAULT',
        'TOTAL_HOURS',
      ]
      let updates = _omitBy(
        shiftItem,
        (val, key) => !keysToMonitor.includes(key)
      )
      // when adding a shift item on the Daily Report view, this will be undefined
      // which will force 'makeRevisionForAssetMod' to use id from getTheReport()
      let reportId = _get(shift, 'DAILY_REPORT[0].id')

      let updatedReport = await dispatch(
        'makeRevisionForAssetMod',
        {
          assetType,
          assetName: `Shift Item for ${assetType} "${assetName}"`,
          action,
          updates,
          reportId,
        },
        { root: true }
      )
      return updatedReport
    }, // makeRevisionForShiftItemMod

    getUniqueTimestamp({ state }) {
      let uniqueTimestamp = state.lastUsedTimestamp
        ? Math.max(state.lastUsedTimestamp + 1, Date.now())
        : Date.now()
      state.lastUsedTimestamp = uniqueTimestamp
      return uniqueTimestamp
    }, // getUniqueTimestamp
  }, // actions
}
