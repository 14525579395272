import Vue from 'vue'
import api from '@services/api'
import deepDiff from '@utils/deepDiff'
import dayjs from 'dayjs'
import _get from 'lodash/get'
import pAll from 'p-all'

import { ReportStatuses, LocationStatuses, HumanFields } from '@constants/knack'
import {
  RESET_DRAFT_REPORT,
  SET_REPORT,
  MODIFY_REPORT,
  SET_REPORTS,
  UPDATE_DRAFT_REPORT,
  SET_REPORTS_RESPONSE,
  DELETE_REPORT,
  UPDATE_SINGLE_REPORT,
  INCREMENT_UNSAVED_ASSETS,
  DECREMENT_UNSAVED_ASSETS,
  // Scheduler
  CREATE_SCHED_REPORT,
  UPDATE_SCHED_REPORT,
  REMOVE_SCHED_REPORT,
  CREATE_SCHED_GHOST_REPORT,
  REMOVE_SCHED_GHOST_REPORT,
  SCHED_REPORT_IS_PROCESSING,
  SCHED_REPORT_IS_NOT_PROCESSING,
} from '@constants/mutations'

import { createHelpers } from 'vuex-map-fields'
const rptFlds = HumanFields.DAILY_REPORT
const { getReportField, updateReportField } = createHelpers({
  getterType: 'getReportField',
  mutationType: 'updateReportField',
})

/**
 * @typedef Report
 * @property {?number} location - ID of location record
 * @property {?number} foreman - ID of laborer record
 * @property {Array<number>} labor - IDs of laborer records
 * @property {Array<number>} equipment - IDs of equipment records
 * @property {Array<number>} material - IDs of material records
 * @property {string} status - one of `ReportStatuses`
 */

/**
 * Factory function that generates an empty report object.
 * @return {Report}
 */
const newReport = () => ({
  status: ReportStatuses.SETUP,
})

export default {
  state: {
    // Q: What is the methodology behind {module}.state.data?
    data: {},
    // relationship: {
    //   [reportId]: [{
    //     {type: 'equipment', id: id, fields: {...}}
    //   }]
    // }
    draft: {},
    // draft: newReport(),

    // single report record (no metadata)
    report: {},

    reportMods: {},

    // multiple report records (plus total_records and other metadata)
    // response.records contains the records
    response: {},

    // easy-to-access collection set during fetchRecords
    reports: [],

    visibleQuickLinks: [],

    // Tracking whether there are unsaved changes on the Daily Report
    numUnsavedAssets: 0,

    isReportDirty: false,

    isReportUpdating: false,

    idleSaveCountDownPercentage: null,

    showSwitchLocationForReportModal: false,
  },
  mutations: {
    // single records
    [RESET_DRAFT_REPORT](state) {
      state.draft = newReport()
    },
    [SET_REPORT](state, report) {
      state.report = report
      // state.data = { ...state.data, [report.ID]: report }
    },
    [MODIFY_REPORT](state, updates) {
      // Always set the ID to our current view
      state.reportMods.ID = state.report.ID
      Object.keys(updates).map((key) => {
        state.report[key] = updates[key]
        state.reportMods[key] = updates[key]
      })
    },
    [DELETE_REPORT](state, report) {
      Vue.set(
        state,
        'reports',
        state.reports.filter((r) => r.ID !== report.ID)
      )
    },
    [UPDATE_DRAFT_REPORT](state, payload) {
      state.draft = { ...state.draft, ...payload }
    },
    [SET_REPORTS_RESPONSE](state, response) {
      state.response = response
    },
    [SET_REPORTS](state, reports) {
      state.reports = reports
    },
    [UPDATE_SINGLE_REPORT](state, report) {
      let currReport = state.reports.find((r) => r.ID === report.ID)
      let newReport = { ...currReport, ...report }
      Vue.set(state.reports, report.ID, newReport)
    },

    [INCREMENT_UNSAVED_ASSETS](state) {
      state.numUnsavedAssets++
    }, // INCREMENT_UNSAVED_ASSETS
    [DECREMENT_UNSAVED_ASSETS](state) {
      state.numUnsavedAssets--
    }, // DECREMENT_UNSAVED_ASSETS

    /**
     * Scheduler
     */
    [CREATE_SCHED_REPORT](state, report) {
      let newArray = [...state.reports]
      // Make sure status == "Setup"
      report = { ...report, REPORT_STATUS: ReportStatuses.SETUP }
      newArray.push(report)
      state.reports = newArray
    },
    [CREATE_SCHED_GHOST_REPORT](state, report) {
      let newArray = [...state.reports]
      newArray.push({ ...report, isGhost: true })
      state.reports = newArray
    },
    [REMOVE_SCHED_GHOST_REPORT](state, ghostId) {
      let ghostReport = state.reports.find((r) => r.ID === ghostId && r.isGhost)
      state.reports.splice(state.reports.indexOf(ghostReport), 1)
    },
    [UPDATE_SCHED_REPORT](state, updatedReport) {
      let oldReport = state.reports.find((r) => r.ID === updatedReport.ID)
      state.reports.splice(state.reports.indexOf(oldReport), 1, updatedReport)
    },
    [REMOVE_SCHED_REPORT](state, report) {
      let reportToDelete = state.reports.find((r) => r.ID === report.ID)
      state.reports.splice(state.reports.indexOf(reportToDelete), 1)
    },
    [SCHED_REPORT_IS_PROCESSING](state, report) {
      let theReport = state.reports.find((r) => r.ID === report.ID)
      let newGuy = { ...theReport, isProcessing: true }
      state.reports.splice(state.reports.indexOf(theReport), 1, newGuy)
    },
    [SCHED_REPORT_IS_NOT_PROCESSING](state, report) {
      let theReport = state.reports.find((r) => r.ID === report.ID)
      let newGuy = { ...theReport, isProcessing: false }
      state.reports.splice(state.reports.indexOf(theReport), 1, newGuy)
    },
    updateReportField,
  },
  actions: {
    // fetch all reports
    async fetchReports({ dispatch, getters, commit }, options) {
      try {
        await api.getReports(
          { options },
          (res) => {
            commit(SET_REPORTS_RESPONSE, res)
            let records = res.records || []
            commit(SET_REPORTS, records)
          },
          (err) => {
            // eslint-disable-next-line
            console.warn('Error : Couldnt fetch reports', {
              err,
            })
          }
        )
      } catch (err) {
        throw new Error(err)
      }
    },
    // get a single report
    async fetchReport({ getters, commit }, id) {
      try {
        const res = await api.getReport(id)
        // Knack will return *either* a report object or an
        // empty standard response like:
        //   {current_page: 1, records: [], total_pages: 0, total_records: 0}
        // so we can test against the presence/value of res.total_records
        let report = res.total_records === 0 ? false : res
        commit(SET_REPORT, report)
        commit(UPDATE_DRAFT_REPORT, report)
        return report
      } catch (err) {
        throw new Error(err)
      }
    },
    // update a single report
    async updateReport({ state, dispatch, commit, getters }, updates) {
      let report = updates.ID ? getters.getReportById(updates.ID) : state.report
      try {
        let revisions = await dispatch('updateReportRevisions', {
          report,
          mods: {
            ...updates,
            ...(Object.keys(updates).filter((k) => !['label', 'ID'].includes(k))
              .length === 1 && { single: true }),
          },
        })
        updates = { ...updates, REVISIONS: revisions }
        const updatedReport = await api.updateReport(report.ID, updates)
        commit(SET_REPORT, updatedReport)
        return updatedReport
      } catch (err) {
        throw new Error(err)
      }
    },

    // update a single report's revisions
    async saveReportRevisions({ commit }, { reportId, revisions }) {
      try {
        const updatedReport = await api.updateReport(reportId, {
          REVISIONS: revisions,
        })
        commit(SET_REPORT, updatedReport)
        return updatedReport
      } catch (err) {
        throw new Error(err)
      }
    }, // saveReportRevisions

    // update a single report
    async delReport({ getters, commit }, report) {
      try {
        const deleteResponse = await api.deleteReport(report.ID)
        if (deleteResponse.delete === true) {
          commit(DELETE_REPORT, report)
        }
        return deleteResponse
      } catch (err) {
        throw new Error(err)
      }
    },
    // update a single report
    async postReport({ getters, commit }, options) {
      try {
        const report = await api.post(
          { options, type: 'daily_report' },
          getters.accessToken
        )
        commit(SET_REPORT, report)
        return report
      } catch (err) {
        throw new Error(err)
      }
    },

    // Update Many Reports
    async batchUpdateReports({ commit }, { reports, cb }) {
      try {
        let updateCalls = reports.map((report) => {
          let batchUpdate = true
          return () =>
            api.updateReport(
              report.ID,
              report,
              cb,
              (err) => {
                throw new Error(err)
              },
              batchUpdate
            )
        })
        let responses = await pAll(updateCalls, { concurrency: 3 })
        return responses
      } catch (err) {
        throw new Error(err)
      }
    }, // batchUpdateReports

    modifyReport({ commit }, updatesObj) {
      commit(MODIFY_REPORT, updatesObj)
    }, // modifyReport

    incrementUnsavedAssets({ commit }) {
      commit(INCREMENT_UNSAVED_ASSETS)
    },
    decrementUnsavedAssets({ commit }) {
      commit(DECREMENT_UNSAVED_ASSETS)
    },

    /**
     *  SCHEDULER
     */

    // Add a report to the scheduler.
    // this will either create or updated a report
    // depending on whether it's new or being moved
    async addSchedReport(
      { dispatch, rootState },
      { foreman, index, draggedObj }
    ) {
      let startDate =
        rootState.route.query.startDate || dayjs().format('MM-DD-YYYY')

      let date = dayjs(startDate, 'MM-DD-YYYY')
        .add(index, 'day')
        .format('MM/DD/YYYY')

      let wasExistingReportDragged =
        typeof draggedObj.DAILY_REPORT_NAME !== 'undefined'

      let location = wasExistingReportDragged
        ? draggedObj.locationData
        : draggedObj

      let ghostId = `ghost_${draggedObj.ID}`

      let reportName = [location.JOB_NUMBER, location.IDENTIFIER, date].join(
        ' - '
      )

      // If this was a Report being moved "within" the cal
      if (wasExistingReportDragged) {
        let updatedReport = await dispatch('moveSchedReport', {
          foreman,
          draggedObj,
          ghostId,
          date,
          reportName,
        })
        return { updatedReport }
      }

      // If this was a Report being created from outside the cal
      // e.g. it was a Location Item being dragged onto the cal
      else {
        let newReport = await dispatch('newSchedReport', {
          location,
          foreman,
          draggedObj,
          ghostId,
          date,
          reportName,
        })
        return { newReport }
      }
    }, // addSchedReport

    async moveSchedReport(
      { dispatch },
      { foreman, draggedObj, ghostId, date, reportName }
    ) {
      dispatch('createSchedGhostReport', {
        location: draggedObj,
        ghostId,
        date,
        foreman,
      })

      let mods = {
        FOREMANS_NAME: foreman.LABORER_NAME,
        REPORT_DATE: date,
        DAILY_REPORT_NAME: reportName,
      }
      let revisions = await dispatch('updateReportRevisions', {
        report: draggedObj,
        mods,
      })
      let report = {
        ID: draggedObj.ID,
        [rptFlds.REVISIONS]: revisions,
        [rptFlds.FOREMAN]: foreman.ID,
        [rptFlds.REPORT_DATE]: date,
        [rptFlds.DAILY_REPORT_NAME_FORMULA]: reportName,
      }

      // eslint-disable-next-line
      // console.log('[ updateSchedReport ]', report)

      let oldReport = draggedObj
      let updatedReport
      try {
        updatedReport = await dispatch('updateSchedReport', {
          report,
          oldReport,
          ghostId,
        })
        Vue.notify({
          type: 'success',
          title: 'Report updated',
        })
      } catch (error) {
        // If this is a timeout error
        Vue.$raven.captureException(error)
        Vue.notify({
          type: 'warn',
          title: 'Failed to update Report',
        })
      }
      return updatedReport
    }, // moveSchedReport

    // This is a "meta action" that not only creates a report but
    // also creates a ghost report and initializes the revisions
    // and manages the notifications on success/error
    async newSchedReport(
      { dispatch },
      { location, foreman, draggedObj, ghostId, date, reportName }
    ) {
      await dispatch('createSchedGhostReport', {
        location: draggedObj,
        ghostId,
        date,
        foreman,
      })

      let address =
        [
          location.LOCATION.street,
          location.LOCATION.city,
          location.LOCATION.state,
        ].join(', ') || ''

      // Initial Creation Revision
      let revisions = await dispatch('updateReportRevisions')

      let report = {
        [rptFlds.FOREMAN]: foreman.ID,
        [rptFlds.REPORT_DATE]: date,
        [rptFlds.LOCATION]: location.ID,
        [rptFlds.LOCATION_ADDRESS]: address,
        [rptFlds.LOCATION_IDENTIFIER]: location.IDENTIFIER,
        [rptFlds.LOCATION_PHASE_]: location.PHASE_,
        [rptFlds.PERMIT_START_TIME]: location.PERMIT_START_TIME,
        [rptFlds.PERMIT_END_TIME]: location.PERMIT_END_TIME,
        [rptFlds.CLIENT_NAME]: location.CLIENT_NAME,
        [rptFlds.JOB_NUMBER]: location.JOB_NUMBER,
        [rptFlds.REPORT_STATUS]: ReportStatuses.SETUP,
        [rptFlds.DAILY_REPORT_NAME_FORMULA]: reportName,
        [rptFlds.REVISIONS]: revisions,
      }

      let newReport
      try {
        newReport = await dispatch('createSchedReport', { report, ghostId })
        Vue.notify({
          type: 'success',
          title: 'Report Created',
          text: newReport.DAILY_REPORT_NAME,
        })
      } catch (error) {
        Vue.notify({
          type: 'warn',
          title: 'Could not create report',
        })
        throw error
      }
      return newReport
    }, // newSchedReport

    // Create a single report
    async createSchedReport(
      { getters, commit, rootGetters, dispatch },
      { report, ghostId }
    ) {
      try {
        report = {
          ...report,
          [HumanFields.DAILY_REPORT.REPORT_STATUS]: ReportStatuses.SCHEDULED,
        }
        // eslint-disable-next-line
        console.log('[ createSchedReport ]', report)

        const createReportResponse = await api.createReport(
          report,
          (createdReport) => {
            commit(CREATE_SCHED_REPORT, createdReport)
            commit(REMOVE_SCHED_GHOST_REPORT, ghostId)
            // Get the the location somewhere in store
            // (it should almost def be there, bc we just added it)
            let locId = createdReport.LOCATION[0].id
            let locationData = rootGetters.getLocationFromId(locId)

            locationData = dispatch('updateLocation', {
              ID: locId,
              NUMBER_OF_DAILY_REPORTS: locationData.NUMBER_OF_DAILY_REPORTS + 1,
              STATUS: LocationStatuses.WORK_SCHEDULED,
            })
            createdReport.locationData = locationData
            return createdReport
          },
          (err) => {
            commit(REMOVE_SCHED_GHOST_REPORT, ghostId)
            throw err
          }
        )

        return createReportResponse
      } catch (err) {
        throw new Error(err)
      }
    }, // createSchedReport

    // Update a single report
    async updateSchedReport(
      { commit, dispatch },
      { report, oldReport, ghostId }
    ) {
      commit(SCHED_REPORT_IS_PROCESSING, oldReport)
      const updatedReportReponse = await api.updateReport(
        report.ID,
        report,
        (updatedReport) =>
          dispatch('updateReportSuccess', { updatedReport, ghostId }),
        (err) => {
          dispatch('updateReportFailure', { oldReport, ghostId })
          throw err
        }
      )
      return updatedReportReponse
    }, // updateSchedReport

    updateReportSuccess({ commit }, { updatedReport, ghostId }) {
      commit(UPDATE_SCHED_REPORT, updatedReport)
      if (ghostId) {
        commit(REMOVE_SCHED_GHOST_REPORT, ghostId)
      }
      return updatedReport
    },

    updateReportFailure({ commit }, { oldReport, ghostId }) {
      commit(SCHED_REPORT_IS_NOT_PROCESSING, oldReport)
      if (ghostId) {
        commit(REMOVE_SCHED_GHOST_REPORT, ghostId)
      }
      return oldReport
    }, // updateReportFailure

    // Delete a single report
    async deleteSchedReport({ getters, commit, dispatch }, report) {
      try {
        commit(SCHED_REPORT_IS_PROCESSING, report)

        let deleteResponse = await api.deleteReport(
          report.ID,
          (response) =>
            dispatch('maybeDeleteReportSuccess', { response, report }),
          (err) => {
            commit(SCHED_REPORT_IS_NOT_PROCESSING, report)
            throw err
          }
        )
        return deleteResponse
      } catch (err) {
        throw new Error(err)
      }
    }, // deleteSchedReport

    async maybeDeleteReportSuccess({ commit, dispatch }, { response, report }) {
      // If its a good Knack response, remove the record
      // from the schedule
      if (response.delete === true) {
        // If we just removed the last daily report assigned to this location,
        // update the Loc status to "ready to work"
        if (report.locationData.NUMBER_OF_DAILY_REPORTS - 1 === 0) {
          let location = {
            ID: report.locationData.ID,
            STATUS: LocationStatuses.READY_TO_WORK,
          }
          await dispatch('updateLocation', location)
        }
        commit(REMOVE_SCHED_REPORT, report)
      } else {
        // Otherwise leave it alone
        commit(SCHED_REPORT_IS_NOT_PROCESSING, report)
      }
      return response
    }, // maybeDeleteReportSuccess

    // Must imitate the obj/array structure
    // of the Report obj coming from Knack
    createSchedGhostReport({ commit }, { location, ghostId, date, foreman }) {
      let ghostReport = {
        ...location,
        LOCATION: [{ identifier: location.IDENTIFIER, id: location.ID }], // redundant? dangerous? stup
        ID: ghostId,
        REPORT_DATE: { date },
        FOREMAN: [{ identifier: foreman.LABORER_NAME, id: foreman.ID }],
      }
      commit(CREATE_SCHED_GHOST_REPORT, ghostReport)
    }, // createSchedGhostReport

    removeSchedGhostReport({ commit }, ghostId) {
      commit(REMOVE_SCHED_GHOST_REPORT, ghostId)
    }, // removeSchedGhostReport

    initRevision({ rootGetters }) {
      let nowEDT = new Date().toLocaleString('en-US', {
        timeZone: 'America/New_York',
      })
      let now = new Date(Date.now())
      let author = `${rootGetters.firstName} ${rootGetters.lastName}`
      let newRevision = {
        timestamp: dayjs(now).toISOString(),
        // and the '-' bc TZ offset represents the reverse direction
        // e.g. L.A. is -7 from GMT so it's "420 from GMT"
        timezone: now.getTimezoneOffset() / -60,
        date: dayjs(nowEDT).format('MMM DD, YYYY, h:mm a [EDT]'),
        author,
      }
      return newRevision
    }, // initRevision

    async updateReportRevisions({ dispatch }, payload) {
      let defaults = { report: false, mods: false }
      payload = { ...defaults, ...payload }
      let { report, mods } = payload

      let newRevision = await dispatch('initRevision')

      if (!report && !mods) {
        newRevision.creationRevision = true
        return JSON.stringify([newRevision])
      }

      let details
      let updates = {}
      let newValues = { ...mods }
      // Don't *EVER* save nested revision in history
      // Remove that key if it ended up here somehow...
      let modKeys = Object.keys(newValues).filter((key) => key !== 'REVISIONS')

      // REPORT ASSET UPDATE
      if (mods.action) {
        details = {
          single: true,
          asset: true,
          mods,
        }
      } else if (mods.changeGroups) {
        details = {
          changeGroups: mods.changeGroups,
        }
      }
      // DAILY REPORT BATCH UPDATE
      else if (!mods.single) {
        modKeys.map((key) => {
          // special jam for REPORT_DATE
          if (key === 'REPORT_DATE') {
            updates[key] = {
              new: newValues[key],
              old: _get(report, 'REPORT_DATE.date'),
            }
          } else {
            updates[key] = {
              new: newValues[key],
              old: report[key],
            }
          }
        })
        details = {
          single: false,
          mods: { updates },
        }
      }
      //
      // DAILY REPORT OBJ SINGLE KEY UPDATE
      //
      // we're expecting at least one key to update and a `label` value
      else {
        let key = modKeys[0]
        details = {
          single: true,
          key: newValues.label || key,
          old: report[key],
          new: newValues[key],
        }
      }
      let prevRevisions =
        typeof report.REVISIONS === 'string' && report.REVISIONS.length
          ? JSON.parse(report.REVISIONS)
          : report.REVISIONS || []
      let revisionWithMods = {
        ...newRevision,
        details,
      }
      return Object.freeze(JSON.stringify([revisionWithMods, ...prevRevisions]))
    }, // updateReportRevisions
  }, // actions
  getters: {
    getReport: (state) => (id) => state.report,
    getReportById: (state) => (reportId) =>
      state.reports.find((r) => r.ID === reportId),
    getReportDraft: (state) => state.draft,
    getTheReport: (state, getters, rootState) => {
      return rootState.route
        ? getters.getReport(rootState.route.params.reportId)
        : {}
    },
    getNumUnsavedAssets: (state) => state.numUnsavedAssets,
    getTheReportDate: (_, getters) =>
      _get(getters.getTheReport, 'REPORT_DATE.date'), // getTheReportDate
    getTheReportForemanId: (_, getters) =>
      _get(getters.getTheReport, 'FOREMAN[0].id'), // getTheReportForeman
    getReportsResponse: (state) => state.response,
    getReports: (state) => state.reports,
    getReportDiff: (state) => deepDiff(state.draft, state.report),
    getJobNumbersFromReports: (state) => state.reports.map((r) => r.JOB_NUMBER),
    getClientNamesFromReports: (state) =>
      state.reports.map((r) => r.CLIENT_NAME),
    getReportByLocationId: (state) => (locId) =>
      state.reports.filter((r) =>
        r.LOCATION ? r.LOCATION[0].id === locId : false
      ),
    getReportField,
  },
}
