<template>
  <VDialog value="show" :max-width="400" persistent>
    <VCard class="text-xs-center">
      <VCardTitle class="orange accent-2 white--text">
        <VFlex xs12 mb-2
          ><h2>
            Knack Error
          </h2></VFlex
        >
      </VCardTitle>
      <VCardText class="font-weight-medium pa-5">
        <p class="px-2"
          >Sorry but there was a problem connecting to the Knack Database that
          requires the page to be reloaded.</p
        >
        <transition name="fade-fast">
          <p v-if="seconds"
            >If the page does not refresh in {{ seconds }} seconds</p
          >
        </transition>
        <BaseButton color="blue" outline @click="reload"
          >Click to Reload</BaseButton
        >
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
// import { mapState } from 'vuex'
import VDialog from '@vuetify/VDialog'
import { VCardText } from '@vuetify/VCard'

export default {
  name: 'KnackErrorModal',
  components: {
    VDialog,
    VCardText,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      seconds: this.$store.state.api.numSecondsForReload,
    }
  },
  computed: {
    // ...mapState(['numSecondsForReload']),
  },
  created() {
    setInterval(() => {
      if (this.seconds === 0) return
      this.seconds--
    }, 1000)
    setTimeout(() => {
      window.location.reload()
    }, this.seconds * 1000)
  },
  methods: {
    reload() {
      window.location.reload()
    }, // reload
  },
}
</script>
