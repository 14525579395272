<template>
  <VForm @submit.prevent="$emit('search', searchTerm)">
    <BaseInput
      v-model="searchTerm"
      v-bind="allBindings"
      class="search-field"
      :label="placeholder"
      @input="$emit('searchTermUpdate', $event)"
      @click:clear="$emit('clear')"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
  </VForm>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import BaseInput from '@base/Input'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'BaseSearch',
  components: { BaseInput },
  props: {
    ...BaseInput.props,
    placeholder: {
      type: String,
      default: 'Type to Search',
    },
    // messages: {
    //   type: Array,
    //   default: [],
    // },
  },
  data() {
    return {
      searchTerm: '',
    }
  },
  computed: {
    ...routeState(['query']),
    hasSearchString() {
      return !!this.searchTerm || !!this.query.s
    }, // hasSearchString
    allBindings() {
      // Need to proxify both props and attrs, for example for showLabels
      let defaults = {
        outline: true,
        color: 'white',
        'prepend-inner-icon': 'search',
        flex: true,
        'single-line': true,
        dark: true,
        clearable: true,
      }
      return { ...defaults, ...this.$props, ...this.$attrs }
    },
  },
  created() {
    this.searchTerm = this.query.s
  },
  methods: {},
}
</script>
