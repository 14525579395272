import MockAdapter from 'axios-mock-adapter'
import { AssetTypes } from '@constants/knack'
import SchedulerLocations from '@tests/mocks/scheduler-locations'
import SchedulerReports from '@tests/mocks/scheduler-reports'
import SchedulerLocationsTwoWeeks from '@tests/mocks/scheduler-locations-2wk'
import SchedulerReportsTwoWeeks from '@tests/mocks/scheduler-reports-2wk'
import Foremen from '@tests/mocks/foremen'
import TodaysReports from '@tests/mocks/todays-reports'
import TheReport from '@tests/mocks/DailyReport/the-report'
import TheLocation from '@tests/mocks/DailyReport/the-location'
import LaborAssignments from '@tests/mocks/DailyReport/labor-assignments'
import EquipmentUsage from '@tests/mocks/DailyReport/equipment-usage'
import ReportLaborShiftItems from '@tests/mocks/DailyReport/labor-shift-items'
import ReportEquipmentShiftItems from '@tests/mocks/DailyReport/equipment-shift-items'
import MaterialUsage from '@tests/mocks/DailyReport/material-usage'
import PayworkItems from '@tests/mocks/paywork-items'
import MaterialParts from '@tests/mocks/materialparts'
import AllCostCodes from '@tests/mocks/all-cost-codes'
import AllPhases from '@tests/mocks/all-phases'
import DispatcherAvailableLaborers from '@tests/mocks/dispatcher-available-laborers'
import LaborAssignmentSchemaFields from '@tests/mocks/DailyReport/labor-assignment-schema-fields'
import CompletedWorkQuantities from '@tests/mocks/DailyReport/completed-work-quantities'

export default (lambdaApi) => {
  const mock = new MockAdapter(lambdaApi, {
    onNoMatch: 'passthrough',
    delayResponse: 300,
  })
  const baseURL = '/.netlify/functions'

  mock
    .onPost(`${baseURL}/getRecords`, {
      options: {
        view_mode: true,
        rows_per_page: 1000,
        filters: {
          match: 'and',
          rules: [
            { field: 'field_23', operator: 'is not', value: 'Cancelled' },
            { field: 'field_23', operator: 'is not', value: 'Complete' },
            { field: 'field_23', operator: 'is not', value: 'Hold' },
            { field: 'field_23', operator: 'is not', value: 'Invoiced' },
            {
              field: 'field_23',
              operator: 'is not',
              value: 'Payment Recieved',
            },
            {
              field: 'field_23',
              operator: 'is not',
              value: 'Permit Approved But Not Paid Yet',
            },
            { field: 'field_23', operator: 'is not', value: 'Permit Expired' },
            {
              field: 'field_23',
              operator: 'is not',
              value: 'Permit Filed Waiting For Approval',
            },
            {
              field: 'field_23',
              operator: 'is not',
              value: 'Permit Not Filed Yet',
            },
            {
              field: 'field_23',
              operator: 'is not',
              value: 'Waiting For Code 53 Check',
            },
            { field: 'field_23', operator: 'is not', value: 'Waiting For NTP' },
            { field: 'field_23', operator: 'is not', value: 'Sub Work Only' },
            {
              field: 'field_23',
              operator: 'is not',
              value: 'Reported As Complete By Foreman',
            },
            { field: 'field_47', operator: 'is today or after' },
          ],
        },
      },
      type: 'locations',
    })
    .reply(200, SchedulerLocations)

  mock
    .onPost(`${baseURL}/getReports`, {
      options: {
        rows_per_page: 1000,
        view_mode: true,
        filters: {
          match: 'and',
          rules: [
            {
              field: 'field_244',
              type: 'date',
              operator: 'is after',
              value: { all_day: false, date: '04/09/2023' },
            },
            {
              field: 'field_244',
              type: 'date',
              operator: 'is before',
              value: { all_day: false, date: '04/18/2023' },
            },
          ],
        },
      },
    })
    .reply(200, SchedulerReports)

  mock
    .onPost(`${baseURL}/getReports`, {
      options: {
        rows_per_page: 1000,
        view_mode: true,
        filters: {
          match: 'and',
          rules: [
            {
              field: 'field_244',
              type: 'date',
              operator: 'is after',
              value: { all_day: false, date: '05/29/2023' },
            },
            {
              field: 'field_244',
              type: 'date',
              operator: 'is before',
              value: { all_day: false, date: '06/14/2023' },
            },
          ],
        },
      },
    })
    .reply(200, SchedulerReportsTwoWeeks)

  mock
    .onPost(`${baseURL}/getRecords`, {
      options: {
        view_mode: true,
        filters: {
          match: 'or',
          rules: [
            {
              field: 'field_19',
              operator: 'is',
              value: 'Bldg. #60 E. 42nd St.',
            },
            {
              field: 'field_19',
              operator: 'is',
              value: 'Bldg. 87 Commercial Street',
            },
            { field: 'field_19', operator: 'is', value: 'Pole 8 to Pole 8.5' },
            { field: 'field_19', operator: 'is', value: 'BKL_BWK_N080' },
            { field: 'field_19', operator: 'is', value: 'MANU_HRM_N049' },
            { field: 'field_19', operator: 'is', value: 'NYMH1049' },
            { field: 'field_19', operator: 'is', value: 'NYD1044_D' },
            { field: 'field_19', operator: 'is', value: 'NYD1487_Repair' },
            { field: 'field_19', operator: 'is', value: 'NYD1814' },
            { field: 'field_19', operator: 'is', value: 'NYD 4326' },
            {
              field: 'field_19',
              operator: 'is',
              value: '79th Street Transverse',
            },
            { field: 'field_19', operator: 'is', value: 'BNX_BRD_N003' },
            { field: 'field_19', operator: 'is', value: 'BNX_SPD_N001' },
            {
              field: 'field_19',
              operator: 'is',
              value: 'Bldg. 1568 Broadway Emergency',
            },
            { field: 'field_19', operator: 'is', value: 'VZMH 377' },
            { field: 'field_19', operator: 'is', value: 'Stamford ' },
            { field: 'field_19', operator: 'is', value: 'Job Preperation' },
            { field: 'field_19', operator: 'is', value: 'CRAN_RNYJ_00005_7' },
            {
              field: 'field_19',
              operator: 'is',
              value: '24th Ave Wood Pole to Wood Pole',
            },
            {
              field: 'field_19',
              operator: 'is',
              value: 'VZMH 156 to VZMH 158 to VZMH 404',
            },
          ],
        },
        rows_per_page: 1000,
      },
      type: 'locations',
    })
    .reply(200, SchedulerLocationsTwoWeeks)

  mock
    .onPost(`${baseURL}/getReports`, {
      options: {
        rows_per_page: 9,
        filters: {
          match: 'and',
          rules: [
            { field: 'field_244', operator: 'is today' },
            { field: 'field_299', operator: 'is not', value: 'Locked' },
          ],
        },
      },
    })
    .reply(200, TodaysReports)

  mock
    .onPost(`${baseURL}/getRecords`, {
      options: {
        rows_per_page: 1000,
        filters: {
          rules: [{ field: 'field_258', operator: 'is', value: 'Foreman' }],
        },
        sort_field: 'field_641',
        sort_order: 'asc',
      },
      type: 'laborer',
    })
    .reply(200, Foremen)

  // Daily Report
  mock
    .onPost(`${baseURL}/getSingleRecord`, {
      options: { ID: '63875d265fc5ea0021e8b410' },
      type: 'daily_report',
    })
    .reply(200, TheReport)

  mock
    .onPost(`${baseURL}/getSingleRecord`, {
      options: { ID: '63371dbc5739880021fd3aaa' },
      type: 'locations',
    })
    .reply(200, TheLocation)

  mock
    .onPost(`${baseURL}/getRecords`, {
      options: {
        assetType: 'LABOR_ASSIGNMENT',
        reportId: '6463c79a3602e40028c03d87',
        filters: {
          match: 'and',
          rules: [
            {
              field: 'field_275',
              operator: 'is',
              value: '6463c79a3602e40028c03d87',
            },
          ],
        },
        rows_per_page: 1000,
      },
      type: 'LABOR_ASSIGNMENT',
    })
    .reply(200, LaborAssignments)

  mock
    .onPost(`${baseURL}/getRecords`, {
      options: {
        assetType: 'EQUIPMENT_USAGE',
        reportId: '6463c79a3602e40028c03d87',
        filters: {
          match: 'and',
          rules: [
            {
              field: 'field_277',
              operator: 'is',
              value: '6463c79a3602e40028c03d87',
            },
          ],
        },
        rows_per_page: 1000,
      },
      type: 'EQUIPMENT_USAGE',
    })
    .reply(200, EquipmentUsage)

  mock
    .onPost(`${baseURL}/getRecords`, {
      options: {
        filters: {
          match: 'or',
          rules: [
            {
              field: 'field_1100',
              operator: 'is',
              value: '6487c2e800001b0028d1e8da',
            },
            {
              field: 'field_1100',
              operator: 'is',
              value: '648896430b4c8100299ef2da',
            },
            {
              field: 'field_1100',
              operator: 'is',
              value: '6487c2ebf7d2a7001dc29274',
            },
          ],
        },
        assetType: 'SHIFT_ITEMS',
        rows_per_page: 1000,
      },
      type: 'SHIFT_ITEMS',
    })
    .reply(200, ReportLaborShiftItems)

  mock
    .onPost(`${baseURL}/getRecords`, {
      options: {
        filters: {
          match: 'or',
          rules: [
            {
              field: 'field_1101',
              operator: 'is',
              value: '647a6e3b59cfa50027829922',
            },
            {
              field: 'field_1101',
              operator: 'is',
              value: '647a6e3b7777fd0029b0a143',
            },
            {
              field: 'field_1101',
              operator: 'is',
              value: '647a6e3b0d730f0028e3be36',
            },
          ],
        },
        assetType: 'SHIFT_ITEMS',
        rows_per_page: 1000,
      },
      type: 'SHIFT_ITEMS',
    })
    .reply(200, ReportEquipmentShiftItems)

  mock
    .onPost(`${baseURL}/getRecords`, {
      options: {
        assetType: 'MATERIAL_USAGE',
        reportId: '63875d265fc5ea0021e8b410',
        filters: {
          match: 'and',
          rules: [
            {
              field: 'field_281',
              operator: 'is',
              value: '63875d265fc5ea0021e8b410',
            },
          ],
        },
        rows_per_page: 1000,
      },
      type: 'MATERIAL_USAGE',
    })
    .reply(200, MaterialUsage)

  mock
    .onPost(`${baseURL}/getObjectSchemaFields`, { objectId: 'object_24' })
    .reply(200, LaborAssignmentSchemaFields)

  mock
    .onPost(`${baseURL}/getRecords`, {
      options: {
        assetType: 'COMPLETED_WORK_QUANTITY',
        reportId: '63875d265fc5ea0021e8b410',
        filters: {
          match: 'and',
          rules: [
            {
              field: 'field_286',
              operator: 'is',
              value: '63875d265fc5ea0021e8b410',
            },
          ],
        },
        rows_per_page: 1000,
      },
      type: 'COMPLETED_WORK_QUANTITY',
    })
    .reply(200, CompletedWorkQuantities)

  mock
    .onPost(`${baseURL}/getRecords`, {
      options: {
        assetType: 'paywork_item',
        rows_per_page: 1000,
        filters: [{ field: 'field_470', operator: 'contains', value: 'Fiber' }],
      },
      type: 'paywork_item',
    })
    .reply(200, PayworkItems)

  mock
    .onPost(`${baseURL}/getRecords`, {
      options: {
        assetType: 'materialpart',
        rows_per_page: 1000,
        filters: [{ field: 'field_469', operator: 'contains', value: 'Fiber' }],
      },
      type: 'materialpart',
    })
    .reply(200, MaterialParts)

  mock
    .onPost(`${baseURL}/getRecords`, {
      options: {
        rows_per_page: 1000,
      },
      type: 'cost_codes',
    })
    .reply(200, AllCostCodes)

  mock
    .onPost(`${baseURL}/getRecords`, {
      options: {
        rows_per_page: 1000,
      },
      type: 'phases',
    })
    .reply(200, AllPhases)

  mock
    .onPost(`${baseURL}/getRecords`, {
      options: {
        assetType: AssetTypes.LABORER,
        rows_per_page: 1000,
        filters: {
          match: 'and',
          rules: [
            { field: 'field_300', operator: 'is not', value: 'Inactive' },
          ],
        },
      },
      type: AssetTypes.LABORER,
    })
    .reply(200, DispatcherAvailableLaborers)
}
