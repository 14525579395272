var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VBtn',_vm._g(_vm._b({class:['btn-base', `${_vm.textColor}--text`],attrs:{"title":_vm.mdIcon == 'save' ? 'Save' : '',"disabled":_vm.disabled},on:{"click":_vm.onClick}},'VBtn',_vm.allPropsAndAttrs,false),_vm.$listeners),[(_vm.mdIcon.length && _vm.mdIconPosition === 'left')?_c('VIcon',{class:[
      `material-icons${_vm.mdIconStyle ? '-' + _vm.mdIconStyle : ''}`,
      { 'mr-2': !_vm.isSoloIconButton },
      _vm.mdIconClass,
    ],attrs:{"left":!_vm.hasIconAttr && !_vm.noIconPadding,"small":_vm.allPropsAndAttrs.small}},[_vm._v(_vm._s(_vm.mdIcon))]):_vm._e(),_vm._t("default"),(_vm.mdIcon.length && _vm.mdIconPosition === 'right')?_c('VIcon',{class:[
      `material-icons${_vm.mdIconStyle ? '-' + _vm.mdIconStyle : ''}`,
      { 'ml-2': !_vm.isSoloIconButton },
      _vm.mdIconClass,
    ],attrs:{"small":_vm.allPropsAndAttrs.small,"right":!_vm.hasIconAttr && !_vm.noIconPadding}},[_vm._v(_vm._s(_vm.mdIcon))]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }