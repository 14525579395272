import {
  OKTA_CLIENT_ID,
  OKTA_PATH,
  OKTA_TESTING_DISABLEHTTPSCHECK,
} from '../constants/credentials'
/**
 * @fileoverview Lists all User Roles and Okta related info.
 */

export const Roles = Object.freeze({
  ADMIN: 'Administrator',
  ACCOUNTANT: 'Accountant',
  DISPATCHER: 'Dispatcher',
  FOREMAN: 'Foreman',
  PROJECT_MANAGER: 'Project Manager',
  SCHEDULER_EDIT: 'Scheduler_Edit',
  SCHEDULER_VIEW: 'Scheduler_View',
})

const redirectUri =
  window.location.protocol + '//' + window.location.host + '/implicit/callback/'

export const OktaConfig = Object.freeze({
  clientId: OKTA_CLIENT_ID,
  issuer: `${OKTA_PATH}/oauth2/default`,
  redirectUri,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  // https://github.com/okta/okta-auth-js#the-tokenmanager
  tokenManager: {
    storage: 'localStorage',
  },
  testing: {
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  onSessionExpired: function() {
    // eslint-disable-next-line
    console.log(
      'Token Expired. This message was logged from OktaClient.onSessionExpired() '
    )
  },
})
