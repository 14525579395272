import strToBool from '../utils/strToBool'
require('dotenv').config()

/// /////////////////////////
//    KNACK
/// /////////////////////////

export const KNACK_DB_VERSION =
  process.env.VUE_APP_KNACK_DB_VERSION || 'development'
export const KNACK_API_KEY = process.env.VUE_APP_KNACK_API_KEY
export const KNACK_APPLICATION_ID = process.env.VUE_APP_KNACK_APPLICATION_ID

/// /////////////////////////
//    OKTA
/// /////////////////////////

export const OKTA_AUTH_TOKEN = process.env.VUE_APP_OKTA_AUTH_TOKEN
export const OKTA_CLIENT_ID = process.env.VUE_APP_OKTA_CLIENT_ID
export const OKTA_PATH = process.env.VUE_APP_OKTA_PATH
export const OKTA_TESTING_DISABLEHTTPSCHECK = strToBool(
  process.env.VUE_APP_OKTA_TESTING_DISABLEHTTPSCHECK
)

/// /////////////////////////
//    GOOGLE
/// /////////////////////////

export const GOOGLE_CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID
export const GOOGLE_SECRET = process.env.VUE_APP_GOOGLE_SECRET
export const GOOGLE_REDIRECT_URL = process.env.VUE_APP_GOOGLE_REDIRECT_URL

/// /////////////////////////
//    SENTRY
/// /////////////////////////

export const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN
export const SENTRY_PROJECT_ID = process.env.VUE_APP_SENTRY_PROJECT_ID

/// /////////////////////////
//    TWILIO
/// /////////////////////////

export const TWILIO_ACCOUNT_ID = process.env.VUE_APP_TWILIO_ACCOUNT_ID
export const TWILIO_FLOW_ID = process.env.VUE_APP_TWILIO_FLOW_ID
export const TWILIO_AUTH_TOKEN = process.env.VUE_APP_TWILIO_AUTH_TOKEN
export const TWILIO_NUMBER = process.env.VUE_APP_TWILIO_NUMBER
export const TWILIO_MSG_SERVICE_ID = process.env.TWILIO_MSG_SERVICE_ID
