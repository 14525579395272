<template>
  <Layout>
    <transition name="fade-fast" appear>
      <SignIn />
    </transition>
  </Layout>
</template>

<script>
import Layout from '@layouts/main'
import SignIn from '@components/HomeView/SignIn'

export default {
  name: 'Home',
  components: { Layout, SignIn },
}
</script>
