<template>
  <VBtn
    :class="['btn-base', `${textColor}--text`]"
    :title="mdIcon == 'save' ? 'Save' : ''"
    v-bind="allPropsAndAttrs"
    :disabled="disabled"
    v-on="$listeners"
    @click="onClick"
  >
    <VIcon
      v-if="mdIcon.length && mdIconPosition === 'left'"
      :class="[
        `material-icons${mdIconStyle ? '-' + mdIconStyle : ''}`,
        { 'mr-2': !isSoloIconButton },
        mdIconClass,
      ]"
      :left="!hasIconAttr && !noIconPadding"
      :small="allPropsAndAttrs.small"
      >{{ mdIcon }}</VIcon
    >
    <slot></slot>
    <VIcon
      v-if="mdIcon.length && mdIconPosition === 'right'"
      :class="[
        `material-icons${mdIconStyle ? '-' + mdIconStyle : ''}`,
        { 'ml-2': !isSoloIconButton },
        mdIconClass,
      ]"
      :small="allPropsAndAttrs.small"
      :right="!hasIconAttr && !noIconPadding"
      >{{ mdIcon }}</VIcon
    >
  </VBtn>
</template>

<script>
import VBtn from '@vuetify/VBtn/'
export default {
  name: 'BaseButton',
  components: { VBtn },
  props: {
    ...VBtn.props,
    mdIcon: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    mdIconPosition: {
      type: String,
      default: 'right',
    },
    mdIconStyle: {
      type: String,
      default: null,
    },
    mdIconClass: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'teal',
    },
    onClick: {
      type: Function,
      required: false,
      default: () => () => {},
    },
    textColor: {
      type: String,
      required: false,
      default: 'white',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noIconPadding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    allPropsAndAttrs() {
      return { ...this.$props, ...this.$attrs }
    }, // allPropsAndAttrs
    hasIconAttr() {
      return typeof this.$attrs.icon !== 'undefined'
    }, // hasIconAttr
    hasFabAttr() {
      return typeof this.$attrs.fab !== 'undefined'
    }, // hasFabAttr
    isSoloIconButton() {
      return this.hasIconAttr || this.hasFabAttr
    }, // isSoloIconButton
  },
}
</script>

<style lang="scss" scoped>
.btn-base {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.03em;
  &.v-btn {
    min-width: 40px;
  }
  &.v-btn--xs {
    $dim: 30px;
    height: $dim;
    width: $dim;
    min-width: $dim;
    min-height: $dim;
  }
}
</style>
