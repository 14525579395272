import api from '@services/api'
import { ContentTypes } from '@constants/knack'
import {
  SET_EXAMPLE,
  EXAMPLE_UPDATE_TIME
} from '@constants/mutations'

/**
 * @typedef ExampleObj
 * @property {Timestamp} timestamp - time the record was created
 * @property {Object} parent - the parent object for this Example obj
 * @property {?Array} relationships - genericized related data
 */

/**
 * Factory function that generates an empty example object.
 * @return {ExampleObj}
 */
const newExampleObj = () => ({
  timestamp: Date.now(),
  parent: null,
  relationships: [],
})

export default {
  state: {
    // Q: What is the methodology behind {module}.state.data?
    data: {},
    draft: newExampleObj(),
  },
  mutations: {
    [SET_EXAMPLE](state, exampleObj) {
      state.data = { ...state.date, [exampleObj.id]: exampleObj }
    },
    [EXAMPLE_UPDATE_TIME](state) {
      state.draft = { ...state.draft, timestamp : Date.now() }
    },
  },
  actions: {
    async createExample({ state, getters, commit }) {
      try {
        const example = await api.post({
          type: ContentTypes.EXAMPLE_TYPE,
          data : state.draft
        })
        commit(SET_EXAMPLE, example)
        return getters.getExample(example.id)
      } catch (err) {
        throw new Error(err)
      }
    },
    async fetchExample({ getters, commit }, id) {
      try {
        // TODO (ES): Swap out this example for the real API
        const example = {
          id : '1',
          fields : { thing_1 : 'foo', second_thing : 'bar ', timestamp: Date.now() }
        }
        // const example = await api.get({
        //   type: ContentTypes.EXAMPLE_TYPE,
        //   id : id
        // })
        commit(SET_EXAMPLE, example)
        return getters.getExample(id)
      } catch (err) {
        throw new Error(err)
      }
    },
    async updateExample({commit}, {id,payload}) {
      const example = await api.update({
        type : ContentTypes.EXAMPLE_TYPE,
        id : id,
        fields : payload
      })
      commit(SET_EXAMPLE, example);
    }
  },
  getters: {
    // Retrieve one specific object from state.data, which could have
    // many "example" objects saved to it
    getExample: (state) => (id) =>
      Object.keys(state.data).includes(id) ? state.data[id] : null,
  },
}
