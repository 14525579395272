/**
 * @fileoverview This will automatically register any components you place in
 * the components/Base directory as a global component, where the component name
 * is the file name prefixed with `Base`, e.g. components/Base/Spinner.vue will
 * be made globally available as BaseSpinner.
 */
import Vue from 'vue';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

const getComponentName = fileName =>
  upperFirst(camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')));

const context = require.context('../components/Base', true);
context.keys().forEach(fileName => {
  Vue.component(
    `Base${getComponentName(fileName)}`,
    context(fileName).default || context(fileName)
  );
});
