<template>
  <VCard class="text-xs-center no-print">
    <VCardTitle class="green accent-1 black--text">
      <VFlex xs12>
        <h3 class="job-identifier">{{ job.identifier || job.JOB_NAME }}</h3>
        <h2>{{ cardTitle }}</h2>
        <transition name="slide-left" mode="out-in">
          <BaseButton
            :key="buttonTitle"
            color="primary"
            small
            class="asset-type-button"
            md-icon="navigate_next"
            outline
            @click="switchAssetType"
            >{{ buttonTitle }}</BaseButton
          >
        </transition>
      </VFlex>
    </VCardTitle>
    <VCardText class="font-weight-medium">
      <VContainer fluid pa-0>
        <VLayout row fill-height align-center wrap>
          <VFlex xs12 md4 grow>
            <JobAssetManagerGlobalList
              :loading="loading"
              :asset-type="assetType"
            />
          </VFlex>
          <VFlex shrink class="mx-auto">
            <BaseButton
              color="primary"
              :disabled="loading || !selectedSourceAssets.length"
              :loading="isSavingJobAssets"
              class="add-asset-btn asset-manager-btn mb-2"
              :class="{ 'd-block condensed': showCondensedButton }"
              :block="!showCondensedButton"
              :md-icon="showCondensedButton ? 'add' : ''"
              :fab="showCondensedButton"
              title="Add Asset"
              @click="addJobAssets({ assetType })"
              >{{ showCondensedButton ? '' : 'Add' }}</BaseButton
            >
            <BaseButton
              color="primary"
              :disabled="loading || !selectedLocalAssets.length"
              :loading="isSavingJobAssets"
              class="remove-asset-btn asset-manager-btn"
              :class="{ 'd-block condensed': showCondensedButton }"
              :block="!showCondensedButton"
              :md-icon="showCondensedButton ? 'remove' : ''"
              :fab="showCondensedButton"
              title="Remove Asset"
              @click="removeJobAssets({ assetType })"
              >{{ showCondensedButton ? '' : 'Remove' }}</BaseButton
            >
          </VFlex>
          <VFlex xs12 md7 grow>
            <JobAssetManagerLocalList
              :loading="loading"
              :asset-type="assetType"
              :job-id="job.ID"
            />
          </VFlex>
        </VLayout>
      </VContainer>
    </VCardText>
    <VCardActions text-xs-right>
      <VSpacer />
      <slot name="actions" />
    </VCardActions>
  </VCard>
</template>

<script>
import JobAssetManagerGlobalList from '@components/DailyReportView/JobAssetManagerGlobalList'
import JobAssetManagerLocalList from '@components/DailyReportView/JobAssetManagerLocalList'
import { VCardText, VCardActions } from '@vuetify/VCard'
import { AssetTypes, HumanReadableAssetTypes } from '@constants/knack'
import { capitalCase } from 'change-case'
import { mapActions } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getJobAssetsField',
  mutationType: 'updateJobAssetsField',
})
export default {
  name: 'JobAssetManager',
  components: {
    JobAssetManagerGlobalList,
    JobAssetManagerLocalList,
    VCardText,
    VCardActions,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    job: {
      type: Object,
      required: true,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      assetType: AssetTypes.PHASES,
    }
  },
  computed: {
    ...mapFields([
      'selectedSourceAssets',
      'selectedLocalAssets',
      'isSavingJobAssets',
    ]),
    showCondensedButton() {
      return ['ml', 'lg'].includes(this.$mq)
    },
    isManagingCostCodes() {
      return this.assetType === AssetTypes.COST_CODES
    },
    cardTitle() {
      return capitalCase(
        this.isManagingCostCodes
          ? HumanReadableAssetTypes.COST_CODES
          : HumanReadableAssetTypes.PHASES
      )
    },
    buttonTitle() {
      return capitalCase(
        this.isManagingCostCodes
          ? HumanReadableAssetTypes.PHASES
          : HumanReadableAssetTypes.COST_CODES
      )
    },
  }, // computed
  async mounted() {
    this.selectedSourceAssets = []
    this.selectedLocalAssets = []
  },
  methods: {
    ...mapActions([
      'addJobAssets',
      'removeJobAssets',
      'fetchCostCodes',
      'fetchJobCostCodes',
      'fetchPhases',
      'fetchJobPhases',
    ]),
    switchAssetType() {
      this.assetType = this.isManagingCostCodes
        ? AssetTypes.PHASES
        : AssetTypes.COST_CODES
    },
  },
}
</script>

<style lang="scss">
.asset-manager-btn.condensed.btn-base.v-btn {
  width: 34px;
  min-width: 0 !important;
  height: 34px;
  .v-icon--right {
    margin: 0;
  }
}
</style>

<style lang="scss" scoped>
.asset-type-button {
  width: 150px;
  &,
  &:hover,
  &:focus {
    position: absolute;
  }
  right: 10px;
  top: 10px;
}
.job-identifier {
  left: 20px;
  top: 20px;
  position: absolute;
}

.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}
.slide-left-enter-from {
  opacity: 0;
  transform: translateX(10px);
}

.slide-left-leave-from,
.slide-left-enter-to {
  opacity: 1;
}

.slide-left-leave-active,
.slide-left-enter-active {
  transition: all 0.12s;
}
</style>
