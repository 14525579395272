<template>
  <div class="input-field">
    <input :id="pickerName" v-model="fieldValue" class="datepicker" />
    <label :class="[{ active: fieldValue.length }]">{{ title }}</label>
  </div>
</template>

<script>
export default {
  name: 'DatepickerGeneric',
  components: {},
  props: {
    title: { type: String, default: '' },
    pickerName: { type: String, default: '' },
    value: { type: String, default: '' },
  },
  data() {
    return {
      fieldValue: this.value,
    }
  },
  mounted() {
    var self = this
    let $ = window.$
    $('#' + this.pickerName).pickadate({
      format: 'mm/dd/yyyy',
      selectMonths: true, // Creates a dropdown to control month
      selectYears: 15, // Creates a dropdown of 15 years to control year,
      today: 'Today',
      clear: 'Clear',
      close: 'Ok',
      closeOnSelect: true, // Close upon selecting a date,
      onSet: function(context) {
        self.fieldValue = this.$node[0].value
        self.$emit('update', self.fieldValue)
      },
    })
  },
  methods: {},
}
</script>
