import {
  ObjectTypes,
  AssignmentTables,
  HumanFields,
  AssetObjectNames,
  AssetTypes,
} from '../constants/knack'

import lambdaApi from '@services/lambdaApi'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _concat from 'lodash/concat'

// general purpose lambda call
const lambda = async function(
  lambdaFn,
  query,
  cb = null,
  errorCb = null,
  retryCount = 0
) {
  if (retryCount) {
    // console.log('Retrying', { lambdaFn, query })
  }
  try {
    // console.log('trying req', { lambdaFn, query })
    let requestData = JSON.stringify(query)
    const response = await lambdaApi.post(lambdaFn, requestData)

    const data = await response.data

    if (retryCount) {
      // console.log('Successful response on Retry', response)
    }
    // console.log('knack response delivered by lambda was: ', response.status)
    return cb ? cb(data) : data
  } catch (lambdaError) {
    if (retryCount) {
      // console.log('Retrying FAIL', String(lambdaError))
    }

    const lambdaErrorStatus = _get(lambdaError, 'response.status', null)

    // Knaxios timeout
    if (lambdaErrorStatus === 408) {
      // TODO (ES) : Maybe trigger a retry and/or fire a request to see if
      // the previous one actually worked in Knack but timed out
    }
    if (lambdaErrorStatus === 429) {
      // console.log('Exceed Knack Request limit', lambdaError)
      // console.log('Retrying', { lambdaFn, query })
      let nextAttempt = retry(lambdaFn, query, cb, errorCb, ++retryCount)
      return nextAttempt
    }
    return errorCb ? errorCb(lambdaError) : Promise.reject(lambdaError)
  }
}

// helper function for 429 related retries only
async function retry(lambdaFn, query, cb = null, errorCb = null, count) {
  let maxRetries = 4
  if (count < maxRetries) {
    let retriedResult
    try {
      retriedResult = await setTimeout(
        lambda(lambdaFn, query, cb, errorCb, ++count),
        100
      )
      return retriedResult
    } catch (retryError) {
      let statusCode = _get(retryError, 'response.status', 500)
      return {
        statusCode,
        body: retryError,
      }
    }
  } else {
    return {
      statusCode: 500,
      body: JSON.stringify({
        message: `Failed after Maximum number of retries (${maxRetries}).`,
        query,
      }),
    }
  }
}

// wrapper functions for each type of request
function get(query, cb, errorCb) {
  /**
   * If this is a single record request, the query
   * will have a single set of options and ID
   */

  const lambdaFn =
    query.options && query.options.ID ? 'getSingleRecord' : 'getRecords'
  return lambda(lambdaFn, query, cb, errorCb)
}

function put(query, cb, errorCb) {
  const lambdaFn = 'putSingleRecord'
  return lambda(lambdaFn, query, cb, errorCb)
}

function post(query, cb, errorCb) {
  const lambdaFn = 'postSingleRecord'
  return lambda(lambdaFn, query, cb, errorCb)
}

function del(query, cb, errorCb) {
  const lambdaFn = 'deleteSingleRecord'
  return lambda(lambdaFn, query, cb, errorCb)
}

const getRecords = async (type, query, cb, errorCb) => {
  query = { ...query, type }
  return get(query, cb, errorCb)
}

const getSingle = async (type, ID, cb, errorCb, batchUpdate = 0) => {
  let query = {
    options: { ID },
    type,
  }
  return get(query, cb, errorCb)
}

const createObject = async (type, obj, cb, errorCb, batchUpdate = false) => {
  let query = {
    options: {},
    body: obj,
    type,
  }
  return post(query, cb, errorCb)
}

const updateObject = async (
  type,
  ID,
  updates,
  cb,
  errorCb,
  batchUpdate = false
) => {
  let query = {
    options: { ID },
    body: updates,
    type,
  }
  return put(query, cb, errorCb)
}

const deleteObject = async (type, ID, cb, errorCb, batchUpdate = false) => {
  let query = {
    options: { ID },
    type,
  }
  return del(query, cb, errorCb)
}

const getObjectSchemaFields = async (objectId, cb, errorCb) => {
  const lambdaFn = 'getObjectSchemaFields'
  return lambda(lambdaFn, { objectId }, cb, errorCb)
}

// authentication wrapper
// When any of these exported functions is called, the "true" function
// is being passed as a "callback" that's executed on validation success
export default {
  /// ///////////////////////
  //  "CREATE" UTILS
  /// ///////////////////////

  post: (query, token, batchUpdate = false) => post(query),

  createReport: (report, cb, errorCb, batchUpdate = false) =>
    createObject(ObjectTypes.DAILY_REPORT, report, cb, errorCb, batchUpdate),

  createAssignment: (assetType, assignment, cb, errorCb, batchUpdate = false) =>
    createObject(
      AssignmentTables[assetType],
      assignment,
      cb,
      errorCb,
      batchUpdate
    ),

  createShiftItem: (shiftItem, cb, errorCb, batchUpdate = false) =>
    createObject(ObjectTypes.SHIFT_ITEMS, shiftItem, cb, errorCb, batchUpdate),

  createJobCostCode: async (body, cb, errorCb) => {
    let query = { options: {}, body }
    return lambda('create-job-cost-code', query, cb, errorCb)
  },

  createJobPhase: async (body, cb, errorCb) => {
    let query = { options: {}, body }
    return lambda('create-job-phase', query, cb, errorCb)
  },

  /// ///////////////////////
  //  "READ" UTILS
  /// ///////////////////////

  get: (query, token, batchUpdate = false, cb, errorCb) =>
    get(query, cb, errorCb),

  getRecord: (type, ID, cb, errorCb, batchUpdate = false) =>
    getSingle(type, ID, cb, errorCb, batchUpdate),

  /**
   * Singles
   */

  getAssetAssignment: (assetType, ID, cb, errorCb, batchUpdate = false) => {
    let type = AssignmentTables[assetType]
    return getSingle(type, ID, cb, errorCb, (batchUpdate = 0))
  },

  getReport: (ID, cb, errorCb, batchUpdate = false) =>
    getSingle(ObjectTypes.DAILY_REPORT, ID, cb, errorCb, (batchUpdate = 0)), // getReport
  /**
   * Collections
   */

  getReports: (query, cb, errorCb) => lambda('getReports', query, cb, errorCb),

  getJobs: (query, cb, errorCb) =>
    getRecords(ObjectTypes.JOBS, query, cb, errorCb),

  getLocations: (query, cb, errorCb) =>
    getRecords(ObjectTypes.LOCATIONS, query, cb, errorCb),

  getPhases: (query, cb, errorCb) =>
    getRecords(ObjectTypes.PHASES, query, cb, errorCb),

  getCostCodes: (query, cb, errorCb) =>
    getRecords(ObjectTypes.COST_CODES, query, cb, errorCb),

  getJobCostCodes: (jobId, query = {}, cb, errorCb) => {
    let q = { ...query }
    _set(q, 'options.rows_per_page', 1000)
    _set(q, 'options.filters.match', 'and')
    let jobIdRule = [
      {
        field: HumanFields.JOB_COST_CODES.JOB,
        operator: 'is',
        value: jobId,
      },
    ]
    _set(
      q,
      'options.filters.rules',
      _concat(_get(q, 'options.filters.rules', []), jobIdRule)
    )
    _set(q, 'type', AssetTypes.JOB_COST_CODES)

    return lambda('job-cost-codes', q, cb, errorCb)
  },

  getJobPhases: (jobId, query = {}, cb, errorCb) => {
    let q = { ...query }

    _set(q, 'options.rows_per_page', 1000)
    _set(q, 'options.filters.match', 'and')
    let jobIdRule = [
      {
        field: HumanFields.JOB_PHASES.JOB,
        operator: 'is',
        value: jobId,
      },
    ]
    _set(
      q,
      'options.filters.rules',
      _concat(_get(q, 'options.filters.rules', []), jobIdRule)
    )
    _set(q, 'type', AssetTypes.JOB_PHASES)

    return lambda('job-phases', q, cb, errorCb)
  },

  getCollection: (type, query, cb, errorCb) =>
    getRecords(type, query, cb, errorCb),

  getAssignedAssets: (assetType, query, cb, errorCb) =>
    getRecords(AssignmentTables[assetType], query, cb, errorCb),

  getDefaultAssets: (assetType, locationJobType, excludeRules, cb, errorCb) => {
    let assetObjName = AssetObjectNames[assetType]
    let field = _get(HumanFields, `${assetObjName}.INCLUDE_IN_JOB_TYPE_DEFAULT`)
    if (!locationJobType || !field) {
      return false
    }
    let rules = [
      ...excludeRules,
      {
        field,
        operator: 'contains',
        value: locationJobType,
      },
    ]

    let query = {
      options: {
        rows_per_page: 1000,
        filters: {
          match: 'and',
          rules,
        },
      },
    }

    return getRecords(assetObjName, query, cb, errorCb)
  },

  getObjectSchemaFields: (objectId, cb, errorCb) =>
    getObjectSchemaFields(objectId, cb, errorCb),

  /// ///////////////////////
  //  "UPDATE" UTILS
  /// ///////////////////////

  put: (query, token, batchUpdate = false) => put(query),

  updateReport: (ID, updates, cb, errorCb, batchUpdate = false) =>
    updateObject(
      ObjectTypes.DAILY_REPORT,
      ID,
      updates,
      cb,
      errorCb,
      batchUpdate
    ),

  updateAssignment: (
    assetType,
    assignmentID,
    updates,
    cb,
    errorCb,
    batchUpdate = false
  ) =>
    updateObject(
      AssignmentTables[assetType],
      assignmentID,
      updates,
      cb,
      errorCb,
      batchUpdate
    ),

  updateShiftItem: (shiftItemId, updates, cb, errorCb, batchUpdate = false) =>
    updateObject(
      ObjectTypes.SHIFT_ITEMS,
      shiftItemId,
      updates,
      cb,
      errorCb,
      batchUpdate
    ),
  updateLocation: (ID, updates, cb, errorCb, batchUpdate = false) =>
    updateObject(ObjectTypes.LOCATIONS, ID, updates, cb, errorCb, batchUpdate),

  updateJob: (ID, updates, cb, errorCb, batchUpdate = false) =>
    updateObject(ObjectTypes.JOBS, ID, updates, cb, errorCb, batchUpdate),

  updateJobPhase: (ID, updates, cb, errorCb, batchUpdate = false) =>
    updateObject(ObjectTypes.JOB_PHASES, ID, updates, cb, errorCb, batchUpdate),

  updateJobCostCode: (ID, updates, cb, errorCb, batchUpdate = false) =>
    updateObject(
      ObjectTypes.JOB_COST_CODES,
      ID,
      updates,
      cb,
      errorCb,
      batchUpdate
    ),

  /// ///////////////////////
  //  "DELETE" UTILS
  /// ///////////////////////

  del: (query, batchUpdate = false) => del(query),

  deleteReport: (ID, cb, errorCb, batchUpdate = false) =>
    deleteObject(ObjectTypes.DAILY_REPORT, ID, cb, errorCb, batchUpdate),

  deleteAssignment: (assetType, ID, cb, errorCb, batchUpdate = false) => {
    // let res = new Promise((resolve) =>
    //   setTimeout(resolve({ delete: true }), 30000)
    // )
    // return res
    return deleteObject(
      AssignmentTables[assetType],
      ID,
      cb,
      errorCb,
      batchUpdate
    )
  },

  deleteLocation: (ID, cb, errorCb, batchUpdate = false) =>
    deleteObject(ObjectTypes.LOCATIONS, ID, cb, errorCb, batchUpdate),

  deleteShiftItem: (ID, cb, errorCb, batchUpdate = false) =>
    deleteObject(ObjectTypes.SHIFT_ITEMS, ID, cb, errorCb, batchUpdate),

  deleteJobCostCode: async (ID, cb, errorCb, batchUpdate = false) =>
    deleteObject(ObjectTypes.JOB_COST_CODES, ID, cb, errorCb, batchUpdate),

  deleteJobPhase: async (ID, cb, errorCb, batchUpdate = false) =>
    deleteObject(ObjectTypes.JOB_PHASES, ID, cb, errorCb, batchUpdate),
}
