<template>
  <VCard class="job-asset-manager-table--wrap px-3 py-0" elevation="0">
    <VCardTitle class="pa-1">
      {{ title }}
      <VSpacer></VSpacer>
      <BaseInput
        v-model="search"
        append-icon="search"
        placeholder="Search"
        hide-details
        class="pt-0 mt-0"
        color="primary"
        clearable
      ></BaseInput>
    </VCardTitle>

    <VDataTable
      :value="selected"
      :items="items"
      :loading="loading"
      :headers="headers"
      :pagination.sync="pagination"
      :search="search"
      item-key="ID"
      select-all
      dense
      class="job-asset-manager-table"
      @input="$emit('select', $event)"
    >
      <template v-slot:items="props">
        <td>
          <VCheckbox v-model="props.selected" primary hide-details></VCheckbox>
        </td>
        <td class="text-xs-left">{{ getAssetName(props.item) }}</td>
        <td class="text-xs-left">{{ props.item.DESCRIPTION }}</td>
      </template>
      <template v-slot:no-results>
        No search results for "{{ search }}".
      </template>
    </VDataTable>
  </VCard>
</template>

<script>
import { VCheckbox } from 'vuetify/lib'
import { VDataTable } from '@vuetify/VDataTable'
import { AssetTypes } from '@constants/knack'
export default {
  name: 'JobAssetManagerTable',
  components: {
    VDataTable,
    VCheckbox,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'List',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    assetType: {
      type: String,
      default: AssetTypes.COST_CODES,
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: '',
      pagination: { rowsPerPage: -1 },
    }
  },
  methods: {
    getAssetName(item) {
      let key =
        this.assetType === AssetTypes.COST_CODES
          ? 'COST_CODE_NUMBER'
          : 'PHASE_NUMBER'
      return item[key]
    },
  },
}
</script>

<style lang="scss">
.job-asset-manager-table {
  max-height: 400px;
  height: 400px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;

  .v-table__overflow {
    overflow: unset;
    table {
      tbody,
      thead {
        tr {
          td,
          th {
            padding: 0 12px;
          }
          &,
          td,
          th {
            height: 35px;
          }
        }
      }
      thead {
        position: sticky;
        left: 0px;
        top: 0;
        background: white;
        z-index: 4;
        th {
          font-weight: 900;
        }
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0px;
          width: 100%;
          height: 1px;
          background: #e0e0e0;
        }
      }
    }
  }
}
</style>
