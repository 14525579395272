<template>
  <div class="job-asset-manager-source-list job-asset-manager-list--wrap">
    <WithAllowedJobAssets
      v-if="computedJobId"
      :asset-type="assetType"
      :job-id="computedJobId"
    >
      <div slot-scope="{ areAssetsLoading, assets }">
        <JobAssetManagerLocalTable
          :loading="areAssetsLoading || loading"
          title="Allowed on Current Job"
          :headers="headers"
          :items="assets"
          :selected="selectedLocalAssets"
          :asset-type="assetType"
          @select="selectedLocalAssets = $event"
        />
      </div>
    </WithAllowedJobAssets>
    <div v-else>
      <h3>We couldn't find a Job Id for the current view.</h3>
    </div>
  </div>
</template>

<script>
import WithAllowedJobAssets from '@dataProviders/WithAllowedJobAssets'
import JobAssetManagerLocalTable from '@components/DailyReportView/JobAssetManagerLocalTable'

import { mapGetters } from 'vuex'
import { AssetTypes } from '@constants/knack'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getJobAssetsField',
  mutationType: 'updateJobAssetsField',
})
export default {
  name: 'JobAssetManagerLocalList',
  components: { WithAllowedJobAssets, JobAssetManagerLocalTable },
  props: {
    assetType: {
      type: String,
      required: true,
      default: AssetTypes.COST_CODES,
    },
    loading: {
      type: Boolean,
      required: true,
      default: true,
    },
    jobId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getLocationJobId']),
    ...mapFields(['selectedLocalAssets']),
    isViewingCostCodes() {
      return this.assetType === AssetTypes.COST_CODES
    },
    computedJobId() {
      return this.jobId ?? this.getLocationJobId
    },
    headers() {
      let headers = [
        {
          text: 'Name',
          value: this.isViewingCostCodes ? 'COST_CODE' : 'PHASE',
        },
        {
          text: 'Description',
          value: 'DESCRIPTION',
          hasLocalField: true,
        },
      ]
      if (this.isViewingCostCodes) {
        headers = [
          ...headers,
          {
            text: 'Price',
            value: 'UNIT_PRICE',
            type: 'number',
          },
          {
            text: 'Contract Qty',
            value: 'CONTRACT_QUANTITY',
            type: 'number',
          },
          {
            text: 'Unt. of Msrmt',
            value: 'UNIT_OF_MEASUREMENT',
            hasLocalField: true,
          },
          {
            text: 'Hide on Picklist',
            value: 'HIDE_FROM_WORK_ITEM_PICKLIST',
          },
        ]
      }
      return headers
    },
  },
}
</script>

<style lang="scss">
.job-asset-manager-list {
  max-height: 400px;
  height: 400px;
  overflow-y: auto;
}
</style>
