<template>
  <div class="records-list">
    <div v-if="records" pa-0>
      <slot>
        <VCard
          v-for="(record, i) in records"
          :key="record.ID"
          class="record-item pa-2 px-4"
          :to="`/${assetType}/${record.ID}`"
        >
          <VLayout align-center fill-height>
            <VFlex xs8 sm6 grow>
              <RouterLink class="left-side" :to="`/${assetType}/${record.ID}`">
                <small class="item-index">{{ i + currentBaseIndex }}. </small
                >{{ record[nameKey] }}</RouterLink
              >
            </VFlex>
            <VFlex xs4 sm6 text-xs-right>
              <BaseButton
                flat
                color="grey darken-1"
                class="view-btn"
                :to="`/${assetType}/${record.ID}`"
                :md-icon="isMediumOrBigger ? 'visibility' : false"
                :small="!isMediumOrBigger"
                >View</BaseButton
              >
            </VFlex>
          </VLayout>
        </VCard>
      </slot>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'RecordsList',
  props: {
    records: {
      type: Array,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    nameKey: {
      type: String,
      required: true,
      default: undefined,
    },
    assetType: {
      type: String,
      required: true,
      default: undefined,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...routeState(['query']),
    currentBaseIndex() {
      let pageNum = this.query.page ? this.query.page : 1
      return (pageNum - 1) * this.itemsPerPage + 1
    }, // currentBaseIndex
    isMediumOrBigger() {
      return !['sm', 'xs'].includes(this.$mq)
    }, // isMediumOrBigger
  },
}
</script>

<style lang="scss" scoped>
.records-list {
  list-style: none;
}
.record-item {
  width: 95%;
  max-width: 760px;
  height: 60px;
  padding: 12px 20px;
  margin: 0 auto 7px;
  font-weight: 500;
  text-align: left;
  a:link,
  a:visited {
    color: $blue;
  }
  &:hover {
    background-color: lighten($blue, 42);
  }
}
.left-side {
  font-size: 1.2rem;
}
.item-index {
  margin-right: 7px;
  color: $gray-body;
}

.search-field,
.search-submit {
  display: inline-block;
}

.input-wrap {
  align-items: center;
  padding: 5px;
  color: white;
  background-color: #565656;
  .search-field {
    margin-bottom: 0;
    &.error {
      border-bottom-color: red;
    }
  }
  .clear-btn {
    padding: 0 0.5em;
    font-size: 0.85rem;
    .material-icons {
      margin-right: 3px;
    }
  }
}

.submit-wrap {
  position: relative;
  align-items: center;
  justify-content: center;
}

.search-spinner-wrap {
  .spinner--wrap {
    margin: 0 !important;
  }
}
</style>
