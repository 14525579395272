import Vue from 'vue'
import pAll from 'p-all'
import _get from 'lodash/get'
import _omitBy from 'lodash/omitBy'
import nanoid from 'nanoid'
import api from '@services/api'
import dayjs from 'dayjs'

import { AssignmentTables, HumanFields, ContentTypes } from '@constants/knack'
import dayNames from '@constants/dayNames'
import { DISABLE_REPORT_REVISIONS } from '@constants/appConfig'
import {
  SET_ASSETS_RESPONSE,
  SET_ASSETS,
  MODIFY_ASSET,
  REPLACE_ASSET,
  ADD_NEW_ASSET,
  REMOVE_NEW_ASSET,
  SET_OBJECT_SCHEMA_FIELDS,
} from '@constants/mutations'

import { createHelpers } from 'vuex-map-fields'
const { getReportAssetsField, updateReportAssetsField } = createHelpers({
  getterType: 'getReportAssetsField',
  mutationType: 'updateReportAssetsField',
})

export default {
  state: {
    assets: {},
    assetResponses: {},
    modifiedAssets: [],
    schemas: {
      [AssignmentTables.laborer]: [],
    },
  },
  mutations: {
    updateReportAssetsField,
    [SET_ASSETS_RESPONSE](state, { assignmentTable, response }) {
      state.assetResponses[assignmentTable] = response.records
    },
    [SET_ASSETS](state, { assignmentTable, records }) {
      state.assets[assignmentTable] = records
    },
    [MODIFY_ASSET](state, { assignmentTable, recordId, payload }) {
      let responses = { ...state.assetResponses }
      let updatedRecords = responses[assignmentTable]
      let record = updatedRecords.find((a) => a.ID === recordId)
      let index = updatedRecords.indexOf(record)
      let modifiedRecord = { ...record, ...payload }
      updatedRecords.splice(index, 1, modifiedRecord)
      Vue.set(state, 'assetResponses', responses)
    },

    [REPLACE_ASSET](state, { assignmentTable, recordId, payload }) {
      let responses = { ...state.assetResponses }
      let updatedRecords = responses[assignmentTable]
      let record = updatedRecords.find((a) => a.ID === recordId)
      let index = updatedRecords.indexOf(record)
      let modifiedRecord = { ...payload }
      updatedRecords.splice(index, 1, modifiedRecord)
      Vue.set(state, 'assetResponses', responses)
    },

    [ADD_NEW_ASSET](state, { assignmentTable, asset }) {
      let responses = { ...state.assetResponses }
      let assgnts = responses[assignmentTable]
      assgnts.push({ ...asset, new: true })
      Vue.set(state, 'assetResponses', responses)
    }, // ADD_NEW_ASSET

    [REMOVE_NEW_ASSET](state, { assignmentTable, assetId }) {
      if (!assetId) {
        return false
      }
      let responses = { ...state.assetResponses }
      let updatedRecords = responses[assignmentTable]
      let record = updatedRecords.find((a) => a.ID === assetId)
      let index = updatedRecords.indexOf(record)
      updatedRecords.splice(index, 1)
      responses[assignmentTable] = updatedRecords
      Vue.set(state, 'assetResponses', responses)
    }, // REMOVE_NEW_ASSET

    [SET_OBJECT_SCHEMA_FIELDS](state, { assignmentTable, fields }) {
      state.schemas[assignmentTable] = fields
    }, // SET_OBJECT_SCHEMA_FIELDS
  },
  actions: {
    // fetch assets for report
    async fetchReportAssets({ dispatch, getters, commit }, options) {
      try {
        let { assetType, reportId } = options
        let assignmentTable = AssignmentTables[assetType]
        options = {
          ...options,
          assetType: assignmentTable,
          filters: {
            match: 'and',
            rules: [
              {
                field: HumanFields[assignmentTable].DAILY_REPORT,
                operator: 'is',
                value: reportId,
              },
            ],
          },
          rows_per_page: 1000,
        }

        let assets = await api.getCollection(
          assignmentTable,
          { options },
          (response) => {
            commit(SET_ASSETS_RESPONSE, { assignmentTable, response })
            commit(SET_ASSETS, {
              assignmentTable,
              records: response.records,
            })
            return response.records
          },
          (err) => {
            throw err
          }
        )
        return assets
      } catch (err) {
        throw new Error(err)
      }
    }, // fetchReportAssets

    // remove assets from a report
    async deleteReportAssets({ dispatch, getters, commit }, options) {
      try {
        let { assetType, assets } = options

        let actions = assets.map((asset, counter) => {
          // make sure we return a function here
          return () =>
            api.del(
              {
                options: { ID: asset.ID },
                type: AssignmentTables[assetType],
              },
              counter
            )
        })
        let responses = await pAll(actions, { concurrency: 3 })
        return responses.map((r, counter) => {
          return { response: r, asset: assets[counter] }
        })
      } catch (err) {
        throw new Error(err)
      }
    }, // deleteReportAssets

    // assign assets to a report
    async batchAssignAssets({ getters }, options) {
      try {
        let {
          assignmentType,
          assets,
          reportId,
          reportConnectionKey,
          assetConnectionKey,
          assetIdKey,
          cloneableFields,
        } = options

        let assgntCreationPromises = assets.map((assignment, counter) => {
          let assetId = _get(assignment, assetIdKey, false)
          // make sure we return a function here
          if (!assetId) {
            return () =>
              new Error(
                JSON.stringify({ msg: 'Asset ID not found', assignment })
              )
          }
          let cloneableFieldData = {}
          cloneableFields.map((field) => {
            let fieldVal = _get(assignment, field)
            if (fieldVal) {
              let fieldId = HumanFields[assignmentType][field]
              cloneableFieldData[fieldId] = fieldVal
            }
          })

          let postData = {
            options: {},
            type: assignmentType,
            body: {
              ...cloneableFieldData,
              [reportConnectionKey]: reportId,
              [assetConnectionKey]: assetId,
            },
          }

          // If it has one, use the TABLE_ORDER_NUMBER that's
          // attached to this assgnt so the order is retained
          if (typeof assignment.TABLE_ORDER_NUMBER !== 'undefined') {
            let tableOrderKey = HumanFields[assignmentType].TABLE_ORDER_NUMBER
            postData.body[tableOrderKey] = assignment.TABLE_ORDER_NUMBER
          }

          return () =>
            api
              .post(postData, getters.accessToken, counter)
              .catch((e) => new Error(e))
        })
        let responses = await pAll(assgntCreationPromises, { concurrency: 3 })
        return responses
      } catch (err) {
        throw new Error(err)
      }
    }, // batchAssignAssets

    async batchFetchAsset({ dispatch, getters, commit }, options) {
      try {
        let { assetType, assets, cb } = options

        let actions = assets.map((asset, counter) => {
          // make sure we return a function here
          let ID = asset.ID
          return () =>
            api.getRecord(
              assetType,
              ID,
              cb,
              (err) => {
                console.warn('error', err)
                throw err
              },
              counter > 0
            )
          // .catch((e) => console.warn)
        })
        let responses = await pAll(actions, { concurrency: 6 })
        return responses
      } catch (err) {
        throw new Error(err)
      }
    }, // batchFetchAsset

    setAssetIsWorking({ dispatch }, { assetType, recordId }) {
      dispatch('modifyAsset', {
        assetType,
        recordId,
        payload: { isWorking: true },
      })
    }, // setAssetIsWorking

    setAssetNotWorking({ dispatch }, { assetType, recordId }) {
      dispatch('modifyAsset', {
        assetType,
        recordId,
        payload: { isWorking: false },
      })
    }, // setAssetNotWorking

    modifyAsset({ commit }, { assetType, recordId, payload }) {
      let assignmentTable = AssignmentTables[assetType]
      commit(MODIFY_ASSET, { assignmentTable, recordId, payload })
    }, // modifyAsset

    async replaceAsset({ commit }, { assetType, recordId, payload }) {
      let assignmentTable = AssignmentTables[assetType]
      commit(REPLACE_ASSET, { assignmentTable, recordId, payload })
    }, // replaceAsset

    async addAsset({ dispatch }, { asset, assetType, assgntNameKey }) {
      let newAssgnt = await api.createAssignment(assetType, asset)
      let assetName = newAssgnt[assgntNameKey]
      if (newAssgnt) {
        await dispatch('makeRevisionForAssetMod', {
          assetType,
          assetId: newAssgnt.ID,
          assetName,
          action: 'added',
          updates: _omitBy(newAssgnt, (val) => !val),
        })
      }
      return newAssgnt
    }, // addAsset

    async addDraftAsset({ commit }, { assetType, reportId, asset = {} }) {
      let assignmentTable = AssignmentTables[assetType]

      let defaults = {
        ID: nanoid(),
        draft: true,
        DAILY_REPORT: [{ id: reportId }],
      }
      asset = { ...defaults, ...asset }
      commit(ADD_NEW_ASSET, {
        assignmentTable,
        asset,
      })
    }, // addDraftAsset

    async updateAsset({ dispatch }, { asset, assetType, assgntNameKey }) {
      let updatedAssgnt = await api.updateAssignment(assetType, asset.ID, asset)
      if (updatedAssgnt) {
        let assetName = updatedAssgnt[assgntNameKey]
        let updates = { ...asset }
        delete updates.ID
        await dispatch('makeRevisionForAssetMod', {
          assetType,
          assetId: updatedAssgnt.ID,
          assetName,
          updates,
          action: 'updated',
        })
        await dispatch('replaceAsset', {
          assetType,
          recordId: updatedAssgnt.ID,
          payload: updatedAssgnt,
        })
      }
      return updatedAssgnt
    }, // updateAsset

    removeNewAsset({ commit }, { assetType, assetId }) {
      let assignmentTable = AssignmentTables[assetType]
      commit(REMOVE_NEW_ASSET, {
        assignmentTable,
        assetId,
      })
    }, // removeNewAsset

    async trashAsset(
      { getters, dispatch },
      { assetType, assetId, assgntNameKey }
    ) {
      await dispatch('setAssetIsWorking', {
        assetType: assetType,
        recordId: assetId,
      })
      let assgnt = getters.getAssgntById(assetType, assetId)
      delete assgnt.isWorking
      let assetName = assgnt[assgntNameKey]
      let removedAsset = await api.deleteAssignment(assetType, assetId)
      if (removedAsset.delete) {
        dispatch('removeNewAsset', {
          assetType: assetType,
          assetId,
        })
        await dispatch('makeRevisionForAssetMod', {
          assetType,
          assetId,
          assetName,
          action: 'deleted',
          updates: _omitBy(assgnt, (val) => !val),
        })
      } else {
        this.$notify(`Couldn't remove asset. Please try again.`)
        await dispatch('setAssetIsNotWorking', {
          assetType: assetType,
          recordId: assetId,
        })
      }
    }, // trashAsset

    untrashAsset({ dispatch }, { assetType, assetId }) {
      dispatch('modifyAsset', {
        assetType: assetType,
        recordId: assetId,
        payload: { trashed: false },
      })
    }, // untrashAsset

    async fetchDefaultAssets(
      { commit },
      { assetType, locationJobType, excludeRules = [] }
    ) {
      let defaults = await api.getDefaultAssets(
        assetType,
        locationJobType,
        excludeRules
      )
      return defaults
    }, // fetchDefaultAssets

    async makeRevisionForAssetMod(
      { dispatch, rootGetters },
      { assetType, assetName, action, updates = null, reportId = false }
    ) {
      if (DISABLE_REPORT_REVISIONS) {
        return false
      }
      let report = reportId
        ? await rootGetters.getReportById(reportId)
        : await rootGetters.getTheReport
      if (!report) {
        console.warn('No report found to update revisions')
        return false
      }
      let mods = {
        assetName,
        assetType,
        action,
      }
      if (updates) {
        mods.updates = updates
      }

      let revisions = await dispatch('updateReportRevisions', {
        report,
        mods,
      })
      let updatedReport = await dispatch('saveReportRevisions', {
        reportId: report.ID,
        revisions,
      })

      await dispatch('updateReportSuccess', { updatedReport })
      return updatedReport
    }, // makeRevisionForAssetMod

    async fetchLaborAssgntSchema({ commit }) {
      try {
        const objectId = ContentTypes[AssignmentTables.laborer]
        const fields = await api.getObjectSchemaFields(objectId)
        const assignmentTable = AssignmentTables.laborer
        commit(SET_OBJECT_SCHEMA_FIELDS, { assignmentTable, fields })
        return fields
      } catch (err) {
        throw new Error(err)
      }
    }, // fetchLaborAssgntSchema
  },
  getters: {
    getReportAssetsField,
    getEquipmentForReport: (state) => (reportId) =>
      state.assetResponses[AssignmentTables.equipment].filter(
        (e) => e.DAILY_REPORT[0].id === reportId
      ), // getEquipmentForReport

    getReportAssets: (state) => ({ assetType, reportId }) => {
      let records = state.assetResponses[AssignmentTables[assetType]] || []
      return records.filter((e) => e.DAILY_REPORT[0].id === reportId)
    }, // getReportAssets

    getAssgntById: (state) => (assetType, assetId) => {
      let assgnts = state.assetResponses[AssignmentTables[assetType]] || []
      let assgnt = assgnts.find((a) => a.ID === assetId)
      return assgnt
    }, // getAssgntById

    getNewReportAssets: (state) => {
      let newAssets = []
      Object.keys(state.assetResponses).map((assgntTable) => {
        let newOfType =
          state.assetResponses[assgntTable].filter((a) => a.new) || []
        newAssets = [...newAssets, ...newOfType]
      })
      return newAssets
    }, // getNewReportAssets
    getLaborAssgntSchema: (state) => state.schemas[AssignmentTables.laborer],
    getLaborAssgntField: (state) => (fieldName) => {
      let fields = _get(state, `schemas[${AssignmentTables.laborer}]`, [])
      if (fields.length === 0) {
        return {}
      }
      let field = fields.find((field) => field.name.trim() === fieldName.trim())
      return field
    },
    getLaborerStartLocations: (state, getters) => {
      let locationStartField = getters.getLaborAssgntField('Location Start ')
      let startLocations = _get(locationStartField, 'format.options', [])
      return startLocations
    },
    getAssignmentDayOfWeek: (state, getters) => (assetType, assignmentId) => {
      let assignment = getters.getAssgntById(assetType, assignmentId)
      let reportDateDayOfWeek = dayjs(
        assignment.REPORT_DATE.date,
        'MM/DD/YYYY'
      ).format('dddd')
      return reportDateDayOfWeek
    },
    getIsSundayShift: (state, getters) => (assetType, assignmentId) => {
      return (
        getters.getAssignmentDayOfWeek(assetType, assignmentId) ===
        dayNames.SUNDAY
      )
    },
    getIsSaturdayShift: (state, getters) => (assetType, assignmentId) => {
      return (
        getters.getAssignmentDayOfWeek(assetType, assignmentId) ===
        dayNames.SATURDAY
      )
    },
  },
}
