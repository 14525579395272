/**
 * @fileoverview This lists all possible mutation names for this app.
 */

// Example Mutations
export const SET_EXAMPLE = 'SET_EXAMPLE'
export const EXAMPLE_UPDATE_TIME = 'EXAMPLE_UPDATE_TIME'

// Authentication mutations.
export const USER_LOG_IN = 'USER_LOG_IN'
export const USER_LOG_OUT = 'USER_LOG_OUT'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR'
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_SIGNIN_REDIRECT = 'SET_SIGNIN_REDIRECT'
export const SET_LOGOUT_INTERVAL_ID = 'SET_LOGOUT_INTERVAL_ID'
export const CLEAR_INTERVAL = 'CLEAR_INTERVAL'

// Nav & Drawers
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER'

// Drag & Drop
export const DRAG_START = 'DRAG_START'
export const DRAG_END = 'DRAG_END'
export const SET_THE_DRAG_OBJECT = 'SET_THE_DRAG_OBJECT'

// API Management
export const SET_API_OFFLINE = 'SET_API_OFFLINE'
export const SET_API_ONLINE = 'SET_API_ONLINE'
export const SET_KNACK_DID_TIMEOUT = 'SET_KNACK_DID_TIMEOUT'

// Reports
export const SET_REPORT = 'SET_REPORT'
export const MODIFY_REPORT = 'MODIFY_REPORT'
export const DELETE_REPORT = 'DELETE_REPORT'
export const CREATE_REPORT = 'CREATE_REPORT'
export const UPDATE_REPORT = 'UPDATE_REPORT'
export const UPDATE_SINGLE_REPORT = 'UPDATE_SINGLE_REPORT'
export const INCREMENT_UNSAVED_ASSETS = 'INCREMENT_UNSAVED_ASSETS'
export const DECREMENT_UNSAVED_ASSETS = 'DECREMENT_UNSAVED_ASSETS'

// Scheduler
export const CREATE_SCHED_REPORT = 'CREATE_SCHED_REPORT'
export const UPDATE_SCHED_REPORT = 'UPDATE_SCHED_REPORT'
export const REMOVE_SCHED_REPORT = 'REMOVE_SCHED_REPORT'
export const CREATE_SCHED_GHOST_REPORT = 'CREATE_SCHED_GHOST_REPORT'
export const REMOVE_SCHED_GHOST_REPORT = 'REMOVE_SCHED_GHOST_REPORT'
export const SCHED_REPORT_IS_PROCESSING = 'SCHED_REPORT_IS_PROCESSING'
export const SCHED_REPORT_IS_NOT_PROCESSING = 'SCHED_REPORT_IS_NOT_PROCESSING'
export const CLEAR_LOCATION_FILTERS = 'CLEAR_LOCATION_FILTERS'
export const SET_SCHEDULER_NOT_LOADING = 'SET_SCHEDULER_NOT_LOADING'

export const SET_LOCATION_HOVER = 'SET_LOCATION_HOVER'
export const UNSET_LOCATION_HOVER = 'UNSET_LOCATION_HOVER'
export const SET_REPORT_HOVER = 'SET_REPORT_HOVER'
export const UNSET_REPORT_HOVER = 'UNSET_REPORT_HOVER'
export const SET_PRINT_MODE = 'SET_PRINT_MODE'

export const SET_REPORTS = 'SET_REPORTS'
export const SET_REPORTS_RESPONSE = 'SET_REPORTS_RESPONSE'

export const RESET_DRAFT_REPORT = 'RESET_DRAFT_REPORT'
export const UPDATE_DRAFT_REPORT = 'UPDATE_DRAFT_REPORT'

// Report Asset Mutations
export const SET_ASSETS_RESPONSE = 'SET_ASSETS_RESPONSE'
export const SET_ASSETS = 'SET_ASSETS'
export const MODIFY_ASSET = 'MODIFY_ASSET'
export const REPLACE_ASSET = 'REPLACE_ASSET'
export const ADD_NEW_ASSET = 'ADD_NEW_ASSET'
export const REMOVE_NEW_ASSET = 'REMOVE_NEW_ASSET'
export const SET_OBJECT_SCHEMA_FIELDS = 'SET_OBJECT_SCHEMA_FIELDS'

// Jobs Mutations
export const SET_JOBS_RESPONSE = 'SET_JOBS_RESPONSE'
export const SET_THE_JOB = 'SET_THE_JOB'
export const SET_JOBS = 'SET_JOBS'
export const UPDATE_JOB = 'UPDATE_JOB'
export const ADD_JOB = 'ADD_JOB'

// Locations Mutations
export const SET_LOCATIONS = 'SET_LOCATIONS'
export const SET_LOCATIONS_RESPONSE = 'SET_LOCATIONS_RESPONSE'
export const SET_LOCATION = 'SET_LOCATION'
export const REMOVE_LOCATION = 'REMOVE_LOCATION'
export const UPDATE_LOCATION = 'UPDATE_LOCATION'
export const ADD_LOCATION = 'ADD_LOCATION'
export const ADD_SUPPLEMENTAL_LOCATIONS = 'ADD_SUPPLEMENTAL_LOCATIONS'

// Laborers
export const SET_LABORER = 'SET_LABORER'
export const SET_LABORERS_RESPONSE = 'SET_LABORERS_RESPONSE'
export const SET_FOREMEN_RESPONSE = 'SET_FOREMEN_RESPONSE'

// Cost Codes & Phases
export const SET_PHASES = 'SET_PHASES'
export const SET_COST_CODES = 'SET_COST_CODES'

// Shift Items
export const SET_SHIFT_ITEMS = 'SET_SHIFT_ITEMS'
export const MODIFY_SHIFT_ITEM = 'MODIFY_SHIFT_ITEM'
export const REPLACE_SHIFT_ITEM = 'REPLACE_SHIFT_ITEM'
export const ADD_NEW_SHIFT_ITEM = 'ADD_NEW_SHIFT_ITEM'
export const INSERT_SHIFT_ITEM = 'INSERT_SHIFT_ITEM'
export const REMOVE_NEW_SHIFT_ITEM = 'REMOVE_NEW_SHIFT_ITEM'
export const TOGGLE_MULTI_SPLIT_SELECTION = 'TOGGLE_MULTI_SPLIT_SELECTION'

// Progress Bar
export const SET_PROGRESS_TOTAL = 'SET_PROGRESS_TOTAL'
export const SET_PROGRESS_COUNTER = 'SET_PROGRESS_COUNTER'

// Dispatcher (High level state)
export const CLEAR_REPORT_SEARCH_TERM = 'CLEAR_REPORT_SEARCH_TERM'
export const SET_REPORT_SEARCH_TERM = 'SET_REPORT_SEARCH_TERM'
export const CLEAR_REPORT_FOREMAN_FILTER = 'CLEAR_REPORT_FOREMAN_FILTER'
export const SET_REPORT_FOREMAN_FILTER = 'SET_REPORT_FOREMAN_FILTER'
export const TOGGLE_SMS_STATUS_VISIBILITY = 'TOGGLE_SMS_STATUS_VISIBILITY'

//
// Dispatcher Assets (Equipment & Laborers)
//
export const SET_ASSIGNED_ASSETS_RESPONSE = 'SET_ASSIGNED_ASSETS_RESPONSE'
export const SET_ALL_ASSETS_RESPONSE = 'SET_ALL_ASSETS_RESPONSE'

export const SET_ALL_ASSETS_DONE_LOADING = 'SET_ALL_ASSETS_DONE_LOADING'
export const SET_ALL_ASSETS_STILL_LOADING = 'SET_ALL_ASSETS_STILL_LOADING'
export const SET_ASSIGNMENTS_DONE_LOADING = 'SET_ASSIGNMENTS_DONE_LOADING'
export const SET_ASSIGNMENTS_STILL_LOADING = 'SET_ASSIGNMENTS_STILL_LOADING'

export const SET_ALL_ASSETS = 'SET_ALL_ASSETS'
export const SET_ASSIGNED_ASSETS = 'SET_ASSIGNED_ASSETS'

export const ADD_ASSET_ASSIGNMENT = 'ADD_ASSET_ASSIGNMENT'
export const UPDATE_ASSET_ASSIGNMENT = 'UPDATE_ASSET_ASSIGNMENT'
export const REMOVE_ASSET_ASSIGNMENT = 'REMOVE_ASSET_ASSIGNMENT'

export const SELECT_ASSET = 'SELECT_ASSET'
export const DESELECT_ASSET = 'DESELECT_ASSET'
export const DESELECT_ALL_ASSETS = 'DESELECT_ALL_ASSETS'

export const SELECT_ASSGNT = 'SELECT_ASSGNT'
export const DESELECT_ASSGNT = 'DESELECT_ASSGNT'
export const DESELECT_ALL_ASSGNTS = 'DESELECT_ALL_ASSGNTS'

export const SET_IS_PROCESSING = 'ASSET_IS_PROCESSING'
export const SET_NOT_PROCESSING = 'ASSET_NOT_PROCESSING'

export const SET_ASSET_HOVER = 'SET_ASSET_HOVER'
export const UNSET_ASSET_HOVER = 'UNSET_ASSET_HOVER'

export const SET_MULTI_ASSIGN_PROPS = 'SET_MULTI_ASSIGN_PROPS'
export const RESET_MULTI_ASSIGN_PROPS = 'RESET_MULTI_ASSIGN_PROPS'

// Job Assets (Phases & Cost Codes)
export const SET_JOB_PHASES = 'SET_JOB_PHASES'
export const ADD_JOB_PHASE = 'ADD_JOB_PHASE'
export const REPLACE_JOB_PHASE = 'REPLACE_JOB_PHASE'
export const REMOVE_JOB_PHASE = 'REMOVE_JOB_PHASE'
export const SET_JOB_COST_CODES = 'SET_JOB_COST_CODES'
export const ADD_JOB_COST_CODE = 'ADD_JOB_COST_CODE'
export const REPLACE_JOB_COST_CODE = 'REPLACE_JOB_COST_CODE'
export const REMOVE_JOB_COST_CODE = 'REMOVE_JOB_COST_CODE'

//
// Print Mode
//
export const TOGGLE_PRINT_MODE = 'TOGGLE_PRINT_MODE'
export const SET_REPORT_PRINT_MODE = 'SET_REPORT_PRINT_MODE'
