import {
  SET_LOCATION_HOVER,
  UNSET_LOCATION_HOVER,
  CLEAR_LOCATION_FILTERS,
  SET_PRINT_MODE,
  SET_REPORT_HOVER,
  UNSET_REPORT_HOVER,
  SET_SCHEDULER_NOT_LOADING,
} from '@constants/mutations'
import { FiberJobTypes } from '@constants/knack'

import { matchText } from '@utils/fuzzyMatch'
import dayjs from 'dayjs'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'

import { createHelpers } from 'vuex-map-fields'
const { getSchedulerField, updateSchedulerField } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})

export default {
  state: {
    hoveringLocationId: null,
    hoveringReportLocationId: null,
    isPrintMode: false,
    isSchedulerLoading: true,
    showScheduleInThePastModal: false,
    confirmScheduleInThePast: false,
    moveToThePastPayload: null,
    isForemanColumnHidden: false,
    isLocationDrawerMini: false,
    isLocationFilterMenuOpen: false,
    searchTerm: null,
    isPreciseSearchOn: false,
    isShowingFiberOnly: false,
    selectedShiftType: null,
    selectedJobType: null,
    selectedClient: null,
    selectedClientNames: [],
    selectedJobNumber: null,
    isShowingCivilJobsOnly: false,
    isShowingFiberJobsOnly: false,
    isViewingFiberLocationPrint: false,
  },
  mutations: {
    [SET_LOCATION_HOVER](state, locationId) {
      state.hoveringLocationId = locationId
    },
    [UNSET_LOCATION_HOVER](state) {
      state.hoveringLocationId = null
    },
    [SET_REPORT_HOVER](state, reportId) {
      state.hoveringReportLocationId = reportId
    },
    [UNSET_REPORT_HOVER](state) {
      state.hoveringReportLocationId = null
    },
    [SET_PRINT_MODE](state, mode) {
      state.isPrintMode = mode
    },
    [SET_SCHEDULER_NOT_LOADING](state) {
      state.isSchedulerLoading = false
    },
    [CLEAR_LOCATION_FILTERS](state) {
      state.selectedShiftType = null
      state.selectedJobType = null
      state.selectedClient = null
      state.isShowingFiberOnly = false
      state.searchTerm = ''
    },
    updateSchedulerField,
  },
  actions: {
    setLocationHover({ commit }, locationId) {
      commit(SET_LOCATION_HOVER, locationId)
    }, // setLocationHover
    unsetLocationHover({ commit }) {
      commit(UNSET_LOCATION_HOVER)
    }, // setLocationHover
    setReportHover({ commit }, locationId) {
      commit(SET_REPORT_HOVER, locationId)
    }, // setReportHover
    unsetReportHover({ commit }) {
      commit(UNSET_REPORT_HOVER)
    }, // setReportHover
    setIsPrintMode({ commit }, val) {
      commit(SET_PRINT_MODE, val)
    },
    setScheduleAsNotLoading({ commit }) {
      commit(SET_SCHEDULER_NOT_LOADING)
    },
    clearLocationFilters({ commit }) {
      commit(CLEAR_LOCATION_FILTERS)
    }, // clearFilters
  },
  getters: {
    getHoveringLocationId: (state) => state.hoveringLocationId,
    getHoveringReportLocationId: (state) => state.hoveringReportLocationId,
    getIsPrintMode: (state) => state.isPrintMode,
    getIsSchedulerLoading: (state) => state.isSchedulerLoading,
    getLocationFiltersAreOn: (state) =>
      !!state.selectedJobType ||
      !!state.selectedShiftType ||
      !!state.selectedClient ||
      !!state.isShowingFiberOnly ||
      !!state.searchTerm,

    getSortedLocations: (state, getters) => {
      const UNIX_EPOCH = 0
      const hasNewPermit = (location) =>
        dayjs().diff(
          _get(location, 'DATE_PERMIT_APPROVED.unix_timestamp', UNIX_EPOCH),
          'day'
        ) < 2

      return _orderBy(
        getters.getFilteredLocations,
        [
          (location) => !hasNewPermit(location), // false comes before true
          (location) =>
            _get(location, 'DATE_PERMIT_APPROVED.unix_timestamp', UNIX_EPOCH),
        ],
        ['asc', 'desc']
      )
    },

    getFilteredLocations: (state, getters, rootState) => {
      const locations = rootState.locations.locations
      const activeFilters = getters.getActiveLocationFilters

      return activeFilters.reduce(
        (locs, filterFn) => locs.filter(filterFn),
        locations
      )
    },

    getActiveLocationFilters: (state, getters) => {
      const activeFilters = []

      // Filter out any supplemental records used for cal display purposes only
      activeFilters.push((location) => !location.CALENDAR_ONLY)

      if (state.isViewingFiberLocationPrint) {
        activeFilters.push((loc) => {
          return FiberJobTypes.includes(loc.JOB_TYPE[0])
        })
        // Short circuit & return if we're only showing fiber jobs
        return activeFilters
      }

      if (state.isShowingFiberOnly) {
        activeFilters.push((loc) => {
          return FiberJobTypes.includes(loc.JOB_TYPE[0])
        })
      }
      if (state.selectedShiftType) {
        activeFilters.push(getters.filterLocationsByShiftType)
      }
      if (state.selectedJobType) {
        activeFilters.push(getters.filterLocationsByJobType)
      }
      if (state.selectedClient) {
        activeFilters.push(getters.filterLocationsByClient)
      }

      if (state.searchTerm) {
        const searchTermLower = state.searchTerm.toLowerCase()
        const targetKey = (loc) => `${loc.IDENTIFIER} – ${loc.JOB_NUMBER}`

        const searchFilter = state.isPreciseSearchOn
          ? (loc) =>
              targetKey(loc)
                .toLowerCase()
                .includes(searchTermLower)
          : (loc) => matchText(targetKey(loc), state.searchTerm)

        activeFilters.push(searchFilter)
      }

      return activeFilters
    },

    filterLocationsByShiftType: (state) => (loc) => {
      // If there aren't any work hours available, spoof 'M-F' so
      // those guys show up when .filter()-ing via `workHours.includes(selectedShiftType)`
      // *Note: This won't affect the visible hours on the LocationItem
      let workHours = loc.PERMIT__WORK_HOURS.length
        ? loc.PERMIT__WORK_HOURS
        : ['M-F']

      if (typeof workHours === 'undefined') {
        return true
      }
      return workHours.includes(state.selectedShiftType)
    }, // filterLocationsByShiftType

    filterLocationsByJobType: (state) => (loc) => {
      if (typeof loc.JOB_TYPE === 'undefined') {
        return true
      }

      // Job Type field will either be an array or a string
      let jobTypeMatch =
        typeof loc.JOB_TYPE === 'object'
          ? loc.JOB_TYPE.includes(state.selectedJobType)
          : loc.JOB_TYPE === state.selectedJobType
      return jobTypeMatch
    }, // filterLocationsByJobType

    filterLocationsByClient: (state) => (loc) => {
      return loc.CLIENT_NAME === state.selectedClient
    },

    getSchedulerField,
  },
}
