import dayjs from '@utils/dayjsCustomParse'

import { TOGGLE_DRAWER } from '@constants/mutations'
import { Roles } from '@constants/okta'
import { RoutePermissions } from '@constants/routePermissions'

export default {
  state: {
    navRoutes: [
      {
        navGroup: 'main',
        rolesAllowed: RoutePermissions['/invalid-reports'],
        to: '/invalid-reports',
        title: 'Invalid Reports',
        showInDropdownNav: false,
        color: 'blue',
        mdIcon: 'error_outline',
      },
      {
        navGroup: 'main',
        rolesAllowed: RoutePermissions['/locations'],
        to: '/locations',
        title: 'Locations',
        showInDropdownNav: true,
        color: 'blue',
        mdIcon: 'location_on',
      },
      {
        navGroup: 'main',
        rolesAllowed: RoutePermissions['/my-reports'],
        to: '/my-reports',
        title: 'My Reports',
        showInDropdownNav: true,
        color: 'blue',
        mdIcon: 'account_box',
      },
      {
        navGroup: 'main',
        rolesAllowed: RoutePermissions['/todays-reports'],
        to: '/todays-reports',
        title: "Today's Reports",
        showInDropdownNav: true,
        color: 'blue',
        mdIcon: 'event',
      },
      {
        navGroup: 'main',
        rolesAllowed: RoutePermissions['/reports'],
        to: '/reports',
        title: 'All Reports',
        showInDropdownNav: true,
        color: 'blue',
        mdIcon: 'view_list',
      },
      {
        navGroup: 'main',
        rolesAllowed: RoutePermissions['/dispatcher-reports'],
        to: '/dispatcher-reports',
        title: 'Dispatcher Reports',
        showInDropdownNav: true,
        color: 'blue',
        mdIcon: 'view_list',
      },
      {
        navGroup: 'main',
        rolesAllowed: RoutePermissions['/lock-reports'],
        to: '/lock-reports',
        title: 'Lock Reports',
        showInDropdownNav: false,
        color: 'orange',
        mdIcon: 'lock',
      },
      {
        navGroup: 'main',
        rolesAllowed: RoutePermissions['/locked-reports'],
        to: '/locked-reports',
        title: 'View Locked Reports',
        showInDropdownNav: false,
        color: 'teal lighten-1',
        mdIcon: 'lock',
      },
      {
        navGroup: 'main',
        rolesAllowed: RoutePermissions['/jobs'],
        to: '/jobs',
        title: 'Jobs',
        showInDropdownNav: true,
        color: 'blue',
        mdIcon: 'construction',
      },
      {
        navGroup: 'scheduler',
        rolesAllowed: RoutePermissions['/scheduler'],
        to: '/scheduler',
        title: 'Scheduler',
        showInDropdownNav: true,
        color: 'indigo darken-4',
        mdIcon: 'calendar_today',
      },

      {
        navGroup: 'dispatcher',
        rolesAllowed: RoutePermissions['/dispatcher'],
        to: '/dispatcher',
        title: 'Dispatcher',
        showInDropdownNav: true,
        color: 'indigo darken-4',
        mdIcon: 'view_quilt',
      },
      {
        navGroup: 'scheduler',
        rolesAllowed: RoutePermissions['/scheduler'],
        to: '/print-fiber-locations',
        title: 'Print Fiber Locations',
        showInDropdownNav: true,
        color: 'indigo darken-4',
        mdIcon: 'calendar_today',
      },
    ],
    primaryDrawer: false,
    availableAssets: true,
  }, // state
  mutations: {
    [TOGGLE_DRAWER](state, drawerId) {
      let targetDrawer = drawerId || 'primaryDrawer'
      state[targetDrawer] = !state[targetDrawer]
    },
  }, // mutations
  getters: {
    currentUserRoutes: (state, getters, rootState, rootGetters) => {
      return getters.userRoles.includes(Roles.ADMIN)
        ? state.navRoutes
        : state.navRoutes.filter((r) =>
            r.rolesAllowed.some((group) => getters.userRoles.includes(group))
          )
    }, // currentUserRoutes
    navTitle: (_, __, ___, rootGetters) => ($route) => {
      let title = $route.name
      if (Object.keys($route.params).includes('date')) {
        let date = $route.params.date
          ? dayjs($route.params.date, 'MM-DD-YYYY').format('MM/DD/YYYY')
          : dayjs().format('MM/DD/YYYY')
        title += ` - ${date}`
      }
      if ($route.name === 'Daily Report') {
        let report = rootGetters.getReport($route.params.reportId)
        title = report.DAILY_REPORT_NAME || 'Daily Report'
      } else if ($route.name === 'Job') {
        title = rootGetters.getJobFromId($route.params.jobId)?.JOB_NAME || 'Job'
      }
      return title
    }, // navTitle
  }, // getters
}
