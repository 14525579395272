import OktaAuth from '@okta/okta-auth-js'
import { OKTA_CLIENT_ID, OKTA_PATH } from '../constants/credentials'

const redirectUri =
  window.location.protocol + '//' + window.location.host + '/dashboard'

const oktaClient = new OktaAuth({
  url: OKTA_PATH,
  clientId: OKTA_CLIENT_ID,
  redirectUri,
  pkce: true,
})

export default oktaClient
