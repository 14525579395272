<template>
  <VCard class="job-asset-manager-table--wrap px-3 py-0" elevation="0">
    <VCardTitle class="pa-1">
      {{ title }}
      <VSpacer></VSpacer>
      <BaseInput
        v-model="search"
        append-icon="search"
        placeholder="Search"
        hide-details
        class="pt-0 mt-0"
        color="primary"
        clearable
      ></BaseInput>
    </VCardTitle>

    <VDataTable
      :value="selected"
      :items="items"
      :loading="loading"
      :headers="headers"
      :pagination.sync="pagination"
      :search="search"
      item-key="ID"
      select-all
      dense
      class="job-asset-manager-table"
      @input="$emit('select', $event)"
    >
      <template v-slot:items="props">
        <td>
          <VCheckbox v-model="props.selected" primary hide-details></VCheckbox>
        </td>
        <td class="text-xs-left">{{ getAssetName(props.item) }}</td>
        <td
          v-for="editableHeader of editableItemHeaders"
          :key="editableHeader.value"
          class="text-xs-left"
        >
          <JobAssetCheckbox
            v-if="editableHeader.value === 'HIDE_FROM_WORK_ITEM_PICKLIST'"
            :prop="editableHeader.value"
            :item="props.item"
            @update="updateAsset(props.item, editableHeader.value, $event)"
          />
          <JobAssetEditable
            v-else
            :item="props.item"
            :prop="editableHeader.value"
            :type="editableHeader.type"
            @update="
              updateAsset(
                props.item,
                editableHeader.hasLocalField
                  ? `LOCAL_${editableHeader.value}`
                  : editableHeader.value,
                $event
              )
            "
          />
        </td>
      </template>
      <template v-slot:no-results>
        No search results for "{{ search }}".
      </template>
    </VDataTable>
  </VCard>
</template>

<script>
import { VCheckbox } from 'vuetify/lib'
import { VDataTable } from '@vuetify/VDataTable'
import { AssetTypes } from '@constants/knack'
import { mapActions } from 'vuex'
import JobAssetEditable from '@dailyReport/JobAssetEditable'
import JobAssetCheckbox from '@dailyReport/JobAssetCheckbox'
import _get from 'lodash/get'
export default {
  name: 'JobAssetManagerLocalTable',
  components: {
    VDataTable,
    VCheckbox,
    JobAssetEditable,
    JobAssetCheckbox,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'List',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    assetType: {
      type: String,
      default: AssetTypes.COST_CODES,
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: '',
      pagination: { rowsPerPage: -1 },
    }
  },
  computed: {
    editableItemHeaders() {
      let editablProps = ['DESCRIPTION']
      if (this.assetType === AssetTypes.COST_CODES) {
        editablProps = [
          ...editablProps,
          'UNIT_PRICE',
          'UNIT_OF_MEASUREMENT',
          'CONTRACT_QUANTITY',
          'HIDE_FROM_WORK_ITEM_PICKLIST',
        ]
      }
      return this.headers.filter((h) => editablProps.includes(h.value))
    },
  },
  methods: {
    ...mapActions(['updateJobAsset']),
    getAssetName(item) {
      let key = this.assetType === AssetTypes.COST_CODES ? 'COST_CODE' : 'PHASE'
      return _get(item, key, '')
    },
    updateAsset(item, prop, value) {
      let jobAsset = {
        ID: item.ID,
        [prop]: value,
      }
      this.updateJobAsset({ assetType: this.assetType, jobAsset })
    },
  },
}
</script>

<style lang="scss" scoped>
.job-asset-manager-table {
  min-width: 420px;
}
</style>
