import store from '@store'

import { Raven } from 'vue-raven'
import axios from 'axios'
import CreateMock from '@tests/mocks/LambdaMockAdapter'
import { version } from '@/package.json'
const baseURL = '/.netlify/functions/'

// eslint-disable-next-line new-cap
let lambdaApi = new axios.create({
  baseURL: baseURL,
  timeout: 12000,
  headers: {
    'x-wci-app-version': version,
  },
})
lambdaApi.interceptors.response.use(
  function(response) {
    // Do something with response data
    // console.log('Intercepted knaxios response', { response })
    return response
  },
  function(error) {
    // Do something with response error
    if (process.env.NODE_ENV === 'production') {
      Raven.captureException(error)
    }
    if (
      error.response.data.indexOf('Function invocation took longer than') !== -1
    ) {
      // eslint-disable-next-line
      console.warn('Lambda timeout!')
      error.response.status = 555
      store.dispatch('setKnackDidTimeout')
    } else if (error.response.data.indexOf('timeout of') !== -1) {
      // Maybe do something different with a knaxios timeout
      // as opposed to a full on lambda timeout

      // eslint-disable-next-line
      console.warn('Knaxios timeout!')
      store.dispatch('setKnackDidTimeout')
    } else if (error.response.data.indexOf('Proxy error') !== -1) {
      // eslint-disable-next-line
      console.warn('Proxy error. Lambda offline.')
      error.response.status = 516
      store.dispatch('setApiOffline')
    }
    return Promise.reject(error)
  }
)

let USE_MOCK = true
USE_MOCK = false

if (USE_MOCK) {
  CreateMock(lambdaApi)
}

export default lambdaApi
