<template>
  <div class="job-asset-manager-source-list job-asset-manager-list--wrap">
    <WithJobAssets :asset-type="assetType">
      <div slot-scope="{ areAssetsLoading, visibleAssets }">
        <JobAssetManagerTable
          :loading="areAssetsLoading || loading"
          title="Source List"
          :headers="headers"
          :items="visibleAssets"
          :selected="selectedSourceAssets"
          :asset-type="assetType"
          @select="selectedSourceAssets = $event"
        />
      </div>
    </WithJobAssets>
  </div>
</template>

<script>
import { AssetTypes } from '@constants/knack'
import WithJobAssets from '@dataProviders/WithJobAssets'

import JobAssetManagerTable from '@components/DailyReportView/JobAssetManagerTable'

import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getJobAssetsField',
  mutationType: 'updateJobAssetsField',
})
export default {
  name: 'JobAssetManagerGlobalList',
  components: { WithJobAssets, JobAssetManagerTable },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    assetType: {
      type: String,
      required: true,
      default: AssetTypes.COST_CODES,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields(['selectedSourceAssets']),
    isViewingCostCodes() {
      return this.assetType === AssetTypes.COST_CODES
    },
    headers() {
      return [
        {
          text: 'Name',
          value: this.isViewingCostCodes ? 'COST_CODE_NUMBER' : 'PHASE_NUMBER',
        },
        {
          text: 'Description',
          value: 'DESCRIPTION',
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.job-asset-manager-list {
  max-height: 400px;
  height: 400px;
  overflow-y: auto;
}
</style>
