import api from '@services/api'
import _get from 'lodash/get'
import {
  SET_LOCATIONS_RESPONSE,
  SET_LOCATIONS,
  SET_LOCATION,
  REMOVE_LOCATION,
  ADD_LOCATION,
  UPDATE_LOCATION,
  ADD_SUPPLEMENTAL_LOCATIONS,
} from '@constants/mutations'

export default {
  state: {
    // multiple location records + pagination data
    response: {},
    // single location
    location: {},
    // All Locations
    locations: [],
  },
  mutations: {
    [SET_LOCATIONS_RESPONSE](state, payload) {
      state.response = payload
    },
    [SET_LOCATIONS](state, locations) {
      state.locations = locations
    },
    [UPDATE_LOCATION](state, newLocation) {
      let existingLocation = state.locations.find(
        (l) => l.ID === newLocation.ID
      )
      state.locations.splice(
        state.locations.indexOf(existingLocation),
        1,
        newLocation
      )
    },
    /**
     * When the standard fetchLocations call doesn't return
     * what we need from the calendar, we need to request add'l locs
     * a la cart. This mutation feathers them in for us
     */
    [ADD_SUPPLEMENTAL_LOCATIONS](state, extraLocations) {
      state.locations = [...state.locations, ...extraLocations]
    },
    [SET_LOCATION](state, payload) {
      state.location = payload
    },
    [ADD_LOCATION](state, location) {
      state.locations.push(location)
    },
    [REMOVE_LOCATION](state, location) {
      let existingLocation = state.locations.find((l) => l.ID === location.ID)
      state.locations.splice(state.locations.indexOf(existingLocation), 1)
    },
  },
  actions: {
    async fetchLocations({ state, getters, commit }, options = {}) {
      try {
        await api.getLocations(
          { options },
          (res) => {
            commit(SET_LOCATIONS_RESPONSE, res)
            if (res && res.records) {
              commit(SET_LOCATIONS, res.records)
            }
          },
          (err) => {
            // eslint-disable-next-line
            console.warn('Error : Couldnt fetch Locations', {
              err,
            })
          }
        )
      } catch (err) {
        throw new Error(err)
      }
    },
    async fetchCalendarLocations({ state, getters, commit }, options = {}) {
      try {
        options = {
          ...options,
          rows_per_page: 1000,
        }
        const calLocationsResponse = await api.getLocations(
          { options },
          (response) => {
            if (response && response.records) {
              let calendarLocations = response.records.map((r) => {
                return { ...r, CALENDAR_ONLY: true }
              })
              commit(ADD_SUPPLEMENTAL_LOCATIONS, calendarLocations)

              return calendarLocations
            }
          },
          (err) => {
            throw err
          }
        )
        return calLocationsResponse
      } catch (err) {
        throw new Error(err)
      }
    },
    async fetchLocation({ state, getters, commit }, options = {}) {
      try {
        const location = await api.get(
          { options, type: 'locations' },
          getters.accessToken
        )
        commit(SET_LOCATION, location)
        commit(ADD_LOCATION, location)

        return location
      } catch (err) {
        throw new Error(err)
      }
    }, // fetchLocation
    async updateLocation({ dispatch }, location) {
      const updatedLocationResponse = await api.updateLocation(
        location.ID,
        location,
        (updatedLocation) =>
          dispatch('updateLocationSuccess', { updatedLocation }),
        (err) => {
          throw new Error(err)
        }
      )
      return updatedLocationResponse
    }, // updateLocation

    updateLocationSuccess({ commit, getters }, { updatedLocation }) {
      commit(SET_LOCATION, updatedLocation)
      // If this loc exists in location state, update
      if (getters.getLocationFromId(updatedLocation.ID)) {
        commit(UPDATE_LOCATION, updatedLocation)
      }
      return updatedLocation
    },

    maybeRemoveCalendarLocation({ commit, rootState }, locId) {
      let reports = rootState.reports.reports
      // Only remove the location from state if
      // there's exactly one instance of it on the Calendar
      let locationMatches = reports.filter((r) => r.LOCATION[0].id === locId)
      if (!locationMatches.length) {
        commit(REMOVE_LOCATION, locId)
      }
    }, // maybeRemoveCalendarLocation

    async addSupplementalLocation({ commit }, location) {
      await commit(ADD_LOCATION, { ...location, CALENDAR_ONLY: true })
    }, // addSupplementalLocation
  },
  getters: {
    // this may be needed on each state module to get the user state's token
    // it's used with fetchLocations above
    getLocationsResponse: (state) => state.response,
    getAllLocations: (state) => state.locations || [],
    getLocation: (state) => state.location,
    getGoogleDriveLink: (state, getters) => {
      if (!getters.getLocation.GOOGLE_DRIVE) return ''

      let url = _get(getters.getLocation, 'GOOGLE_DRIVE.url', '')
      let ua = window.navigator.userAgent
      let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)

      return iOS ? url.replace('https://', 'googledrive://') : url
    }, // getGoogleDriveLink

    getLocationFromId: (state) => (locationId) =>
      state.locations.find((l) => l.ID === locationId),
    getJobType: (state) =>
      state.location.JOB_TYPE ? state.location.JOB_TYPE[0] : false,
    getLocationJobId: (state) => _get(state, 'location.JOB[0].id', false),
    getLocationJobAllowsRequisitionItems: (state) => {
      let allows = _get(state, 'location.JOB_ALLOWS_REQUISITION_ITEMS', '')
      if (allows.toLowerCase() === 'true') {
        return true
      }
      return false
    },
  },
}
