<template>
  <div class="date-range-controls">
    <BaseButton color="blue" md-icon="event" @click="showModal = true"
      >Filter by Date</BaseButton
    >
    <transition name="fade">
      <BaseButton
        v-if="isDateRangeFilterOn"
        color="white"
        outline
        md-icon="close"
        @click="clearDateFilters"
        >Clear Date Range</BaseButton
      >
    </transition>
    <DateRangeModal
      v-if="showModal"
      :show="showModal"
      :date-range="dateRange"
      @clearFilters="clearDateFilters"
      @close="showModal = false"
    />
  </div>
</template>
<script>
import DateRangeModal from '@modals/DateRangeModal'

export default {
  name: 'DateRangeControls',
  components: { DateRangeModal },
  props: {},
  data() {
    return {
      showModal: false,
      dateRange: {
        startDate: this.$route.query.startDate || '',
        endDate: this.$route.query.endDate || '',
      },
    }
  },
  computed: {
    isDateRangeFilterOn() {
      return (
        this.dateRange.startDate.length > 0 || this.dateRange.endDate.length > 0
      )
    }, // isDateRangeFilterOn
  }, // computed
  methods: {
    clearDateFilters() {
      let query = { ...this.$route.query }
      delete query.startDate
      delete query.endDate
      this.$router.push({ query: query })
    }, // clearDateFilters
  },
}
</script>
