<script>
import { AssetTypes } from '@constants/knack'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WithJobAssets',
  props: {
    assetType: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      areAssetsLoading: false,
      assetsLoadError: null,
    }
  },
  computed: {
    ...mapGetters([
      'getPhases',
      'getCostCodes',
      'getTheJobPhaseIds',
      'getTheJobCostCodeIds',
    ]),
    assets() {
      let assets = []
      if (this.assetType === AssetTypes.PHASES) {
        assets = this.getPhases
      } else if (this.assetType === AssetTypes.COST_CODES) {
        assets = this.getCostCodes
      }
      return assets
    },
    assignedAssetIds() {
      let assignedAssetIds =
        this.assetType === AssetTypes.COST_CODES
          ? this.getTheJobCostCodeIds
          : this.getTheJobPhaseIds

      return assignedAssetIds
    },
    visibleAssets() {
      return this.assets.filter(
        (asset) => !this.assignedAssetIds.includes(asset.ID)
      )
    },
  },
  watch: {
    assetType: {
      async handler(assetType) {
        this.areAssetsLoading = true
        try {
          if (!assetType) {
            throw new Error('Asset Type not provided')
          }

          if (assetType === 'BOTH') {
            await this.fetchAllJobAssets()
          } else if (assetType === AssetTypes.PHASES) {
            await this.fetchPhases()
          } else if (assetType === AssetTypes.COST_CODES) {
            await this.fetchCostCodes()
          }
        } catch (error) {
          this.assetsLoadError = error.message
        } finally {
          this.areAssetsLoading = false
        }
      },
      immediate: true,
    },
  }, // watch

  methods: {
    ...mapActions(['fetchAllJobAssets', 'fetchPhases', 'fetchCostCodes']),
  },
  render() {
    const { areAssetsLoading, assetsLoadError, visibleAssets } = this
    return this.$scopedSlots.default({
      areAssetsLoading,
      assetsLoadError,
      visibleAssets,
    })
  },
}
</script>
