<template>
  <VToolbar class="navbar red lighten-2 no-print" dark fixed app>
    <VFlex class="xs2 header--left header-section pr-3" grow text-xs-left>
      <RouterLink v-if="isAuthenticated" to="/dashboard" class="white--text"
        ><span v-if="!['md', 'sm', 'xs'].includes($mq)"
          >Westmoreland Construction, Inc.</span
        ><span v-else><VIcon>home</VIcon></span></RouterLink
      >
    </VFlex>
    <VFlex class="xs8" grow>
      <VToolbarTitle class="header-title header-section flex-12_12-print">
        <span v-if="$route.name === 'Dispatcher'">
          <RouterLink :to="dispatcherQuickLookLink">
            {{ pageTitle }}
          </RouterLink>
        </span>
        <span v-else-if="$route.name === 'Location'">
          <RouterLink :to="{ name: 'Locations Ready for Work' }">
            Locations
          </RouterLink>
        </span>
        <span v-else>{{ pageTitle }}</span>
        <div v-if="$route.name === 'Dispatcher'" class="header-subtitle"
          ><RouterLink :to="switchAssetLink">
            Switch to
            {{
              $route.params.assetType === 'laborer' ? 'Equipment' : 'Manpower'
            }}
          </RouterLink></div
        ></VToolbarTitle
      >
    </VFlex>
    <VFlex xs2 class="header--right header-section" text-xs-right>
      <NavDropdownMenu v-if="isAuthenticated" />
    </VFlex>
  </VToolbar>
</template>

<script>
import { mapGetters } from 'vuex'
import NavDropdownMenu from '@components/Nav/NavDropdownMenu.vue'

export default {
  name: 'NavBar',
  components: {
    NavDropdownMenu,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'navTitle']),
    switchAssetLink() {
      return {
        params: {
          assetType:
            this.$route.params.assetType === 'laborer'
              ? 'equipment'
              : 'laborer',
        },
      }
    }, // switchAssetLink
    pageTitle() {
      return this.navTitle(this.$route)
    }, // pageTitle
    dispatcherQuickLookLink() {
      return {
        name: 'Dispatcher Quick Look',
        params: {
          ...(this.$route.params.date && { date: this.$route.params.date }),
        },
      }
    }, // dispatcherQuickLookLink
  },
}
</script>

<style lang="scss" scoped>
.dropdown-button {
  color: white;
  text-transform: none;
}
.navbar a {
  text-decoration: none;
}
.header-subtitle {
  line-height: 14px;
  a {
    font-size: 14px;
  }
}
.header-title,
.header-subtitle {
  a {
    transition: color 0.3s ease;
    &:link,
    &:visited {
      color: white;
    }
    &:hover {
      color: lighten(black, 30%);
    }
  }
}
</style>
