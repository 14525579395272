/**
 * @fileoverview This file, when called by store/index.js dynamically registers
 * modules to the store based on their file name, normalized to camel case.
 */
import camelCase from 'lodash/camelCase';

const context = require.context('.', false, /\.js$/);
const modules = context.keys().reduce((obj, fileName) => {
  // Don't register this file, index.js.
  if (!(fileName === './index.js')) {
    const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''));
    obj[moduleName] = context(fileName).default || context(fileName);
  }
  return obj;
}, {});

export default modules;
