<template>
  <VDialog :value="show" :max-width="800" class="no-print">
    <VCard class="text-xs-center no-print">
      <VCardTitle class="blue darken-2 white--text">
        <VFlex xs12 mb-2><h2>Select Date Range</h2></VFlex>
      </VCardTitle>
      <VCardText class="font-weight-medium pa-5">
        <VMenu
          v-model="showStartDatePicker"
          :close-on-content-click="false"
          full-width
          max-width="500"
        >
          <template slot="activator">
            <VTextField
              :value="formattedStartDate"
              clearable
              label="Start date"
              readonly
              @click:clear="dateRange.startDate = ''"
            ></VTextField>
          </template>
          <VDatePicker
            v-model="dateRange.startDate"
            :landscape="false"
            full-width
            header-color="blue darken-2"
            :max="dateRange.endDate || ''"
            @change="showStartDatePicker = false"
            @input="dateRange.startDate = $event"
          ></VDatePicker>
        </VMenu>
        <VMenu
          v-model="showEndDatePicker"
          :close-on-content-click="false"
          full-width
          max-width="500"
        >
          <template slot="activator">
            <VTextField
              :value="formattedEndDate"
              clearable
              label="End Date"
              readonly
              @click:clear="dateRange.endDate = ''"
            ></VTextField>
          </template>
          <VDatePicker
            v-model="dateRange.endDate"
            :landscape="false"
            full-width
            :min="dateRange.startDate || ''"
            header-color="blue darken-2"
            @input="dateRange.endDate = $event"
            @change="showEndDatePicker = false"
          ></VDatePicker>
        </VMenu>
        <BaseButton color="blue darken-2" @click="applyDateRange"
          >Apply Range</BaseButton
        >
        <BaseButton
          :disabled="!dateRange.startDate && !dateRange.endDate"
          color="blue darken-2"
          outline
          @click="clearFilters"
          >Clear Filters</BaseButton
        >
      </VCardText>
      <VCardActions text-xs-right>
        <VSpacer />
        <BaseButton color="orange" outline small @click="$emit('close')"
          >Cancel</BaseButton
        >
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import VDialog from '@vuetify/VDialog'
import { VCardText, VCardActions } from '@vuetify/VCard'
import { VTextField, VDatePicker } from 'vuetify/lib'
import dayjs from 'dayjs'

export default {
  name: 'DateRangeModal',
  components: {
    VDialog,
    VCardText,
    VCardActions,
    VTextField,
    VDatePicker,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dateRange: {
      type: Object,
      default: () => {
        return { startDate: '', endDate: '' }
      },
    },
  },
  data() {
    return {
      showEndDatePicker: false,
      showStartDatePicker: false,
    }
  },
  computed: {
    formattedStartDate() {
      // TODO (ES) : Maybe need to add the dayjs custom parser here
      return this.dateRange.startDate
        ? dayjs(this.dateRange.startDate).format('MM/DD/YYYY')
        : ''
    },
    formattedEndDate() {
      // TODO (ES) : Maybe need to add the dayjs custom parser here
      return this.dateRange.endDate
        ? dayjs(this.dateRange.endDate).format('MM/DD/YYYY')
        : ''
    },
  },
  methods: {
    applyDateRange() {
      this.$notify({
        title: 'Applying Date Range...',
      })
      let query = { ...this.$route.query }
      if (this.dateRange.endDate) {
        query.endDate = this.dateRange.endDate
      } else {
        delete query.endDate
      }
      if (this.dateRange.startDate) {
        query.startDate = this.dateRange.startDate
      } else {
        delete query.startDate
      }
      this.$router.push({ query: query })

      this.$emit('close')
    }, // applyDateRange()

    clearFilters() {
      this.$emit('clearFilters')
      this.$emit('close')
    }, // clearFilters
  },
}
</script>

<style>
@media print {
  .v-overlay--active {
    display: none !important;
  }
}
</style>
