/** Defines all routes with appropriate permissions
 * to be used on both the Router as well as toggling
 * visibility of navbar and dashboard buttons
 **/

import { Roles } from '@constants/okta'

export const RoutePermissions = {
  '/reports': [Roles.ACCOUNTANT, Roles.PROJECT_MANAGER],
  '/todays-reports': [Roles.PROJECT_MANAGER],
  '/lock-reports': [Roles.ACCOUNTANT],
  '/locked-reports': [
    Roles.ACCOUNTANT,
    Roles.PROJECT_MANAGER,
    Roles.DISPATCHER,
  ],
  '/my-reports': [Roles.FOREMAN, Roles.PROJECT_MANAGER],
  '/dispatcher-reports': [Roles.DISPATCHER],
  '/invalid-reports': [Roles.ACCOUNTANT],
  '/locations': [Roles.FOREMAN, Roles.PROJECT_MANAGER],
  '/jobs': [Roles.FOREMAN, Roles.PROJECT_MANAGER, Roles.ACCOUNTANT],
  '/scheduler': [Roles.SCHEDULER_EDIT, Roles.SCHEDULER_VIEW],
  '/dispatcher': [Roles.DISPATCHER],
  '/dispatcher/:date?': [Roles.DISPATCHER],
  '/dispatcher/:date/:assetType': [Roles.DISPATCHER],
}
