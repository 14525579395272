<template>
  <div class="pagination">
    <template v-if="totalPages > 1">
      <BaseButton
        :disabled="pageNum <= 1 || isLoading"
        color="blue"
        class="mr-1"
        @click="$emit('previousPage')"
        >Prev {{ recordType }}</BaseButton
      >
      <BaseButton
        :disabled="pageNum > totalPages - 1 || isLoading"
        color="blue"
        class="ml-1"
        @click="$emit('nextPage')"
        >Next {{ recordType }}</BaseButton
      >
    </template>
    <p v-if="numberOfRecords"
      >Viewing Records {{ recordStartIndex }} – {{ recordEndIndex }} out of
      {{ numberOfRecords }}</p
    >
  </div>
</template>

<script>
export default {
  name: 'BasePagination',
  props: {
    page: {
      type: [Number, String],
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    numberOfRecords: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    recordType: {
      type: String,
      default: 'Record',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    pageNum() {
      return this.page
        ? this.page > 0
          ? Number(this.page)
          : this.totalPages > 0
          ? 1
          : 0
        : 0
    }, // pageNum
    recordStartIndex() {
      return (Number(this.page) - 1) * this.itemsPerPage + 1
    }, // recordStartIndex
    recordEndIndex() {
      let endIndex = this.recordStartIndex + this.itemsPerPage - 1
      return endIndex > this.numberOfRecords ? this.numberOfRecords : endIndex
    }, // recordEndIndex
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.left-side {
  font-size: 1.2rem;
  a {
    color: $gray-body;
  }
}
.item-index {
  margin-right: 7px;
}
.right-side {
  margin-bottom: 0;
  .btn {
    padding: 0 0.6em;
    border: 1px solid #bbb;
    &:hover,
    &:active {
      color: white;
      border-color: transparent;
    }
    &.view-btn {
      flex-basis: calc(#{'45% - 10px'});
      margin-right: 10px;
    }
    &.new-report-btn {
      flex-basis: 55%;
    }
  }
}
</style>
