import api from '@services/api'
import _get from 'lodash/get'
import {
  SET_JOBS_RESPONSE,
  SET_JOBS,
  SET_THE_JOB,
  UPDATE_JOB,
  ADD_JOB,
} from '@constants/mutations'
import { AssetObjectNames } from '@constants/knack'
import { createHelpers } from 'vuex-map-fields'
const { getJobsField, updateJobsField } = createHelpers({
  getterType: 'getJobsField',
  mutationType: 'updateJobsField',
})
export default {
  state: {
    response: {},
    theJob: {},
    jobs: [],
  },
  mutations: {
    [SET_JOBS_RESPONSE](state, payload) {
      state.response = payload
    },
    [SET_JOBS](state, jobs) {
      state.jobs = jobs
    },
    [SET_THE_JOB](state, payload) {
      state.theJob = payload
    },
    [ADD_JOB](state, job) {
      state.jobs.push(job)
    },
    [UPDATE_JOB](state, newJob) {
      let existingJob = state.jobs.find((l) => l.ID === newJob.ID)
      state.jobs.splice(state.jobs.indexOf(existingJob), 1, newJob)
    },

    updateJobsField,
  },
  actions: {
    async fetchJobs({ commit }, options = {}) {
      try {
        await api.getJobs(
          { options },
          (res) => {
            commit(SET_JOBS_RESPONSE, res)
            if (res && res.records) {
              commit(SET_JOBS, res.records)
            }
          },
          (err) => {
            // eslint-disable-next-line
            console.warn('Error : Couldnt fetch Jobs', {
              err,
            })
          }
        )
      } catch (err) {
        throw new Error(err)
      }
    },

    async fetchJob({ getters, commit, rootGetters }, options = {}) {
      try {
        if (!options.ID) {
          options.ID = rootGetters['getLocationJobId']
        }
        if (!options.ID) {
          throw new Error('No job id provided')
        }
        let job
        if (options.useCache) {
          job = await getters.getJobFromId(options.ID)
        }
        if (!job) {
          job = await api.get({ options, type: 'jobs' }, getters.accessToken)
        }

        commit(SET_THE_JOB, job)
        commit(ADD_JOB, job)

        return job
      } catch (err) {
        throw new Error(err)
      }
    }, // fetchJob

    async updateJob({ dispatch, rootGetters }, job) {
      try {
        let jobId = job.ID || rootGetters['getLocationJobId']
        if (!jobId) {
          throw new Error('No job id provided')
        }
        const updatedJobResponse = await api.updateJob(
          job.ID,
          job,
          (updatedJob) => dispatch('updateJobSuccess', { updatedJob }),
          (err) => {
            throw new Error(err)
          }
        )
        return updatedJobResponse
      } catch (err) {
        throw new Error(err)
      }
    }, // updateJob

    updateJobSuccess({ commit, getters }, { updatedJob }) {
      commit(SET_THE_JOB, updatedJob)
      // If this loc exists in job state, update
      if (getters.getJobFromId(updatedJob.ID)) {
        commit(UPDATE_JOB, updatedJob)
      }
      return updatedJob
    },
  },
  getters: {
    getJobsResponse: (state) => state.response,
    getAllJobs: (state) => state.jobs || [],
    getTheJob: (state) => state.theJob,
    getTheJobId: (state) => _get(state, 'theJob.ID', false),
    getJobFromId: (state) => (jobId) => state.jobs.find((j) => j.ID === jobId),
    getAssetsForJob: (state, getters, __, rootGetters) => (
      jobId,
      assetType
    ) => {
      if (!jobId) {
        jobId = rootGetters.getLocationJobId
      }
      if (!jobId) {
        console.warn('No job id provided')
        return []
      }
      let job = getters.getJobFromId(jobId)
      if (!job) {
        console.warn(`No job found for id ${jobId}`)
        return []
      }
      let assetsAllowedOnJob = job[assetType].map((asset) => asset.id)
      return state[assetType.toLowerCase()].filter((asset) =>
        assetsAllowedOnJob.includes(asset.ID)
      )
    },
    getPhasesForJob: (_, getters) => (jobId) => {
      return getters.getJobAssets(jobId, AssetObjectNames.phases)
    },
    getCostCodesForJob: (_, getters) => (jobId) => {
      return getters.getJobAssets(jobId, AssetObjectNames.cost_codes)
    },
    getJobsField,
  },
}
