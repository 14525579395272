import api from '@services/api'
import {
  SET_PHASES,
  SET_JOB_PHASES,
  ADD_JOB_PHASE,
  REPLACE_JOB_PHASE,
  REMOVE_JOB_PHASE,
  SET_COST_CODES,
  ADD_JOB_COST_CODE,
  REPLACE_JOB_COST_CODE,
  REMOVE_JOB_COST_CODE,
  SET_JOB_COST_CODES,
} from '@constants/mutations'
import { AssetObjectNames, AssetTypes } from '@constants/knack'
import _get from 'lodash/get'
import { createHelpers } from 'vuex-map-fields'
const { getJobAssetsField, updateJobAssetsField } = createHelpers({
  getterType: 'getJobAssetsField',
  mutationType: 'updateJobAssetsField',
})

export default {
  state: {
    phases: [],
    cost_codes: [],
    job_phases: [],
    job_cost_codes: [],
    selectedSourceAssets: [],
    selectedLocalAssets: [],
    isSavingJobAssets: false,
    showJobAssetManager: false,
  },
  mutations: {
    [SET_PHASES](state, phases) {
      state.phases = phases
    },
    [SET_COST_CODES](state, costCodes) {
      state.cost_codes = costCodes
    },
    [SET_JOB_PHASES](state, phases) {
      state.job_phases = phases
    },
    [ADD_JOB_PHASE](state, phase) {
      state.job_phases.push(phase)
    },
    [REPLACE_JOB_PHASE](state, jobPhase) {
      if (!jobPhase.ID) {
        console.error('Job Phase ID not found')
        return
      }

      let updatedPhases = [...state.job_phases]
      let record = updatedPhases.find((phase) => phase.ID === jobPhase.ID)
      let index = updatedPhases.indexOf(record)
      let modifiedRecord = { ...jobPhase }
      updatedPhases.splice(index, 1, modifiedRecord)
      state.job_phases = updatedPhases
    },
    [REMOVE_JOB_PHASE](state, jobPhaseId) {
      let updatedPhases = [...state.job_phases]
      updatedPhases = updatedPhases.filter((phase) => phase.ID !== jobPhaseId)
      state.job_phases = updatedPhases
    },
    [SET_JOB_COST_CODES](state, costCodes) {
      state.job_cost_codes = costCodes
    },
    [ADD_JOB_COST_CODE](state, costCode) {
      state.job_cost_codes.push(costCode)
    },
    [REPLACE_JOB_COST_CODE](state, jobCostCode) {
      if (!jobCostCode.ID) {
        console.error('Job Cost Code ID is required to update')
        return
      }

      let updatedCostCodes = [...state.job_cost_codes]
      let record = updatedCostCodes.find(
        (costCode) => costCode.ID === jobCostCode.ID
      )
      let index = updatedCostCodes.indexOf(record)
      let modifiedRecord = { ...jobCostCode }
      updatedCostCodes.splice(index, 1, modifiedRecord)
      state.job_cost_codes = updatedCostCodes
    },
    [REMOVE_JOB_COST_CODE](state, jobCostCodeId) {
      state.job_cost_codes = state.job_cost_codes.filter(
        (costCode) => costCode.ID !== jobCostCodeId
      )
    },
    updateJobAssetsField,
  }, // mutations

  actions: {
    async fetchPhases({ commit }) {
      try {
        const response = await api.getPhases({
          options: { rows_per_page: 1000 },
        })
        commit(SET_PHASES, response.records)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchCostCodes({ commit }) {
      try {
        const response = await api.getCostCodes({
          options: { rows_per_page: 1000 },
        })
        commit(SET_COST_CODES, response.records)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchAllJobAssets(
      { rootGetters, dispatch },
      { jobId, options = {} } = {}
    ) {
      if (!jobId) {
        jobId = rootGetters['getLocationJobId']
      }

      if (!jobId) {
        throw new Error('fetchAllJobAssets: jobId is required')
      }
      try {
        await dispatch('fetchJobPhases', { jobId, options })
        await dispatch('fetchJobCostCodes', { jobId, options })
      } catch (err) {
        throw new Error(err)
      }
    },
    async fetchJobPhases({ commit, rootGetters }, { jobId, options } = {}) {
      if (!jobId) {
        jobId = rootGetters['getLocationJobId']
      }
      if (!jobId) {
        throw new Error('fetchJobPhases: jobId is required')
      }
      try {
        let phases = await api.getJobPhases(
          jobId,
          { options },
          ({ records: phases }) => {
            commit(SET_JOB_PHASES, phases)
            return phases
          },
          (err) => {
            throw err
          }
        )
        return phases
      } catch (err) {
        throw new Error(err)
      }
    },
    async fetchJobCostCodes(
      { commit, rootGetters },
      { jobId, options = {} } = {}
    ) {
      if (!jobId) {
        jobId = rootGetters['getLocationJobId']
      }
      if (!jobId) {
        throw new Error('fetchJobCostCodes: jobId is required')
      }
      try {
        let costCodes = await api.getJobCostCodes(
          jobId,
          { options },
          ({ records: costCodes }) => {
            commit(SET_JOB_COST_CODES, costCodes)
            return costCodes
          },
          (err) => {
            throw err
          }
        )
        return costCodes
      } catch (err) {
        throw new Error(err)
      }
    },

    async addJobAssets({ state, dispatch }, { assetType }) {
      state.isSavingJobAssets = true
      try {
        let selectedAssets = state.selectedSourceAssets

        let addRequests = selectedAssets.map((asset) =>
          dispatch('createJobAsset', { assetType, asset })
        )
        await Promise.all(addRequests)

        state.isSavingJobAssets = true
        state.selectedSourceAssets = []
      } catch (error) {
        console.error(error)
      } finally {
        state.isSavingJobAssets = false
      }
    }, // end addJobAssets

    async createJobAsset(
      { commit, rootGetters, rootState },
      { assetType, asset }
    ) {
      try {
        if (!asset) {
          throw new Error('createJobAsset: asset is required')
        }
        if (![AssetTypes.PHASES, AssetTypes.COST_CODES].includes(assetType)) {
          throw new Error(
            `createJobAsset: assetType must be ${AssetTypes.PHASES} or ${AssetTypes.COST_CODES}`
          )
        }
        let isCostCode = assetType === AssetTypes.COST_CODES
        let action = isCostCode ? 'createJobCostCode' : 'createJobPhase'

        let isViewingJobPage = rootState.route.name === 'Job'
        let jobIdFromRoute = rootState.route.params.jobId
        let jobId = isViewingJobPage
          ? jobIdFromRoute
          : rootGetters['getLocationJobId']

        if (!jobId) {
          jobId = rootGetters['getTheJobId']
        }

        if (!jobId) {
          throw new Error(
            'createJobAsset: jobId is required and not found in route or store'
          )
        }
        let assetKeySingular = (isCostCode
          ? AssetObjectNames.cost_codes
          : AssetObjectNames.phases
        ).replace(/s$/i, '')

        let jobAsset = {
          [assetKeySingular]: [asset.ID],
          JOB: [jobId],
        }
        let response = await api[action](jobAsset)
        let jobAssetTypeSingular = (isCostCode
          ? AssetTypes.JOB_COST_CODES
          : AssetTypes.JOB_PHASES
        )
          .replace(/s$/i, '')
          .toUpperCase()
        commit(`ADD_${jobAssetTypeSingular}`, response)
      } catch (error) {
        console.error(error)
      }
    }, // end createJobAsset

    async removeJobAssets({ state, dispatch }, { assetType }) {
      state.isSavingJobAssets = true
      try {
        let selectedAssets = state.selectedLocalAssets

        let jobAssetType =
          assetType === AssetTypes.PHASES
            ? AssetTypes.JOB_PHASES
            : AssetTypes.JOB_COST_CODES

        let removeRequests = selectedAssets.map((asset) =>
          dispatch('removeJobAsset', { jobAssetType, jobAssetId: asset.ID })
        )
        await Promise.all(removeRequests)

        state.isSavingJobAssets = true
        state.selectedLocalAssets = []
      } catch (error) {
        console.error(error)
      } finally {
        state.isSavingJobAssets = false
      }
    }, // end removeJobAssets

    async removeJobAsset({ commit }, { jobAssetType, jobAssetId }) {
      try {
        if (
          !jobAssetType ||
          ![AssetTypes.JOB_PHASES, AssetTypes.JOB_COST_CODES].includes(
            jobAssetType
          )
        ) {
          throw new Error(
            `removeJobAsset: jobAssetType must be ${AssetTypes.JOB_PHASES} or ${AssetTypes.JOB_COST_CODES}`
          )
        }
        let isJobCostCode = jobAssetType === AssetTypes.JOB_COST_CODES
        let action = 'delete' + (isJobCostCode ? 'JobCostCode' : 'JobPhase')

        let deleteRes = await api[action](jobAssetId)

        if (deleteRes.delete === true) {
          let jobAssetTypeSingular = (isJobCostCode
            ? AssetTypes.JOB_COST_CODES
            : AssetTypes.JOB_PHASES
          )
            .replace(/s$/i, '')
            .toUpperCase()
          commit(`REMOVE_${jobAssetTypeSingular}`, jobAssetId)
        } else {
          throw Error({ deleteRes, msg: 'Job Asset could not be removed' })
        }
      } catch (error) {
        console.error(error)
      }
    }, // end createJobAsset

    async updateJobAsset({ state, commit }, { assetType, jobAsset }) {
      if (!jobAsset.ID) {
        throw new Error('updateJobAsset: jobAssetId is required')
      }
      try {
        state.isSavingJobAssets = true

        let isJobCostCode = assetType === AssetTypes.COST_CODES

        let action = 'update' + (isJobCostCode ? 'JobCostCode' : 'JobPhase')

        let updateResponse = await api[action](jobAsset.ID, jobAsset)

        let jobAssetTypeSingular = (isJobCostCode
          ? AssetTypes.JOB_COST_CODES
          : AssetTypes.JOB_PHASES
        )
          .replace(/s$/i, '')
          .toUpperCase()

        commit(`REPLACE_${jobAssetTypeSingular}`, updateResponse)
      } catch (error) {
        console.error(error)
      } finally {
        state.isSavingJobAssets = false
      }
    }, // end updateJobAsset
  }, // actions
  getters: {
    /// ////////////////////////
    // Phases
    /// ////////////////////////
    getPhases: (state) => state.phases,
    getJobPhases: (state) => state.job_phases,
    getJobPhasesForJobId: (_, getters) => (jobId) =>
      getters.getJobPhases.filter((jobPhase) => jobPhase.JOB[0].id === jobId),
    getPhasesFromJobPhases: () => (jobPhases) => {
      return jobPhases.map((jobPhase) => {
        let phase = _get(jobPhase, 'PHASE[0]', {})
        let localDescription = _get(jobPhase, 'LOCAL_DESCRIPTION', '')
        let description = localDescription.length
          ? localDescription
          : _get(jobPhase, 'DESCRIPTION', '')
        let identifierParts = [phase.identifier.trim()]
        if (description.length) {
          identifierParts.push(description.trim())
        }
        phase.identifierWithDescription = identifierParts.join(' - ')
        return phase
      })
    }, // getPhasesFromJobPhases

    /// theJob...
    getPhasesForTheJob: (_, getters, ___, rootGetters) => {
      let jobId = rootGetters.getLocationJobId
      if (!jobId) {
        jobId = rootGetters.getTheJobId
      }
      if (!jobId) {
        console.warn('No jobId found for getPhasesForTheJob')
        return []
      }

      return getters.getPhasesFromJobPhases(getters.getJobPhasesForJobId(jobId))
    },
    getTheJobPhaseIds: (state, getters) =>
      getters.getPhasesForTheJob?.map((jobPhase) => jobPhase.id),

    /// ////////////////////////
    // Cost Codes
    /// ////////////////////////
    getCostCodes: (state) => state.cost_codes,
    getJobCostCodes: (state) => state.job_cost_codes,
    getJobCostCodeById: (state) => (id) =>
      state.job_cost_codes.find((jobCostCode) => jobCostCode.ID === id),
    getJobCostCodesForJobId: (_, getters) => (jobId) =>
      getters.getJobCostCodes.filter(
        (jobCostCode) => jobCostCode.JOB[0].id === jobId
      ),
    getCostCodesFromJobCostCodes: () => (jobCostCode) => {
      return jobCostCode.map((jobCostCode) => {
        let costCode = _get(jobCostCode, 'COST_CODE[0]', {})
        let identifier = _get(costCode, 'identifier', '')
        let localDescription = _get(jobCostCode, 'LOCAL_DESCRIPTION', '')
        let description = localDescription.length
          ? localDescription
          : _get(jobCostCode, 'DESCRIPTION', '')
        let identifierParts = [identifier.trim()]
        if (description.length) {
          identifierParts.push(description.trim())
        }
        costCode.identifierWithDescription = identifierParts.join(' - ')
        return costCode
      })
    }, // getCostCodesFromJobCostCodes

    /// theJob...
    getCostCodesForTheJob: (_, getters, ___, rootGetters) => {
      let jobId = rootGetters.getLocationJobId || rootGetters.getTheJobId
      if (!jobId) {
        console.warn('No jobId found for getJobCostCodesForTheJob')
        return []
      }
      return getters.getCostCodesFromJobCostCodes(
        getters.getJobCostCodesForJobId(jobId)
      )
    },
    getTheJobCostCodeIds: (state, getters) =>
      getters.getCostCodesForTheJob?.map((jobCostCode) => jobCostCode.id),

    getJobAssetsField,
  }, // getters
}
