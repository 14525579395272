<template>
  <div class="input-select">
    <VSelect
      :items="options"
      :label="title"
      item-text="name"
      item-value="value"
      :solo="solo"
      :color="color"
      menu-props="offsetY"
      v-bind="{ ...$props, ...$attrs }"
      @change="changeSelection"
      @click:clear="$emit('click:clear')"
    >
    </VSelect>
  </div>
</template>

<script>
import VSelect from '@vuetify/VSelect'

export default {
  name: 'BaseDropdown',
  components: { VSelect },
  props: {
    ...VSelect.props,
    title: {
      type: String,
      default: 'Filter by',
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    selected: {
      type: String,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
    solo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      instance: null,
    }
  }, // props
  computed: {
    selectionName() {
      return this.options?.filter((o) => o.value === this.selected)[0].name
    }, // selectionName
  },
  methods: {
    changeSelection(val) {
      this.$emit('selected', val)
    }, // changeSelection
  },
}
</script>

<style scoped>
.input-select >>> .v-list.theme--light .v-list__tile--link:hover {
  background: rgba(0, 0, 0, 0.1);
}
</style>
