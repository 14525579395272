import Home from '@views/Home.vue'
import Router from 'vue-router'
import Vue from 'vue'
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta'
import store from '@store'

import Auth from '@okta/okta-vue'

import { SET_SIGNIN_REDIRECT } from '@constants/mutations'
import { Roles, OktaConfig } from '@constants/okta'
import { RoutePermissions } from '@constants/routePermissions'

Vue.use(Auth, OktaConfig)

Vue.use(Router)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'metaInfo',
})
// Q: why is this being used instead of store.user.getters.isUserLoggedIn?
// why is made into a function? so it doesnt cache?

// const isAuthenticated = () => !!store.state.user.profile

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Sign In',
      component: Home,
      alias: '/login',
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () =>
        import(/* webpackChunkName: "dashboard" */ '@views/DashboardView.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/implicit/callback/',
      component: Auth.handleCallback(),
    },
    // Report Routes

    {
      path: '/reports',
      name: 'All Reports',
      component: () =>
        import(/* webpackChunkName: 'AllReports' */ '@views/AllReportsListView.vue'),
      meta: {
        requiresAuth: true,
        rolesAllowed: RoutePermissions['/reports'],
      },
    },
    {
      path: '/todays-reports',
      name: `Today's Reports`,
      component: () =>
        import(/* webpackChunkName: 'TodaysReports' */ '@views/TodaysReportsListView.vue'),
      meta: {
        requiresAuth: true,
        rolesAllowed: RoutePermissions['/todays-reports'],
      },
    },

    {
      path: '/lock-reports',
      name: `Lock Reports`,
      component: () =>
        import(/* webpackChunkName: 'LockReports' */ '@views/LockReportsListView.vue'),
      meta: {
        requiresAuth: true,
        rolesAllowed: RoutePermissions['/lock-reports'],
      },
    },
    {
      path: '/locked-reports',
      name: `Locked Reports`,
      component: () =>
        import(/* webpackChunkName: 'LockedReports' */ '@views/LockedReportsListView.vue'),
      meta: {
        requiresAuth: true,
        rolesAllowed: RoutePermissions['/locked-reports'],
      },
    },

    {
      path: '/my-reports',
      name: 'My Reports',
      component: () =>
        import(/* webpackChunkName: 'MyReports' */ '@views/MyReportsListView.vue'),
      meta: {
        requiresAuth: true,
        rolesAllowed: RoutePermissions['/my-reports'],
      },
    },
    {
      path: '/dispatcher-reports',
      name: 'Dispatcher Reports',
      component: () =>
        import(/* webpackChunkName: 'DispatcherReports' */ '@views/DispatcherReportsListView.vue'),
      meta: {
        requiresAuth: true,
        rolesAllowed: RoutePermissions['/dispatcher-reports'],
      },
    },

    {
      path: '/invalid-reports',
      name: 'Invalid Reports',
      component: () =>
        import(/* webpackChunkName: 'InvalidReports' */ '@views/InvalidReportsListView.vue'),
      meta: {
        requiresAuth: true,
        rolesAllowed: RoutePermissions['/invalid-reports'],
      },
    },

    // Jobs Routes

    {
      path: '/jobs',
      name: 'Jobs',
      component: () =>
        import(/* webpackChunkName: 'Jobs' */ '@views/JobsListView.vue'),
      meta: {
        requiresAuth: true,
        rolesAllowed: RoutePermissions['/jobs'],
      },
    },

    {
      path: '/jobs/:jobId',
      name: 'Job',
      component: () =>
        import(/* webpackChunkName: 'Job' */ '@views/JobView.vue'),
      props: (route) => ({
        jobId: route.params.jobId,
      }),
      meta: { requiresAuth: true },
    },

    // Location Routes

    {
      path: '/locations',
      name: 'Locations Ready for Work',
      component: () =>
        import(/* webpackChunkName: 'LocationsListView' */ '@views/LocationsListView.vue'),
      meta: {
        requiresAuth: true,
        rolesAllowed: RoutePermissions['/locations'],
      },
    },

    {
      path: '/locations/:locationId',
      name: `Location`,
      component: () =>
        import(/* webpackChunkName: 'LocationView' */ '@views/LocationView.vue'),
      props: (route) => ({
        locationId: route.params.locationId,
      }),
      meta: { requiresAuth: true },
    },

    {
      path: '/locations/:locationId/dailys/:reportId',
      name: 'Daily Report',
      component: () =>
        import(/* webpackChunkName: 'DailyReport' */ '@views/DailyReportView.vue'),
      props: (route) => ({
        locationId: route.params.locationId,
        reportId: route.params.reportId,
      }),
      meta: { requiresAuth: true },
    },

    // Scheduler Routes

    {
      path: '/scheduler',
      name: 'Scheduler',
      component: () =>
        import(/* webpackChunkName: 'Scheduler' */ '@views/SchedulerView.vue'),
      meta: {
        requiresAuth: true,
        rolesAllowed: RoutePermissions['/scheduler'],
      },
    },

    {
      path: '/print-scheduler',
      name: 'Scheduler Print',
      component: () =>
        import(/* webpackChunkName: 'SchedulerPrint' */ '@views/SchedulerPrintView.vue'),
      meta: { requiresAuth: true },
    },

    {
      path: '/print-fiber-locations',
      name: 'Fiber Location Print',
      component: () =>
        import(/* webpackChunkName: 'FiberLocationPrint' */ '@views/FiberLocationPrintView.vue'),
      meta: { requiresAuth: true },
    },

    // Dispatcher Routes

    {
      path: '/dispatcher/:date?',
      name: 'Dispatcher Quick Look',
      component: () =>
        import(/* webpackChunkName: 'DispatcherQuickLook' */ '@views/DispatcherQuickLookView.vue'),
      props: (route) => ({
        date: route.params.date,
      }),
      meta: {
        requiresAuth: true,
        rolesAllowed: RoutePermissions['/dispatcher/:date?'],
      },
    },

    {
      path: '/dispatcher/:date/:assetType',
      name: 'Dispatcher',
      component: () =>
        import(/* webpackChunkName: 'Dispatcher' */ '@views/DispatcherView.vue'),
      props: (route) => ({
        date: route.params.date,
        assetType: route.params.assetType,
      }),
      meta: {
        requiresAuth: true,
        rolesAllowed: RoutePermissions['/dispatcher/:date/:assetType'],
      },
    },

    // Other Routes

    {
      path: '/404',
      name: '404',
      component: () => import(/* webpackChunkName: '404' */ '@views/_404.vue'),
      // Allows props to be passed to the 404 page through route
      // params, such as `resource` to define what wasn't found.
      props: true,
    },
    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    {
      path: '*',
      redirect: '404',
      props: true,
      meta: { requiresAuth: true },
    },
    { path: '/' },
  ],
})

router.beforeEach(async (to, from, next) => {
  Vue.prototype.$auth.authRedirectGuard()
  await store.dispatch('checkAuth')

  // calculate `isAuthenticated` in local scope instead of once in global scope
  // to ensure it's recomputed
  let isAuthenticated = await Vue.prototype.$auth.isAuthenticated()

  let userRoles = await store.getters.userRoles
  let doesUserHavePermission =
    !to.meta.rolesAllowed || userRoles.includes(Roles.ADMIN)
      ? true
      : to.meta.rolesAllowed.filter((role) => userRoles.includes(role)).length

  // If this is auth protected and we're not auth'd
  // send the user to the SignIn page
  if (to.meta.requiresAuth && !isAuthenticated) {
    // Use redirect prop to redirect user to their desired path after log in.
    store.commit(SET_SIGNIN_REDIRECT, to.fullPath)
    next({ name: 'Sign In', props: { redirect: to.path || '/dashboard' } })
  }
  // If the user is authenticated and we're headed to SignIn,
  // reroute to the dashboard
  else if (isAuthenticated && ['/', '/login'].includes(to.path)) {
    // Reroute / and /login to /dashboard if logged in
    next('/dashboard')
  } else if (!doesUserHavePermission) {
    next({
      name: '404',
      params: { wrongPermissions: true, resource: to.name || 'test path' },
    })
  } else {
    if (typeof window.FS !== 'undefined') {
      window.FS.setUserVars({ userRoles })
    }    
    let nextRoute = to.path || '/'
    next({ props: { redirect: nextRoute } })
  }
})

export default router
