<template>
  <div :class="[{ 'inline-checkbox': inline }]">
    <VCheckbox
      v-model="isChecked"
      :color="color"
      v-bind="allBindings"
      :class="[{ 'no-print': !forceShowLabel }]"
      :label="
        (['sm', 'xs'].includes($mq) && label.length) || forceShowLabel
          ? label
          : ''
      "
      @change="$emit('change', $event)"
    />
    <VIcon v-if="isChecked" class="print-only">{{ printIcon }}</VIcon>
    <!-- <VIcon v-else class="print-only">remove</VIcon> -->
  </div>
</template>

<script>
import { VCheckbox } from 'vuetify/lib'

export default {
  name: 'Checkbox',
  components: {
    VCheckbox,
  },
  props: {
    value: {
      type: [Boolean, Number],
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    forceShowLabel: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'teal',
    },
    printIcon: {
      type: String,
      default: 'check',
    },
  },
  data() {
    return {}
  },
  computed: {
    allBindings() {
      return { ...this.$props, ...this.$attrs }
    },
    isChecked: {
      get() {
        return this.value
      },
      set(val) {
        // nothin dawg
      },
    },
  },
}
</script>

<style lang="scss">
.v-input.v-input--checkbox {
  margin-top: 0;
}

@media print {
  .v-input.v-input--checkbox {
    display: inline-block;
  }
  .inline-checkbox {
    text-align: left;
    .v-input--selection-controls__input {
      display: none !important;
    }
  }
  .v-icon {
    margin-left: 10px;
  }
}
</style>
