<template>
  <select
    v-model="selected"
    class="browser-default modal-select"
    @change.prevent="changeValue"
  >
    <option v-for="option of options" :key="option.id" :value="option.id">{{
      option.text
    }}</option>
    <label> Select {{ fieldName }}</label>
  </select>
</template>

<script>
import _isUndefined from 'lodash/isUndefined'
// import { mapState } from 'vuex'

export default {
  name: 'ModalSelect',
  props: {
    fieldName: {
      type: String,
      default: '',
    },
    fieldType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected: this.$store.state.modal.field.value || null,
    }
  },
  computed: {
    selectedOption() {
      // var selectedOption = false
      // var self = this
      // var selectedOption = this.options.find(
      //   (option) => option.id === self.selected
      // )
      // return selectedOption || false
      return false
    },

    options() {
      return this.$store.state.modal.options
    },
  },

  created() {
    var modalText = this.$store.state.modal.field.value.text

    if (_isUndefined(modalText) || modalText == null) {
      this.selected = null
      return ''
    }

    if (this.fieldType === 'timepicker') {
      var timeString = modalText
      var isPM = timeString.indexOf('pm') > -1

      var timeStringParts = timeString.replace(/am|pm/g, '').split(':')

      var h =
        parseInt(timeStringParts[0]) < 10
          ? '0' + timeStringParts[0]
          : timeStringParts[0]
      h = isPM ? parseInt(h) + 12 : h === 12 ? '00' : h

      var m = timeStringParts[1]

      var convertedTimeVal =
        timeStringParts.length > 1 ? [h, m].join(':') : false
      this.selected = convertedTimeVal || null
    } else {
      this.selected = this.$store.state.modal.field.value.id || null
    }
  },
  methods: {
    changeValue() {
      var field = this.$store.state.modal.field

      if (this.selectedOption) {
        field.value = this.selectedOption.text
      } else {
        field.value = null
      }

      this.$emit('modal-value-changed', this.selectedOption)
    },
  },
}
</script>
