import _flattenDeep from 'lodash/flattenDeep'
import _get from 'lodash/get'
import { DRAG_START, DRAG_END, SET_THE_DRAG_OBJECT } from '@constants/mutations'

export default {
  state: {
    isDragging: false,
    theDragObject: null,
  },
  mutations: {
    [DRAG_START](state) {
      state.isDragging = true
    },
    [DRAG_END](state) {
      state.isDragging = false
      state.theDragObject = null
    },
    [SET_THE_DRAG_OBJECT](state, report) {
      state.theDragObject = report
    },
  },
  actions: {
    startDrag({ commit }) {
      commit(DRAG_START)
    }, // startDrag
    endDrag({ commit }) {
      commit(DRAG_END)
    }, // endDrag
    setTheDragObject({ commit }, report) {
      commit(SET_THE_DRAG_OBJECT, report)
    }, // setTheDragObject
  },
  getters: {
    isDragging: (state) => state.isDragging,
    theDragObject: (state) => state.theDragObject,
    theDragObjectShiftTypes: (state) => {
      let objType = _get(state, 'theDragObject.PERMIT__WORK_HOURS')
        ? 'location'
        : 'report'
      let workHours
      workHours =
        objType === 'report'
          ? _get(state, 'theDragObject.locationData.PERMIT__WORK_HOURS', [])
          : _get(state, 'theDragObject.PERMIT__WORK_HOURS', [])
      return workHours.length
        ? _flattenDeep(
            workHours.map((shift) =>
              shift.split(';').map((token) => token.trim())
            )
          ) || []
        : []
    },
  },
}
