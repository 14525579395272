export const contentTypeFields = {
  JOBS: 'object_1',
  LOCATIONS: 'object_2',
  CREW: 'object_4',
  TASKS: 'object_5',
  COMPANY: 'object_6',
  ACCOUNTS: 'object_7',
  ESTIMATOR: 'object_8',
  NODE_SCHEDULER: 'object_9',
  MANAGERADMIN: 'object_10',
  PERMIT_MANAGEMENT: 'object_12',
  PROJECT_MANAGER: 'object_13',
  SUB_CONTRACTOR: 'object_15',
  DAILY_REPORT: 'object_19',
  LABORER: 'object_20',
  EQUIPMENT: 'object_21',
  MATERIALPART: 'object_22',
  PAYWORK_ITEM: 'object_23',
  LABOR_ASSIGNMENT: 'object_24',
  EQUIPMENT_USAGE: 'object_25',
  MATERIAL_USAGE: 'object_26',
  COMPLETED_WORK_QUANTITY: 'object_27',
  CORRECTIVE_ACTION_REQUESTS: 'object_29',
  SUMMONS: 'object_30',
  CAR_INSPECTIONS: 'object_31',
  WORK_ORDERS: 'object_35',
  INSURANCE_INCIDENTS: 'object_36',
  INSURANCE_CORRESPONDENCE: 'object_37',
  PAVING_SUB_WORK: 'object_38',
  EQUIPMENT_TYPE: 'object_40',
  PAVING_AREA: 'object_41',
  JOB_INVENTORY_ITEM: 'object_42',
  JOB_PURCHASE_ORDER_MATERIAL_ITEM: 'object_43',
  JOB_PURCHASE_ORDER: 'object_44',
  VENDOR: 'object_45',
  JOB_PAY_ITEM: 'object_46',
  EQUIPMENT_SERVICE_TICKET: 'object_47',
  LABOR_RATE: 'object_49',
  JOB_PURCHASE_ORDER_EQUIPMENT_RENTAL_ITEM: 'object_50',
  JOB_PURCHASE_ORDER_SUB_CONTRACTOR_ITEM: 'object_51',
  BID_DOCUMENTS: 'object_52',
  SURVEY_TEAM: 'object_53',
  COST_CODES: 'object_55',
  PHASES: 'object_56',
  CATCH_BASINS: 'object_57',
  SHIFT_ITEMS: 'object_58',
  CHANGE_ORDER_LOGS: 'object_63',
  SUBMITTAL_LOGS: 'object_64',
  JOB_COST_CODES: 'object_65',
  JOB_PHASES: 'object_66',
  SUBCONTRACTOR_INSURANCE_LOGS: 'object_67',
  RFI_LOGS: 'object_68',
  REQUISITION_ITEMS: 'object_69',
  TASK_UPDATES: 'object_70',
}
