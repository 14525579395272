import Vue from 'vue'
import api from '@services/api'
import fullstory from '@utils/fullstory'
import {
  HumanFields,
  AssignmentTables,
  AssetTypes,
  AssetObjectNames,
} from '@constants/knack'
import {
  SET_ASSIGNED_ASSETS_RESPONSE,
  SET_ALL_ASSETS_RESPONSE,
  SET_ALL_ASSETS,
  SET_ASSIGNED_ASSETS,
  SET_ALL_ASSETS_DONE_LOADING,
  SET_ALL_ASSETS_STILL_LOADING,
  SET_ASSIGNMENTS_DONE_LOADING,
  SET_ASSIGNMENTS_STILL_LOADING,
  SELECT_ASSET,
  DESELECT_ASSET,
  DESELECT_ALL_ASSETS,
  SELECT_ASSGNT,
  DESELECT_ASSGNT,
  DESELECT_ALL_ASSGNTS,
  ADD_ASSET_ASSIGNMENT,
  UPDATE_ASSET_ASSIGNMENT,
  REMOVE_ASSET_ASSIGNMENT,
  SET_IS_PROCESSING,
  SET_NOT_PROCESSING,
  SET_ASSET_HOVER,
  UNSET_ASSET_HOVER,
  SET_MULTI_ASSIGN_PROPS,
  RESET_MULTI_ASSIGN_PROPS,
} from '@constants/mutations'
import nanoid from 'nanoid'
import _get from 'lodash/get'
import _groupBy from 'lodash/groupBy'

export default {
  state: {
    data: {},
    assets: [],
    // multiple report records (plus total_records and other metadata)
    // response.records contains the records
    assignedAssetsResponses: {},
    allAssetsResponses: {},
    allAssets: {},
    lastAssetsFetchTime: null,
    assignedAssets: {},
    areAllAssetsLoading: true,
    areAssignmentsLoading: true,
    selectedAssets: [],
    selectedAssgnts: [],
    hoveringAssetId: null,
    multiAssign: {
      asset: {},
      assgnts: [],
      existingAssgnt: false,
      targetReport: {},
    },
  },
  mutations: {
    //
    // Assets and Assignment
    //

    [SET_ASSIGNED_ASSETS_RESPONSE](state, { assetType, response }) {
      state.assignedAssetsResponses[assetType] = response
    },
    [SET_ALL_ASSETS_RESPONSE](state, { assetType, response }) {
      state.allAssetsResponses[assetType] = response
    },
    [SET_ALL_ASSETS](state, { assetType, assets }) {
      state.allAssets[assetType] = assets
      state.lastAssetsFetchTime = Date.now()
    },
    [SET_ASSIGNED_ASSETS](state, { assetType, assets }) {
      state.assignedAssets[assetType] = assets
    },

    [ADD_ASSET_ASSIGNMENT](state, { assetType, assgnt, reportId }) {
      let newAssgnt = { ...assgnt, DAILY_REPORT: [{ id: reportId }] }
      let refreshedAssetsArray = state.assignedAssets[assetType]
      refreshedAssetsArray.push(newAssgnt)
      state.assignedAssets = {
        ...state.assignedAssets,
        [assetType]: refreshedAssetsArray,
      }
    }, // ADD_ASSET_ASSIGNMENT

    [UPDATE_ASSET_ASSIGNMENT](state, { assetType, assgnt, reportId }) {
      let existingIndex = state.assignedAssets[assetType].indexOf(
        state.assignedAssets[assetType].find((a) => a.ID === assgnt.ID)
      )
      assgnt = { ...assgnt, DAILY_REPORT: [{ id: reportId }] }
      Vue.set(state.assignedAssets[assetType], existingIndex, assgnt)
      let refreshedAssetsArray = state.assignedAssets[assetType]
      state.assignedAssets = {
        ...state.assignedAssets,
        [assetType]: refreshedAssetsArray,
      }
    }, // UPDATE_ASSET_ASSIGNMENT

    [REMOVE_ASSET_ASSIGNMENT](state, { assetType, assgntId }) {
      let existingAssignment = state.assignedAssets[assetType].find(
        (a) => a.ID === assgntId
      )
      state.assignedAssets[assetType].splice(
        state.assignedAssets[assetType].indexOf(existingAssignment),
        1
      )
    }, // REMOVE_ASSET_ASSIGNMENT

    //
    // Loading
    //

    [SET_ALL_ASSETS_DONE_LOADING](state) {
      state.areAllAssetsLoading = false
    },
    [SET_ALL_ASSETS_STILL_LOADING](state) {
      state.areAllAssetsLoading = true
    },
    [SET_ASSIGNMENTS_DONE_LOADING](state) {
      state.areAssignmentsLoading = false
    },
    [SET_ASSIGNMENTS_STILL_LOADING](state) {
      state.areAssignmentsLoading = true
    },

    //
    // Selection
    //

    [SELECT_ASSET](state, assetId) {
      state.selectedAssets.push(assetId)
    },
    [DESELECT_ASSET](state, assetId) {
      let index = state.selectedAssets.indexOf(assetId)
      if (index !== -1) {
        state.selectedAssets.splice(index, 1)
      }
    },

    [DESELECT_ALL_ASSETS](state) {
      state.selectedAssets = []
    }, // DESELECT_ALL_ASSETS

    [SELECT_ASSGNT](state, assgntId) {
      state.selectedAssgnts.push(assgntId)
    },
    [DESELECT_ASSGNT](state, assgntId) {
      let index = state.selectedAssgnts.indexOf(assgntId)
      if (index !== -1) {
        state.selectedAssgnts.splice(index, 1)
      }
    },

    [DESELECT_ALL_ASSGNTS](state) {
      state.selectedAssgnts = []
    }, // DESELECT_ALL_ASSGNTS

    //
    // Processing
    //

    [SET_IS_PROCESSING](state, { objArrayName, assetType, assgntIndex }) {
      let assgntsArrayClone = [...state[objArrayName][assetType]]
      assgntsArrayClone[assgntIndex].isProcessing = true
      state[objArrayName] = {
        ...state[objArrayName],
        [assetType]: assgntsArrayClone,
      }
    }, // SET_IS_PROCESSING

    [SET_NOT_PROCESSING](state, { objArrayName, assetType, assgntIndex }) {
      let assgntsArrayClone = [...state[objArrayName][assetType]]
      assgntsArrayClone[assgntIndex].isProcessing = false
      state[objArrayName] = {
        ...state[objArrayName],
        [assetType]: assgntsArrayClone,
      }
    }, // SET_NOT_PROCESSING

    //
    // Hover
    //

    [SET_ASSET_HOVER](state, assetId) {
      state.hoveringAssetId = assetId
    },
    [UNSET_ASSET_HOVER](state) {
      state.hoveringAssetId = null
    },

    //
    // Multi Assgnt
    //

    [SET_MULTI_ASSIGN_PROPS](state, newProps) {
      state.multiAssign = newProps
    }, // SET_MULTI_ASSIGN_PROPS

    [RESET_MULTI_ASSIGN_PROPS](state) {
      state.multiAssign = {
        asset: {},
        assgnts: [],
        existingAssgnt: false,
        targetReport: {},
      }
    }, // RESET_MULTI_ASSIGN_PROPS
  }, // mutations
  actions: {
    // fetch all assets
    async fetchAllAssets({ getters, commit, state }, options) {
      try {
        commit(SET_ALL_ASSETS_STILL_LOADING)
        let { assetType } = options
        let lastFetchTime = state.lastAssetsFetchTime || 0
        let secsSinceLastFetch = (Date.now() - lastFetchTime) / 1000
        if (lastFetchTime && secsSinceLastFetch < 10) {
          return Promise.resolve(getters.getAllAssets(assetType))
        }

        let assetsResponse = await api.getCollection(
          assetType,
          { options },
          (response) => {
            commit(SET_ALL_ASSETS_RESPONSE, { response, assetType })
            commit(SET_ALL_ASSETS, {
              assets: response.records,
              assetType,
            })
          }
        )
        commit(SET_ALL_ASSETS_DONE_LOADING)
        return assetsResponse
      } catch (err) {
        throw new Error(err)
      }
    },

    // fetch Assignments by Date
    async fetchAssignedAssets({ getters, commit }, options) {
      try {
        commit(SET_ASSIGNMENTS_STILL_LOADING)

        let { assetType, date } = options
        // TODO (ES): refactor this logic
        let assignmentType = AssignmentTables[assetType]

        let assignmentDateField = HumanFields[assignmentType].REPORT_DATE
        options.filters = {
          match: 'and',
          rules: [
            {
              field: assignmentDateField,
              operator: 'is',
              value: date,
            },
          ],
        }
        await api.getAssignedAssets(
          assetType,
          { options, date: date },
          (response) => {
            commit(SET_ASSIGNED_ASSETS, {
              assets: response.records,
              assetType,
            })
            commit(SET_ASSIGNED_ASSETS_RESPONSE, { response, assetType })
            commit(SET_ASSIGNMENTS_DONE_LOADING)
          },
          (err) => {
            throw err
          }
        )
      } catch (err) {
        throw new Error(err)
      }
    }, // fetchAssignedAssets

    async fetchAssignment(
      { commit, getters, dispatch },
      { assetType, assignmentId }
    ) {
      try {
        let assgnt = await api.getAssetAssignment(assetType, assignmentId)
        return assgnt
      } catch (error) {
        throw new Error(error)
      }
    }, // fetchAssignment

    async setAssgntCounts(
      { getters, dispatch },
      { numAssgnts, assetType, targetKey }
    ) {
      Object.keys(numAssgnts).map(async (sourceOrDestination) => {
        let sORd = sourceOrDestination
        numAssgnts[sORd][targetKey] = await getters.getReportAssignedAssets({
          assetType,
          reportId: numAssgnts[sORd].id,
        }).length
      })

      // If this is the 'updated' call, we want to check the counts
      // and update the reports accordingly
      if ((await targetKey) === 'updated') {
        await dispatch('checkAssgntCounts', { numAssgnts, assetType })
      }

      return numAssgnts
    }, // setAssgntCounts

    async checkAssgntCounts({ dispatch }, { numAssgnts, assetType }) {
      Object.keys(numAssgnts).map(async (sourceOrDestination) => {
        let sORd = sourceOrDestination
        if (
          numAssgnts[sORd].updated !== numAssgnts[sORd].initial &&
          [numAssgnts[sORd].updated, numAssgnts[sORd].initial].includes(0)
        ) {
          let hasAssgntsFieldKey = `HAS_${AssignmentTables[assetType]}_RECORDS`
          // eslint-disable-next-line
          console.log(
            `Setting ${sORd} Report ${hasAssgntsFieldKey} to ${!!numAssgnts[
              sORd
            ].updated}!`
          )
          try {
            let updatedReport = await dispatch('updateReport', {
              ID: numAssgnts[sORd].id,
              [hasAssgntsFieldKey]: !!numAssgnts[sORd].updated,
            })
            return Promise.resolve(updatedReport)
          } catch (error) {
            return Promise.reject(error)
          }
        }
      })
    }, // checkAssgntCounts

    async tryToReassignAsset(
      { commit, getters, dispatch },
      { assetType, assgnt, reportId }
    ) {
      let sourceReportId = assgnt.DAILY_REPORT[0].id

      // let numAssgnts = {
      //   source: { id: sourceReportId, initial: null, updated: null },
      //   destination: { id: reportId, initial: null, updated: null },
      // }
      // numAssgnts = await dispatch('setAssgntCounts', {
      //   numAssgnts,
      //   assetType,
      //   targetKey: 'initial',
      // })

      if (sourceReportId === reportId) {
        let assetName = getters.getAssetNameFromAssgnt(assetType, assgnt)
        Vue.prototype.$notify({
          type: 'warn',
          title: `${assetName} already assigned to target report`,
        })
        return false
      }
      dispatch('setAssgntProcessing', { assetType, assgntId: assgnt.ID })
      let assetId = getters.getAssetIdFromAssgnt(assetType, assgnt)
      let ghostAssgnt = dispatch('createGhostAssgnt', {
        assetType,
        assetId,
        reportId,
      })
      try {
        let updatedAssignment = await api.updateAssignment(
          assetType,
          assgnt.ID,
          { DAILY_REPORT: reportId }
        )

        await dispatch(
          'updateDefaultShiftItem',
          { assetType, shiftId: assgnt.ID, reportId },
          { root: true }
        )

        // Do this before updating the assignment
        // so the duplicate key warning wont hit
        dispatch('removeGhostAssgnt', {
          assetType,
          assgntId: ghostAssgnt.ID,
        })
        commit(UPDATE_ASSET_ASSIGNMENT, {
          assetType,
          assgnt: updatedAssignment,
          reportId: updatedAssignment.DAILY_REPORT[0].id,
        })
        commit(DESELECT_ASSGNT, updatedAssignment.ID)
        let assetName = getters.getAssetNameFromAssgnt(
          assetType,
          updatedAssignment
        )
        await dispatch('makeRevisionForAssetMod', {
          assetType,
          assetName,
          action: 'added',
          reportId: updatedAssignment.DAILY_REPORT[0].id,
        })
        await dispatch('makeRevisionForAssetMod', {
          assetType,
          assetName,
          action: 'deleted',
          reportId: sourceReportId,
        })
      } catch (error) {
        dispatch('removeGhostAssgnt', {
          assetType,
          assgntId: ghostAssgnt.ID,
        })
        dispatch('setAssgntNotProcessing', {
          assetType,
          assgntId: assgnt.ID,
        })
        return Promise.reject(error)
      }
    }, // tryToReassignAsset

    async tryToAssignAsset(
      { commit, getters, dispatch, rootGetters },
      { assetType, assetId, reportId }
    ) {
      // let numAssgnts = {
      //   destination: { id: reportId, initial: null, updated: null },
      // }
      // numAssgnts = await dispatch('setAssgntCounts', {
      //   numAssgnts,
      //   assetType,
      //   targetKey: 'initial',
      // })

      let assignmentKey = getters.getAssetAssignmentKey(assetType)

      let ghostAssgnt = await dispatch('createGhostAssgnt', {
        assetType,
        assetId,
        reportId,
      })

      // The key on the Asset that will give you its name
      let assetNameKey = `${assignmentKey}_NAME`

      // The key on the Assignment that will store the name
      let assignmentNameFieldKey = `${AssignmentTables[assetType]}_NAME`

      let assetIdentifier = _get(ghostAssgnt, assetNameKey, 'BLANK RECORD')

      try {
        let assgnt = {
          [assignmentNameFieldKey]: assetIdentifier,
          DAILY_REPORT: reportId,
          [assignmentKey]: assetId,
        }
        if (assetType === AssetTypes.EQUIPMENT) {
          let asset = await getters.getAsset(assetType, assetId)
          if (asset.DEFAULT_NOTES.length) {
            assgnt.EQUIPMENT_NOTES = asset.DEFAULT_NOTES
          }
        }
        let newAssignment = await api.createAssignment(assetType, assgnt)

        let newAssignmentEventPayload = {
          assetType,
          [assignmentNameFieldKey]: assetIdentifier,
          assetId,
          reportId,
        }
        let newAssignmentStatus = newAssignment ? 'ok' : 'fail'

        fullstory.event(
          `create_assgnt.${newAssignmentStatus}`,
          newAssignmentEventPayload
        )

        let defaultShiftItemEventPayload = {
          assetType,
          shiftId: newAssignment.ID,
          reportId,
        }
        let defaultShiftItemRes = await dispatch(
          'addDefaultShiftItem',
          { assetType, shiftId: newAssignment.ID, reportId },
          { root: true }
        )

        let defaultShiftItemStatus = defaultShiftItemRes ? 'ok' : 'fail'
        fullstory.event(
          `create_default_shift_item.${defaultShiftItemStatus}`,
          defaultShiftItemEventPayload
        )

        // Do this before updating the assignment
        // so the duplicate key warning wont hit
        await dispatch('removeGhostAssgnt', {
          assetType,
          assgntId: ghostAssgnt.ID,
        })
        commit(ADD_ASSET_ASSIGNMENT, {
          assetType,
          assgnt: newAssignment,
          reportId: newAssignment.DAILY_REPORT[0].id,
        })
        commit(DESELECT_ASSET, assetId)
        let assetName = getters.getAssetNameFromAssgnt(assetType, newAssignment)
        await dispatch('makeRevisionForAssetMod', {
          assetType,
          assetName,
          action: 'added',
          reportId: newAssignment.DAILY_REPORT[0].id,
        })
      } catch (error) {
        dispatch('removeGhostAssgnt', {
          assetType,
          assgntId: ghostAssgnt.ID,
        })
        return Promise.reject(error)
      }
    }, // tryToAssignAsset

    async tryToUnassignAsset(
      { commit, dispatch, getters },
      { assetType, assgntId }
    ) {
      let assgnt = await getters.getAssgnt(assetType, assgntId)
      let assetName = getters.getAssetNameFromAssgnt(assetType, assgnt)
      // let numAssgnts = {
      //   source: { id: assgnt.DAILY_REPORT[0].id, initial: null, updated: null },
      // }
      // numAssgnts = await dispatch('setAssgntCounts', {
      //   numAssgnts,
      //   assetType,
      //   targetKey: 'initial',
      // })

      dispatch('setAssgntProcessing', { assetType, assgntId })
      try {
        let deleteRes = await api.deleteAssignment(assetType, assgntId)
        if (deleteRes.delete === true) {
          commit(REMOVE_ASSET_ASSIGNMENT, { assetType, assgntId })
          commit(DESELECT_ASSGNT, assgntId)

          await dispatch('makeRevisionForAssetMod', {
            assetType,
            assetName,
            action: 'deleted',
            reportId: assgnt.DAILY_REPORT[0].id,
          })

          return true
        } else {
          throw Error({
            deleteRes,
            msg: 'Asset could not be unassigned',
          })
        }
      } catch (error) {
        dispatch('setAssgntNotProcessing', { assetType, assgntId })
        return Promise.reject(error)
      }
    }, // tryToUnassignAsset

    createGhostAssgnt({ commit, getters }, { assetType, assetId, reportId }) {
      let ghostAssgnt = getters.makeGhostAssgnt(assetType, assetId, reportId)
      commit(ADD_ASSET_ASSIGNMENT, {
        assetType,
        assgnt: ghostAssgnt,
        reportId,
      })
      return ghostAssgnt
    }, // createGhostAssgnt

    removeGhostAssgnt({ commit }, { assetType, assgntId }) {
      commit(REMOVE_ASSET_ASSIGNMENT, {
        assetType,
        assgntId,
      })
    }, // createGhostAssgnt

    selectAssgnt({ commit }, assgntId) {
      commit(SELECT_ASSGNT, assgntId)
    }, // selectAssgnt

    deselectAssgnt({ commit }, assgntId) {
      commit(DESELECT_ASSGNT, assgntId)
    }, // deselectAssgnt

    deselectAllAssgnts({ commit }) {
      commit(DESELECT_ALL_ASSGNTS)
    }, // deselectAllAssgnts

    selectAsset({ commit }, assetId) {
      commit(SELECT_ASSET, assetId)
    }, // selectAsset

    deselectAsset({ commit }, assetId) {
      commit(DESELECT_ASSET, assetId)
    }, // deselectAsset

    deselectAllAssets({ commit }) {
      commit(DESELECT_ALL_ASSETS)
    }, // deselectAllAssets

    deselectAllAssgntsAndAssets({ commit }) {
      commit(DESELECT_ALL_ASSGNTS)
      commit(DESELECT_ALL_ASSETS)
    }, // deselectAllAssgntsAndAssets

    setAssetHover({ commit }, assetId) {
      commit(SET_ASSET_HOVER, assetId)
    }, // setAssetHover

    unsetAssetHover({ commit }) {
      commit(UNSET_ASSET_HOVER)
    }, // unsetAssetHover

    setAssgntProcessing({ commit, getters }, { assetType, assgntId }) {
      let assgntIndex = getters.getIndexOfAssignment(assetType, assgntId)
      commit(SET_IS_PROCESSING, {
        objArrayName: 'assignedAssets',
        assetType,
        assgntIndex,
      })
    }, // setAssgntProcessing

    setAssgntNotProcessing({ commit, getters }, { assetType, assgntId }) {
      let assgntIndex = getters.getIndexOfAssignment(assetType, assgntId)
      commit(SET_NOT_PROCESSING, {
        objArrayName: 'assignedAssets',
        assetType,
        assgntIndex,
      })
    }, // setAssgntNotProcessing

    setMultiAssgnProps(
      { commit, getters },
      { assetId, assetType, assgnts, existingAssgnt, targetReport }
    ) {
      let asset = getters.getAsset(assetType, assetId)
      commit(SET_MULTI_ASSIGN_PROPS, {
        asset,
        assgnts,
        existingAssgnt,
        targetReport,
      })
    }, // setMultiAssgnProps

    resetMultiAssgnProps({ commit }) {
      commit(RESET_MULTI_ASSIGN_PROPS)
    }, // resetMultiAssgnProps

    async clearLaborerSMSStatus({ commit }, { shiftId }) {
      try {
        let updatedAssignment = await api.updateAssignment(
          AssetTypes.LABORER,
          shiftId,
          { ASSIGNMENT_SMS_STATUS: null }
        )
        commit(UPDATE_ASSET_ASSIGNMENT, {
          assetType: AssetTypes.LABORER,
          assgnt: updatedAssignment,
          reportId: updatedAssignment.DAILY_REPORT[0].id,
        })
      } catch (error) {
        console.log(error)
      }
    }, // clearLaborerSMSStatus
  }, // actions

  getters: {
    getAsset: (state, getters) => (assetType, assetId) => {
      if (state.areAllAssetsLoading) {
        return {}
      }
      return state.allAssets[assetType].find((asset) => asset.ID === assetId)
    },

    getAssgnt: (state, getters) => (assetType, assgntId) => {
      if (state.areAssignmentsLoading) {
        return {}
      }
      return getters
        .getAssignedAssets(assetType)
        .find((assgnt) => assgnt.ID === assgntId)
    },

    getAllAssetsResponses: (state) => (assetType) =>
      state.allAssetsResponses[assetType] || [],

    getAssignedAssetsResponses: (state) => (assetType) =>
      state.assignedAssetsResponses[assetType] || [],

    getDispatcherReportAssets: (state, getters) => ({
      assetType,
      reportId,
    }) => {
      let assets = getters.getAssignedAssets(assetType) || []
      if (assets.length) {
        return assets.filter(
          (a) => a.DAILY_REPORT[0] && a.DAILY_REPORT[0].id === reportId
        )
      }
      return assets
    },

    getAssignedAssets: (state) => (assetType) =>
      state.assignedAssets[assetType] || [],

    getReportAssignedAssets: (state) => ({ reportId, assetType }) => {
      let reportAssets = state.assignedAssets[assetType].filter(
        (a) => a.DAILY_REPORT[0].id === reportId
      )
      return reportAssets || []
    },

    getIsAssetAssigned: (state, getters) => (assetType, assetId) => {
      if (state.areAssignmentsLoading) {
        return false
      }
      return getters.getAssetAssignments(assetType, assetId).length
    },

    getSelectedAssgnts: (state) => {
      return state.selectedAssgnts
    },

    getAssetAssignments: (state, getters) => (assetType, assetId) => {
      if (state.areAssignmentsLoading) {
        return false
      }
      let assignedAssets = getters.getAssignedAssets(assetType)
      let assignmentKey = getters.getAssetAssignmentKey(assetType)
      let assignments = assignedAssets.filter((a) =>
        a[assignmentKey].length
          ? a[assignmentKey][0].id === assetId && !a.isGhost
          : false
      )
      return assignments
    },

    getAssignmentsGroupedByAsset: (state, getters) => (
      assetType,
      assetsToGroup = null
    ) => {
      if (state.areAssignmentsLoading) {
        return false
      }
      assetsToGroup = assetsToGroup || getters.getAssignedAssets(assetType)
      let assignmentKey = getters.getAssetAssignmentKey(assetType)
      return _groupBy(assetsToGroup, (asset) =>
        _get(asset, `${assignmentKey}[0].id`)
      )
    }, // getAssignmentsGroupedByAsset

    numSelectedAssetsAndAssgnts: (state, getters) => {
      return (
        getters.getSelectedAssets.length + getters.getSelectedAssgnts.length
      )
    }, // numSelectedAssetsAndAssgnts

    getAreAssgntsOrAssetsSelected: (state, getters) => {
      return getters.numSelectedAssetsAndAssgnts > 0
    }, // getAreAssgntsOrAssetsSelected

    getAllAssets: (state) => (assetType) => state.allAssets[assetType] || [],

    getAvailableAssets: (state, getters) => (assetType) => {
      let assignmentKey = getters.getAssetAssignmentKey(assetType)
      let assignedAssets = getters.getAssignedAssets(assetType) || []
      let assignedIds = assignedAssets.map((a) =>
        a[assignmentKey][0] ? a[assignmentKey][0].id : false
      )
      let allAssets = getters.getAllAssets(assetType) || []
      let diff = allAssets.filter((a) => !assignedIds.includes(a.ID))
      return diff
    },

    getAssetsForDefaultForeman: (state, getters) => (assetType, foremanId) => {
      let allAssets = getters.getAllAssets(assetType) || []
      return allAssets.filter((asset) => {
        if (asset.DEFAULT_FOREMAN.length) {
          return asset.DEFAULT_FOREMAN[0].id === foremanId
        }
        return false
      })
    }, // getAssetsForDefaultForeman

    getSelectedAssets: (state) => {
      return state.selectedAssets
    },

    getIsAssetSelected: (state) => (assetId) => {
      return state.selectedAssets.includes(assetId)
    },

    getIsAssgntSelected: (state) => (assgntId) => {
      return state.selectedAssgnts.includes(assgntId)
    },

    getAssetAssignmentKey: (state) => (assetType) =>
      _get(AssetObjectNames, assetType),

    getAssetIdFromAssgnt: (state, getters) => (assetType, assgnt) => {
      let assgntKey = getters.getAssetAssignmentKey(assetType)
      return _get(assgnt, `[${assgntKey}][0].id`)
    }, // getAssetIdFromAssgnt

    getAssetNameFromAssgnt: (state, getters) => (assetType, assgnt) => {
      let assgntKey = getters.getAssetAssignmentKey(assetType)
      return _get(assgnt, `[${assgntKey}][0].identifier`, 'BLANK RECORD')
    }, // getAssetNameFromAssgnt

    getHoveringAssetId: (state) => state.hoveringAssetId,

    makeGhostAssgnt: (state, getters) => (assetType, assetId) => {
      let assignmentKey = getters.getAssetAssignmentKey(assetType)
      let asset = getters.getAsset(assetType, assetId)
      return {
        ...asset,
        ID: nanoid(),
        [assignmentKey]: [{ id: assetId }],
        isGhost: true,
      }
    }, // makeGhostAssgnt

    getIndexOfAssignment: (state) => (assetType, assgntId) => {
      let existingIndex = state.assignedAssets[assetType].indexOf(
        state.assignedAssets[assetType].find((a) => a.ID === assgntId)
      )
      return existingIndex
    },

    getMultiAssgnObj: (state) => state.multiAssign, // getMultiAssgnObj
  },
}
