<template>
  <transition name="fade">
    <div v-if="visible && isPrintModeOn" class="wci-textarea print-mode">
      <div class="hint">{{ $attrs.label || '' }}</div>
      <span>{{ $attrs.value || '' }}</span>
    </div>
    <VTextarea
      v-else-if="visible && !isPrintModeOn"
      :class="[
        'wci-textarea',
        { 'conditional-field': conditionalField },
        { visible: visible },
      ]"
      v-bind="allBindings"
      auto-grow
      :outline="
        typeof allBindings.outline !== 'undefined' ? allBindings.outline : true
      "
      rows="2"
      v-on="$listeners"
    >
    </VTextarea>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

import VTextarea from '@vuetify/VTextarea'

export default {
  name: 'Textarea',
  components: { VTextarea },
  props: {},
  data() {
    return {
      // TODO (ES) : Make props or computed or smt
      visible: true,
      conditionalField: false,
    }
  },
  computed: {
    ...mapGetters(['isPrintModeOn']),

    allBindings() {
      // Need to proxify both props and attrs, for example for showLabels
      return { ...this.$props, ...this.$attrs }
    },
  },
}
</script>

<style lang="scss">
.wci-textarea.print-mode {
  text-align: left;
  white-space: break-spaces;
  .hint {
    min-width: 80px;
    padding-left: 0;
    margin: 8px 0;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.54);
  }
}

@media print {
  .wci-textarea.v-text-field--outline .v-input__control {
    .v-input__slot {
      padding-right: 0;
      padding-left: 0 !important;
      margin: 0 !important;
      border: none !important;
      textarea {
        font-size: 0.9em;
      }
    }
    .v-text-field__details {
      display: none;
    }
  }
}
</style>
