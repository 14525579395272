import {
  CLEAR_REPORT_SEARCH_TERM,
  SET_REPORT_SEARCH_TERM,
  CLEAR_REPORT_FOREMAN_FILTER,
  SET_REPORT_FOREMAN_FILTER,
  TOGGLE_SMS_STATUS_VISIBILITY,
} from '@constants/mutations'

export default {
  state: {
    reportFilterSearchTerm: false,
    reportForemanFilter: false,
    showSMSstatuses: false,
  },
  mutations: {
    [CLEAR_REPORT_SEARCH_TERM](state) {
      state.reportFilterSearchTerm = false
    }, // CLEAR_REPORT_SEARCH_TERM
    [SET_REPORT_SEARCH_TERM](state, term) {
      state.reportFilterSearchTerm = term
    }, // SET_REPORT_SEARCH_TERM
    [CLEAR_REPORT_FOREMAN_FILTER](state) {
      state.reportForemanFilter = false
    }, // CLEAR_REPORT_FOREMAN_FILTER
    [SET_REPORT_FOREMAN_FILTER](state, foreman) {
      state.reportForemanFilter = foreman
    }, // SET_REPORT_FOREMAN_FILTER
    [TOGGLE_SMS_STATUS_VISIBILITY](state, foreman) {
      state.showSMSstatuses = !state.showSMSstatuses
    }, // TOGGLE_SMS_STATUS_VISIBILITY
  },
  getters: {
    getReportSearchTerm: (state) => state.reportFilterSearchTerm || false,
    getForemanFilter: (state) => state.reportForemanFilter || false,
    getIsSMSStatusVisible: (state) => state.showSMSstatuses || false,
  },
  actions: {
    updateReportSearchTerm({ commit }, term) {
      commit(SET_REPORT_SEARCH_TERM, term)
    }, // updateReportSearchTerm

    clearReportSearchTerm({ commit }) {
      commit(CLEAR_REPORT_SEARCH_TERM)
    }, // clearReportSearchTerm

    updateForemanFilter({ commit }, foreman) {
      commit(SET_REPORT_FOREMAN_FILTER, foreman)
    }, // updateForemanFilter

    clearForemanFilter({ commit }) {
      commit(CLEAR_REPORT_FOREMAN_FILTER)
    }, // clearForemanFilter

    toggleSMSstatusVisibility({ commit }) {
      commit(TOGGLE_SMS_STATUS_VISIBILITY)
    }, // toggleSMSstatusVisibility
  },
}
