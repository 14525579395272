<template>
  <BasePrintableField v-if="isPrintModeOn" :value="value" />
  <VTooltip v-else v-model="showTooltipError" top>
    <template v-slot:activator="{ on }">
      <VSelect
        ref="input"
        v-bind="allBindings"
        :class="[classes, 'wci-select']"
        :value="value"
        :label="title"
        :items="items"
        :hide-details="!isSmOrLess || !allBindings.hint"
        :persistent-hint="isSmOrLess"
        item-value="ID"
        solo
        flat
        @mouseover.native="showErrors = true"
        @mouseout.native="showErrors = false"
        v-on="$listeners"
        @mousedown="$emit('picking')"
        @blur.self="$emit('donePicking')"
      />
    </template>
    <span>{{ errorText }}</span>
  </VTooltip>
</template>

<script>
import VSelect from '@vuetify/VSelect'
import VTooltip from '@vuetify/VTooltip'
import { mapGetters } from 'vuex'

export default {
  name: 'Select',
  components: { VSelect, VTooltip },
  props: {
    ...VSelect.$props,
    title: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    classes: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMounted: false,
      showErrors: false,
    }
  },
  computed: {
    ...mapGetters(['isPrintModeOn']),
    allBindings() {
      // Need to proxify both props and attrs, for example for showLabels
      return { dense: true, ...this.$props, ...this.$attrs }
    },
    errorBucket() {
      return this.hasErrors ? this.$refs.input.errorBucket : []
    }, // errorBucket
    hasErrors() {
      return !this.isMounted ? false : !!this.$refs.input.errorBucket.length
    }, // hasErrors
    errorText() {
      return this.hasErrors ? this.errorBucket[0] : ''
    }, // errorText
    isSmOrLess() {
      return ['sm', 'xs'].includes(this.$mq)
    }, // isSmOrLess
    showTooltipError() {
      return this.hasErrors && this.showErrors && !this.isSmOrLess
    }, // showTooltipError
  },
  mounted() {
    this.isMounted = true
  },
}
</script>

<style scoped>
@media print {
  .wci-select >>> .v-text-field__details {
    display: none;
  }
}
</style>
