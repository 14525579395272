import {
  SET_API_OFFLINE,
  SET_API_ONLINE,
  SET_KNACK_DID_TIMEOUT,
} from '@constants/mutations'

export default {
  state: {
    isApiOnline: true,
    didKnackTimeout: false,
    numSecondsForReload: 5,
  },
  mutations: {
    [SET_API_OFFLINE](state) {
      state.isApiOnline = false
    },
    [SET_API_ONLINE](state) {
      state.isApiOnline = true
    },
    [SET_KNACK_DID_TIMEOUT](state) {
      state.didKnackTimeout = true
    },
  },
  actions: {
    setKnackDidTimeout({ commit }) {
      commit(SET_KNACK_DID_TIMEOUT)
    },
    setApiOffline({ commit, state }) {
      commit(SET_API_OFFLINE)
    },
  },
  getters: {
    getIsApiOnline: (state) => state.isApiOnline,
    getDidKnackTimeout: (state) => state.didKnackTimeout,
  },
}
