import { SET_PROGRESS_TOTAL, SET_PROGRESS_COUNTER } from '@constants/mutations'

export default {
  state: {
    total: 0,
    counter: 0,
  },
  mutations: {
    [SET_PROGRESS_TOTAL](state, payload) {
      state.total = payload
    },
    [SET_PROGRESS_COUNTER](state, payload) {
      state.counter = payload
    },
  },
  actions: {
    setProgressTotal({ commit }, payload) {
      commit(SET_PROGRESS_TOTAL, payload)
    },
    setProgressCounter({ commit }, payload) {
      commit(SET_PROGRESS_COUNTER, payload)
    },
  },
  getters: {
    getProgressCounter: (state) => state.counter,
    getProgressTotal: (state) => state.total,
  },
}
