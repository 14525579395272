import { knackReadableFields } from './data/knackReadableFields.js'
import { humanReadableFields } from './data/humanReadableFields.js'
import { contentTypeFields } from './data/contentTypeFields.js'

// will remove these below
export const ReportFields = Object.freeze({
  REPORT_DATE: 'field_244',
})

export const ReportFieldLabel = Object.freeze({
  REPORT_DATER: 'date',
})

// TODO (ES) : Whats the scalability of these?
// What's the approach moving forward?
export const ReportStatuses = Object.freeze({
  COMPLETED: 'Completed',
  REVISED: 'Revised',
  LOCKED: 'Locked',
  SETUP: 'Setup',
  SCHEDULED: 'Scheduled',
})

export const LocationStatuses = Object.freeze({
  CANCELLED: 'Cancelled',
  COMPLETE: 'Complete',
  HOLD: 'Hold',
  INVOICED: 'Invoiced',
  PAYMENT_RECIEVED: 'Payment Recieved',
  PERMIT_APPROVED_BUT_NOT_PAID_YET: 'Permit Approved But Not Paid Yet',
  PERMIT_EXPIRED: 'Permit Expired',
  PERMIT_FILED_WAITING_FOR_APPROVAL: 'Permit Filed Waiting For Approval',
  PERMIT_NOT_FILED_YET: 'Permit Not Filed Yet',
  READY_TO_WORK: 'Ready To Work',
  WAITING_FOR_CODE_53_CHECK: 'Waiting For Code 53 Check',
  WAITING_FOR_NTP: 'Waiting For NTP',
  WORK_SCHEDULED: 'Work Scheduled',
  SUB_WORK_ONLY: 'Sub Work Only',
  REPORTED_AS_COMPLETE_BY_FOREMAN: 'Reported As Complete By Foreman',
})

export const JobTypes = Object.freeze({
  // Fiber
  FIBER: 'Fiber',
  MICRO: 'Micro',
  MICRO_REPAIR: 'Micro Repair',
  POWER_DIG: 'Power Dig',
  EMERGENCY: 'Emergency',
  // Civil
  CIVIL_WORK: 'Civil Work',
  NYSDOT: 'NYSDOT',
})

export const FiberJobTypes = [
  JobTypes.FIBER,
  JobTypes.MICRO,
  JobTypes.MICRO_REPAIR,
  JobTypes.POWER_DIG,
  JobTypes.EMERGENCY,
]

export const JobStatuses = Object.freeze({
  TRACKING: 'Tracking',
  NO_BID_SUBMITTED: 'No Bid Submitted',
  ESTIMATING: 'Estimating',
  BID_SUBMITTED: 'Bid Submitted',
  BID_LOST: 'Bid Lost',
  CONTRACT_AWARDED: 'Contract Awarded',
  COMPLETED: 'Completed',
})

export const smsStatuses = Object.freeze({
  CONFIRMED: 'Confirmed',
  NO_REPLY: 'NoReply',
  NON_CONFIRMED: 'NonConfirmed',
  SEND_FAILURE: 'SendFailure',
  UNSENT: 'Unsent',
  SEND_SUCCESS: 'SendSuccess',
})

export const LaborerLocations = [
  'On-site',
  'WCI Yard (Provost Ave)',
  'Nico Yard Brooklyn',
  'Disano Lot-Queens',
  'Rockaway Job Yard',
  'Disano Lot - Queens',
  'State Job - See notes for Details',
  'Rental Company - see notes for details',
]

export const ShiftTypes = Object.freeze({
  WEEKEND: 'Sat/Sun',
  WEEKDAY: 'M-F',
  NIGHTS: 'Nights',
})

export const LaborerTypes = Object.freeze({
  FOREMAN: 'Foreman',
  LABORER: 'Laborer',
  OPERATOR: 'Operator',
})

export const AssignmentTables = Object.freeze({
  laborer: 'LABOR_ASSIGNMENT',
  equipment: 'EQUIPMENT_USAGE',
  materials: 'MATERIAL_USAGE',
  paywork_item: 'COMPLETED_WORK_QUANTITY',
  requisition_items: 'REQUISITION_ITEMS',
})

export const AssetObjectNames = Object.freeze({
  laborer: 'LABORER',
  equipment: 'EQUIPMENT',
  materials: 'MATERIALPART',
  paywork_item: 'PAYWORK_ITEM',
  requisition_items: 'REQUISITION_ITEMS',
  phases: 'PHASES',
  cost_codes: 'COST_CODES',
  shift_items: 'SHIFT_ITEMS',
})

export const AssetNameFields = Object.freeze({
  laborer: 'LABORER_NAME',
  equipment: 'EQUIPMENT_NAME',
  materials: 'MATERIALPART_NAME',
  paywork_item: 'PAYWORK_ITEM_NAME',
})

export const AssetTypes = Object.freeze({
  LABORER: 'laborer',
  EQUIPMENT: 'equipment',
  MATERIALS: 'materials',
  PAYWORK_ITEM: 'paywork_item',
  PHASES: 'phases',
  COST_CODES: 'cost_codes',
  SHIFT_ITEMS: 'shift_items',
  JOB_COST_CODES: 'job_cost_codes',
  JOB_PHASES: 'job_phases',
  REQUISITION_ITEMS: 'requisition_items',
})

export const AssetTypeIcons = Object.freeze({
  [AssetTypes.LABORER]: 'account_circle',
  [AssetTypes.EQUIPMENT]: 'settings',
})

export const AssignableAssetTypes = [
  AssetTypes.LABORER,
  AssetTypes.EQUIPMENT,
  AssetTypes.MATERIALS,
  AssetTypes.PAYWORK_ITEM,
  AssetTypes.REQUISITION_ITEMS,
]

export const HumanReadableAssetTypes = Object.freeze({
  LABORER: 'Laborers',
  EQUIPMENT: 'Equipment',
  MATERIALS: 'Materials',
  PAYWORK_ITEM: 'Pay/Work Items',
  PHASES: 'Phases',
  COST_CODES: 'Cost Codes',
  SHIFT_ITEMS: 'Shift Items',
  JOB_COST_CODES: 'Job Cost Codes',
  JOB_PHASES: 'Job Phases',
  REQUISITION_ITEMS: 'Requisition Items',
})

export const ObjectTypes = Object.freeze({
  DAILY_REPORT: 'daily_report',
  JOBS: 'jobs',
  LOCATIONS: 'locations',
  PHASES: 'phases',
  COST_CODES: 'cost_codes',
  SHIFT_ITEMS: 'shift_items',
  JOB_COST_CODES: 'job_cost_codes',
  JOB_PHASES: 'job_phases',
  REQUISITION_ITEMS: 'requisition_items',
})

export const FilterKeys = Object.freeze({
  FOREMAN: 'filterByForeman',
  REPORT_STATUS: 'filterByReportStatus',
})
/**
 * Using the API "view_mode" to get records from a
 * Knack Scene + View instead of retrieving all fields. This allows
 * a lookup when e.g. "type :'daily_report'" is passed to the Lambda Server.
 *
 * Note it is also structured by the different process.env.VUE_APP_NODE_ENV options
 * so the Dev DB and production DB can have different Scene/Views.
 */
export const SceneViewLookup = Object.freeze({
  development: {
    locations: { scene: 'scene_360', view: 'view_574' },
    daily_report: { scene: 'scene_360', view: 'view_576' },
    dispatcher_reports: { scene: 'scene_360', view: 'view_799' },
  },
  production: {
    locations: { scene: 'scene_360', view: 'view_574' },
    daily_report: { scene: 'scene_360', view: 'view_576' },
    dispatcher_reports: { scene: 'scene_360', view: 'view_851' },
  },
})

// example of creating constant object using generated fields (stored in ./data folder).
// the ./data folder is only accessed from this file. all other files can reference the constants object
// export const JOBS = Object.freeze(knackReadableFields.JOBS)
// export const LOCATIONS = Object.freeze(knackReadableFields.LOCATIONS)
// ...etc
export const ContentTypes = Object.freeze(contentTypeFields)
export const HumanFields = Object.freeze(humanReadableFields)
export const KnackFields = Object.freeze(knackReadableFields)
export const { JOBS, LOCATIONS, DAILY_REPORT, LABORER } = HumanFields
