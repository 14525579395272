<template>
  <VFlex class="display-only">
    <span v-if="hint.length" class="hint">{{ hint }}</span>
    <span>{{ value }}</span>
  </VFlex>
</template>

<script>
export default {
  name: 'DisplayOnlyField',
  props: {
    value: {
      type: [String, Number],
      default: '-',
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
@import '@design';
.display-only {
  // styles copied mostly from .v-messages__message
  margin-bottom: 0.75em;

  @media #{$mobile} {
    margin-top: 4px;
  }
  .hint {
    display: inline-block;
    width: 80px;
    padding-left: 0;
    margin-bottom: 4px;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.54);
  }
}
</style>
