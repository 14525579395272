<template>
  <VDialog value="show" :max-width="400" persistent>
    <VCard class="text-xs-center">
      <VCardTitle class="red accent-2 white--text">
        <VFlex xs12 mb-2
          ><h2>
            Westmoreland API Offline
          </h2></VFlex
        >
      </VCardTitle>
      <VCardText class="font-weight-medium pa-3">
        <p class="px-5"
          >The Westmoreland API could not be found. If this problem persists,
          please contact tech support.</p
        >
        <BaseButton color="blue" outline @click="reload"
          >Click to Reload</BaseButton
        >
        <code class="grey--text white elevation-0 mt-4"
          >Netlify Lambda Proxy Error: Code 516. Check
          /src/services/lambdaApi.js for more information.</code
        >
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
// import { mapState } from 'vuex'
import VDialog from '@vuetify/VDialog'
import { VCardText } from '@vuetify/VCard'

export default {
  name: 'ApiOfflineModal',
  components: {
    VDialog,
    VCardText,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    reload() {
      window.location.reload()
    }, // reload
  },
}
</script>
