<template>
  <div class="base-field">
    <slot>Base Field</slot>
  </div>
</template>

<script>
export default {

  name: 'Field',

  data () {
    return {

    }
  }
}
</script>
