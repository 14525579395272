<template>
  <VApp>
    <ApiOfflineModal v-if="!getIsApiOnline" :show="!getIsApiOnline" />
    <template v-else>
      <RouterView :key="$route.fullPath" />
      <!-- eslint-disable-next-line -->
      <notifications position="top center" />
      <!-- eslint-disable-next-line -->
      <notifications position="top center" group="progress" />
      <KnackErrorModal v-if="getDidKnackTimeout" :show="getDidKnackTimeout" />
    </template>
  </VApp>
</template>

<script>
import * as appConfig from '@src/app.config'
import { mapGetters } from 'vuex'
import KnackErrorModal from '@modals/KnackErrorModal'
import ApiOfflineModal from '@modals/ApiOfflineModal'
import fullstory from '@utils/fullstory'
import * as Sentry from '@sentry/vue'

export default {
  metaInfo: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title || appConfig.title
    },
  },
  components: { KnackErrorModal, ApiOfflineModal },
  computed: {
    ...mapGetters([
      'getIsApiOnline',
      'getDidKnackTimeout',
      'getUserDisplayName',
      'getUserEmail',
      'getUserOktaId',
    ]),
  },
  mounted() {
    // Setup FullStory user tracking
    if (
      typeof window.FS !== 'undefined' &&
      typeof this.getUserOktaId !== 'undefined'
    ) {
      window.FS.identify(this.getUserOktaId, {
        displayName: this.getUserDisplayName,
        email: this.getUserEmail,
      })
    }
    if (
      typeof Sentry !== 'undefined' &&
      typeof this.getUserEmail !== 'undefined'
    ) {
      Sentry.setUser({
        username: this.getUserDisplayName,
        email: this.getUserEmail,
      })
    }
  },
  created() {
    fullstory.init()
  },
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-enter-active {
  transition-delay: 0.6s;
}

.fade-fast-enter-active,
.fade-fast-leave-active {
  transition: opacity 0.2s;
}
.fade-fast-enter,
.fade-fast-leave-active {
  opacity: 0;
}
.fade-fast-enter-active {
  // transition-delay: 0.3s;
}
</style>
