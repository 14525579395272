import Vue from 'vue'
import router from '@router/index'
import * as Sentry from '@sentry/vue'
import { SENTRY_DSN, SENTRY_PROJECT_ID } from '@constants/credentials'
import { FORCE_SENTRY } from '../constants/appConfig'
import { version } from '@/package.json'

const isProduction = process.env.NODE_ENV === 'production'

if (isProduction || FORCE_SENTRY) {
  Sentry.init({
    release: 'wci-knack-v2@' + version,
    Vue,
    dsn: 'https://' + SENTRY_DSN + '.ingest.sentry.io/' + SENTRY_PROJECT_ID,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    environment: isProduction ? 'production' : 'development',
    // tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // tracesSampleRate: 0.5,
    tracesSampleRate: isProduction ? 0.25 : 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [/^Function statements require a function name$/],
  })
}
