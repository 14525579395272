<template>
  <VSpinner
    :class="['spinner--wrap', { 'no-custom-margin': !margin }]"
    :message="message"
    :font-size="fontSize"
    :spacing="computedSpacing"
    :text-fg-color="textFgColor"
    v-bind="{ ...$props, ...$attrs }"
    :style="{ ...marginObj }"
  >
  </VSpinner>
</template>

<script>
import VSpinner from 'vue-simple-spinner'

export default {
  name: 'BaseSpinner',
  components: { VSpinner },
  props: {
    ...VSpinner.props,
    message: {
      type: String,
      default: '',
    },
    fontSize: {
      type: Number,
      default: 18,
    },
    textFgColor: {
      type: String,
      default: '#ffffff',
    },
    margin: {
      type: [String, Number],
      required: false,
      default: '30',
    },
  },
  computed: {
    computedSpacing() {
      return this.fontSize ? this.fontSize * 1.3 : 15
    },
    marginObj() {
      return {
        marginTop: `${this.margin}px`,
        marginBottom: `${this.margin}px`,
      }
    }, // marginObj
  },
}
</script>

<style lang="scss" scoped>
.spinner--wrap {
  height: 100%;
  &.no-custom-margin {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.vue-simple-spinner-text {
  color: white;
}
</style>
