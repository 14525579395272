import '@design'

import Vue from 'vue'
import App from './App.vue'
import { sync } from 'vuex-router-sync'
import router from '@router'
import store from '@store' // done. Returns an unsync callback fn
import '@plugins'

import '@utils/registerServiceWorker'
import '@utils/registerSharedComponents'

import Notifications from 'vue-notification'
import vueScrollto from 'vue-scrollto'

import VueMq from 'vue-mq'

// import Vuetify from 'vuetify'
// index.js or main.js
import 'vuetify/dist/vuetify.min.css'
// Vue.use(Vuetify)
// TODO (ES) : setup stylus
// import '@vuetifyStylus'

import Vuetify, {
  VApp, // required
  VNavigationDrawer,
  VToolbar,
  VToolbarTitle,
  VFadeTransition,
  VIcon,
  VSpacer,
  VForm,
  VContainer,
  VLayout,
  VCard,
  VCardTitle,
  VFlex,
  VContent,
  VMenu,
  VList,
  VListTile,
  VListTileTitle,
  VListTileSubTitle,
  VListTileContent,
  VListTileAction,
  VDivider,
  VSelect,
  VProgressCircular,
} from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'
import { ObserveVisibility } from 'vue-observe-visibility'

Vue.directive('observe-visibility', ObserveVisibility)

Vue.use(Vuetify, {
  components: {
    VApp,
    VNavigationDrawer,
    VToolbar,
    VToolbarTitle,
    VFadeTransition,
    VIcon,
    VSpacer,
    VForm,
    VContainer,
    VLayout,
    VCard,
    VCardTitle,
    VFlex,
    VContent,
    VMenu,
    VList,
    VListTile,
    VListTileTitle,
    VListTileSubTitle,
    VListTileContent,
    VListTileAction,
    VDivider,
    VSelect,
    VProgressCircular,
  },
  directives: {
    Ripple,
  },
})

// unsync is optional in case you need to teardown the app
// eslint-disable-next-line
const unsync = sync(store, router)

Vue.config.productionTip = false

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    sm: 624,
    md: 960,
    ml: 1100,
    lg: 1400,
    xl: Infinity,
  },
  defaultBreakpoint: 'sm', // customize this for SSR
})

Vue.use(Notifications)
Vue.use(vueScrollto)

let vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

// Expose vm to the window if we're not in prod mode
if (process.env.NODE_ENV !== 'production') {
  window.vm = vm
}
