<template>
  <div class="breadcrumb--wrap">
    <VLayout justify-center class="breadcrumbs">
      <RouterLink
        v-for="(crumb, i) in breadcrumbs"
        :key="`${crumb.link || 'no-link'}-${i}`"
        :to="crumb.link || ''"
        class="breadcrumb flex shrink"
        >{{ crumb.title }}</RouterLink
      >
    </VLayout>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
$font: 18px;
.breadcrumb--wrap {
  padding: 0.5rem 0;
  margin: 0;
  background-color: darken($gray-dark, 10);
}

.breadcrumbs {
  .breadcrumb {
    font-size: $font;
    color: rgba(white, 0.7);
    &:last-child {
      color: white;
    }
    &:not(:first-child)::before {
      display: inline-block;
      margin: 0 10px 0 8px;
      font-family: 'Material Icons', sans-serif;
      font-size: $font;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      color: hsla(0, 0%, 100%, 0.7);
      vertical-align: top;
      content: '\E5CC';
    }

    @media #{$mobile} {
      $font: 14px;

      font-size: $font;
      &::before {
        font-size: $font;
      }
    }
  }
}
</style>
