<template>
  <VDialog
    :value="show"
    :max-width="1440"
    class="no-print"
    @input="$emit('close')"
  >
    <JobAssetManager v-if="show" :job="job">
      <template #actions>
        <BaseButton color="orange" outline small @click="$emit('close')"
          >Close</BaseButton
        >
      </template>
    </JobAssetManager>
  </VDialog>
</template>

<script>
import VDialog from '@vuetify/VDialog'
import JobAssetManager from '@components/DailyReportView/JobAssetManager'

export default {
  name: 'JobAssetManagerModal',
  components: {
    VDialog,
    JobAssetManager,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
}
</script>
