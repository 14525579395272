import Vue from 'vue'
import Vuex from 'vuex'
import createMutationsSharer from 'vuex-shared-mutations'
import createPersistedState from 'vuex-persistedstate'
import modules from './modules'
import {
  USER_LOG_IN,
  USER_LOG_OUT,
  SET_SIGNIN_REDIRECT,
  SET_IS_AUTHENTICATED,
} from '../constants/mutations'

Vue.use(Vuex)

let persistedPaths = [
  'authenticated',
  'knackUserObj',
  'user.profile',
  'user.accessToken',
  'user.tokenExpired',
  'user.isAuthenticated',
  'username',
]

const store = new Vuex.Store({
  modules,
  plugins: [
    createMutationsSharer({ predicate: [USER_LOG_IN, USER_LOG_OUT] }),
    createPersistedState({ paths: persistedPaths }),
  ],
})

// TODO (ES): So many ideas here...
//  - commit last route before logging out so it can be loaded on signin
//  - single Okta auth before any update/create/delete action
//  - token refresh cycle trigger
store.subscribeAction({
  before: (action, state) => {
    switch (action.type) {
      case 'logOut':
        store.commit(SET_SIGNIN_REDIRECT, state.route.fullPath)
        break
      default:
        // console.log(`before action ${action.type}`)
        break
    }
  },
  after: (action, state) => {
    // console.log(`after action ${action.type}`)
  },
})

store.subscribe((mutation, state) => {
  if (mutation.type === SET_IS_AUTHENTICATED) {
    let newAuthVal = mutation.payload
    if (newAuthVal) {
      store.dispatch('setUser')
    } else if (state.isAuthenticated) {
      store.dispatch('logOut')
    }
  }
})

export default store
