<script>
import { AssetTypes, HumanFields } from '@constants/knack'
import { mapActions, mapGetters } from 'vuex'
import _get from 'lodash/get'
import _set from 'lodash/set'

const ALL_ASSETS = 'ALL_ASSETS'

export default {
  name: 'WithAllowedJobAssets',
  props: {
    jobId: {
      type: String,
      default: undefined,
    },
    assetType: {
      type: String,
      default: ALL_ASSETS,
    },
    isForPicklist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      areAssetsLoading: false,
      error: null,
    }
  },
  computed: {
    ...mapGetters(['getJobPhases', 'getJobCostCodes']),

    assets() {
      let assets =
        (this.assetType === AssetTypes.COST_CODES
          ? this.getJobCostCodes
          : this.getJobPhases) || []
      let key = this.assetType === AssetTypes.COST_CODES ? 'COST_CODE' : 'PHASE'

      assets = assets.map((asset) => ({
        ...asset,
        [key]: _get(asset, `${key}[0].identifier`, ''),
      }))

      if (this.isForPicklist) {
        assets = assets.filter((asset) => !asset.HIDE_FROM_WORK_ITEM_PICKLIST)
      }
      return assets
    },
  },
  watch: {
    jobId: {
      async handler(jobId) {
        if (!this.assetType) {
          console.error('Asset Type not provided')
          return
        }
        this.loadAssets(this.assetType, jobId)
      },
      immediate: true,
    },
    assetType: {
      async handler(assetType) {
        if (!this.jobId) {
          console.error('Job ID not provided')
          return
        }
        this.loadAssets(assetType, this.jobId)
      },
    },
  },
  methods: {
    ...mapActions(['fetchAllJobAssets', 'fetchJobPhases', 'fetchJobCostCodes']),
    async loadAssets(assetType, jobId) {
      this.areAssetsLoading = true
      this.error = false
      let payload = { jobId }

      if (this.isForPicklist) {
        _set(payload, 'options.filters.rules', [
          {
            field: HumanFields.JOB_COST_CODES.HIDE_FROM_WORK_ITEM_PICKLIST,
            operator: 'is not',
            value: 'Yes',
          },
        ])
      }
      try {
        if (assetType === ALL_ASSETS) {
          await this.fetchAllJobAssets(payload)
        } else if (this.assetType === AssetTypes.COST_CODES) {
          await this.fetchJobCostCodes(payload)
        } else {
          await this.fetchJobPhases(payload)
        }
      } catch (error) {
        this.error = true
        throw Promise.reject(error)
      } finally {
        this.areAssetsLoading = false
      }
    },
  },
  render() {
    const { areAssetsLoading, error, assets } = this
    return this.$scopedSlots.default({
      areAssetsLoading,
      allowedAssetsError: error,
      assets,
    })
  },
}
</script>
