<template>
  <div class="text-xs-center">
    <VProgressCircular
      class="progress--wrap"
      :rotate="360"
      :size="200"
      :width="25"
      :value="value"
      color="blue"
    >
      {{ value }} %</VProgressCircular
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BaseProgressBar',
  components: {},
  props: {
    counter: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getProgressCounter', 'getProgressTotal']),
    value() {
      return Math.floor((this.getProgressCounter / this.getProgressTotal) * 100)
    },
  },
}
</script>

<style lang="scss" scoped>
.progress--wrap {
  height: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.v-progress-circular {
  margin: 1rem;
}
</style>
