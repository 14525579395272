<template>
  <div class="nav-dropdown-menu">
    <VMenu>
      <BaseButton
        slot="activator"
        :class="['nav-profile-btn', { xs: $mq == 'sm' }]"
        md-icon="account_box"
        v-bind="{ ...$attrs, ...$props }"
        depressed
        :flat="$mq == 'sm'"
        :color="$mq == 'sm' ? 'white' : btnColor"
        >{{ userGreeting }}</BaseButton
      >
      <VList class="teal--text pa-0">
        <VListTile to="/dashboard">
          <VListTileTitle>Home</VListTileTitle>
        </VListTile>

        <VListTile
          v-for="(route, index) in mainRoutes"
          :key="index + route.to"
          :to="route.to"
        >
          <VListTileTitle>{{ route.title }}</VListTileTitle>
        </VListTile>
        <VDivider v-if="mainRoutes.length"></VDivider>

        <VListTile
          v-for="(route, index) in otherRoutes"
          :key="index + route.to"
          :to="route.to"
        >
          <VListTileTitle>{{ route.title }}</VListTileTitle>
        </VListTile>
        <VDivider v-if="otherRoutes.length"></VDivider>

        <VListTile @click="$store.dispatch('logOut')">
          <VListTileTitle>Sign Out</VListTileTitle>
        </VListTile>
      </VList>
    </VMenu>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'NavDropdownMenu',
  props: {
    btnColor: {
      type: String,
      default: 'blue',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['page_title']),
    ...mapGetters([
      'isProjMgr',
      'isAccountant',
      'isAuthenticated',
      'firstName',
      'currentUserRoutes',
    ]),
    userGreeting() {
      if (['xs', 'sm'].includes(this.$mq)) {
        return ''
      }
      return this.firstName
    }, // userGreeting
    dropdownRoutes() {
      return this.currentUserRoutes.filter((r) => r.showInDropdownNav)
    }, // dropdownRoutes
    mainRoutes() {
      return this.dropdownRoutes.filter((r) => r.navGroup === 'main')
    }, // mainRoutes
    otherRoutes() {
      return this.dropdownRoutes.filter((r) => r.navGroup !== 'main')
    }, // otherRoutes
  },
}
</script>

<style lang="scss" scoped>
.nav-profile-btn {
  text-transform: none;
}
.v-btn.xs {
  min-width: 40px;
  padding: 0;
  margin: 0;
  ::v-deep .v-icon.v-icon--right {
    margin-left: 0;
  }
}
.v-menu__content {
  border-radius: 0;
}

.nav-dropdown-menu ::v-deep .v-list.theme--light .v-list__tile--link:hover {
  background: rgba(0, 0, 0, 0.1);
}
</style>
