import api from '@services/api'
import { HumanFields, LaborerTypes, AssetTypes } from '@constants/knack'
import {
  SET_LABORER,
  SET_FOREMEN_RESPONSE,
  SET_LABORERS_RESPONSE,
} from '@constants/mutations'

/**
 * @typedef Laborer
 * @property {?number} location - ID of location record
 * @property {?number} foreman - ID of laborer record
 * @property {Array<number>} labor - IDs of laborer records
 * @property {Array<number>} equipment - IDs of equipment records
 * @property {Array<number>} material - IDs of material records
 * @property {string} status - one of `LaborerTypes`
 */

/**
 * Factory function that generates an empty laborer object.
 * @return {Laborer}
 */
const newLaborer = () => ({
  type: LaborerTypes.LABORER,
})

export default {
  state: {
    // Q: What is the methodology behind {module}.state.data?
    data: {},
    // relationship: {
    //   [laborerId]: [{
    //     {type: 'equipment', id: id, fields: {...}}
    //   }]
    // }
    draft: newLaborer(),

    // single laborer record (no metadata)
    laborer: {},

    // Collection of laborers of type "Foreman"
    foremenResponse: {},

    // multiple laborer records (plus total_records and other metadata)
    // response.records contains the records
    response: {},
  },
  mutations: {
    // single records
    // [RESET_DRAFT_LABORER](state) {
    //   state.draft = newLaborer()
    // },
    [SET_LABORER](state, laborer) {
      state.laborer = laborer
    },
    [SET_FOREMEN_RESPONSE](state, response) {
      state.foremenResponse = response
    },
    [SET_LABORERS_RESPONSE](state, response) {
      state.response = response
    },
  },
  actions: {
    // fetch all laborers
    async fetchLaborers({ getters, commit }, options) {
      try {
        const response = await api.get(
          { options, type: AssetTypes.LABORER },
          getters.accessToken
        )
        commit(SET_LABORERS_RESPONSE, response)
      } catch (err) {
        throw new Error(err)
      }
    },
    // get a single laborer
    async fetchLaborer({ getters, commit }, options) {
      try {
        const laborer = await api.get(
          { options, type: AssetTypes.LABORER },
          getters.accessToken
        )
        commit(SET_LABORER, laborer)
        return laborer
      } catch (err) {
        throw new Error(err)
      }
    },
    // fetch all laborers
    async fetchForemen({ getters, commit }) {
      try {
        let options = {
          rows_per_page: 1000,
          filters: {
            match: 'and',
            rules: [
              {
                field: HumanFields.LABORER.LABORER_TYPE,
                operator: 'is',
                value: LaborerTypes.FOREMAN,
              },
              {
                field: HumanFields.LABORER.EXCLUDE_FROM_SCHEDULER,
                operator: 'is not',
                value: 'Yes',
              },
            ],
          },
          sort_field: HumanFields.LABORER.CREW_NUMBER,
          sort_order: 'asc',
        }
        await api.getCollection(
          AssetTypes.LABORER,
          { options },
          (response) => commit(SET_FOREMEN_RESPONSE, response),
          (err) => {
            throw err
          }
        )
      } catch (err) {
        throw new Error(err)
      }
    },
  },
  methods: {},
  getters: {
    // getLaborer: (state) => (id) =>
    //   Object.keys(state.data).includes(id) ? state.data[id] : null,
    // TODO (ES) : This was just scratch work
    getEquipmentForLaborer: (state, getters, rootState) => (laborerId) =>
      getters
        .getLaborer(laborerId)
        .equipment.map((equipmentId) =>
          rootState.equipment.getters.getEquipment(equipmentId)
        ),

    getLaborer: (state) => (id) => state.laborer,
    getLaborersResponse: (state) => state.response,
    getForemen: (state) => state.foremenResponse.records || false,
  },
}
