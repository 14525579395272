<template>
  <component :is="tag" class="pf flex"
    ><slot>{{ value }}</slot></component
  >
</template>

<script>
export default {
  name: 'PrintableField',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
.pf {
  font-size: 0.85em;
  line-height: 1.1;
}
</style>
